import * as React from "react";
import {useEffect, useState} from "react";
import {
  DateRange,
  lastMonth,
  lastThirtyDays,
  lastTwoWeeks, lastWeek,
  setDateRange,
  subscribePrimaryDateRangeChanges,
  thisMonth
} from "../../../data/dataSelectState";
import {Header, SpaceBetween} from "@awsui/components-react";
import DateRangePicker, {DateRangePickerProps} from "@awsui/components-react/date-range-picker";
import Link from "@awsui/components-react/link";
import AbsoluteValue = DateRangePickerProps.AbsoluteValue;
import {subscribeLoadInProgressChanges} from "../../../data/globalState";
import {isValidRangeFunction} from "../../../common/utils";

export function DateRangeSelector() {
  const [value, setValue] = React.useState<DateRange>();
  const [loadInProgress, setLoadInProgress] = useState<boolean>(true);

  useEffect(() => {
    console.log("rendering date range selector");

    if (typeof value === 'undefined') {
      const [startDate, endDate] = lastWeek();
      setDateRange(startDate, endDate);
    }

    subscribePrimaryDateRangeChanges(setValue)
    subscribeLoadInProgressChanges(setLoadInProgress);
  }, [setValue]);


  return (
      <SpaceBetween size={'xxs'} direction={'vertical'}>
        <Header variant={'h3'}>Select a date range</Header>
        <DateRangePicker
            onChange={({detail}) => {
              setDateRange((detail.value as AbsoluteValue).startDate, (detail.value as AbsoluteValue).endDate)
            }}
            value={typeof value === 'undefined' ? null : {
              startDate: value.startDate!,
              endDate: value.endDate!,
              type: 'absolute'
            }}
            isValidRange={isValidRangeFunction as DateRangePickerProps.ValidationFunction}
            i18nStrings={{
              todayAriaLabel: "Today",
              nextMonthAriaLabel: "Next month",
              previousMonthAriaLabel: "Previous month",
              customRelativeRangeDurationLabel: "Duration",
              customRelativeRangeDurationPlaceholder:
                  "Enter duration",
              customRelativeRangeOptionLabel: "Custom range",
              customRelativeRangeOptionDescription:
                  "Set a custom range in the past",
              customRelativeRangeUnitLabel: "Unit of time",
              formatRelativeRange: e => {
                const t =
                    1 === e.amount ? e.unit : `${e.unit}s`;
                return `Last ${e.amount} ${t}`;
              },
              formatUnit: (e, t) => (1 === t ? e : `${e}s`),
              dateTimeConstraintText:
                  "Range is 1 to 31 days. For date, use YYYY/MM/DD.",
              relativeModeTitle: "Relative range",
              absoluteModeTitle: "Absolute range",
              relativeRangeSelectionHeading: "Choose a range",
              startDateLabel: "Start date",
              endDateLabel: "End date",
              startTimeLabel: "Start time",
              endTimeLabel: "End time",
              clearButtonLabel: "Clear and dismiss",
              cancelButtonLabel: "Cancel",
              applyButtonLabel: "Apply"
            }}
            locale={window.navigator.language}
            disabled={loadInProgress}
            dateOnly
            showClearButton={true}
            placeholder="Date range"
            rangeSelectorMode="absolute-only"
            relativeOptions={[]}/>
        <SpaceBetween size={'s'} direction={'horizontal'}>
          <Link variant={'info'} onFollow={() => {
            if (loadInProgress) return;
            const [startDate, endDate] = lastTwoWeeks()
            setDateRange(startDate, endDate);
          }}>Last 2 wks</Link>
          <Link variant={'info'} onFollow={() => {
            if (loadInProgress) return;
            const [startDate, endDate] = lastThirtyDays()
            setDateRange(startDate, endDate);
          }}>Last 30 days</Link>
          <Link variant={'info'} onFollow={() => {
            if (loadInProgress) return;
            const [startDate, endDate] = lastMonth()
            setDateRange(startDate, endDate);
          }}>Last month</Link>
          <Link variant={'info'} onFollow={() => {
            if (loadInProgress) return;
            const [startDate, endDate] = thisMonth()
            setDateRange(startDate, endDate);
          }}>This month</Link>
        </SpaceBetween>
      </SpaceBetween>
  );
}
