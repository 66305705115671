import {FilterEvaluator, FilterEvaluatorProps} from "../interfaces/filters/filter";
import {MetadataValuesFilter} from "../interfaces/filters/impl/MetadataValuesFilter";
import {DeviceFamilyFilter, DeviceFamilyFilterProps} from "../interfaces/filters/impl/DeviceFamilyFilter";
import {DoesValueExistFilter} from "../interfaces/filters/impl/DoesValueExistFilter";
import {StateFilter, StateFilterProps} from "../interfaces/filters/impl/StateFilter";
import {MetricEvaluator, MetricEvaluatorProps, StateMetricEvaluatorProps} from "../interfaces/metrics/metric";
import {SessionCountMetric} from "../interfaces/metrics/impl/SessionCountMetric";
import {UniqueCustomerMetric} from "../interfaces/metrics/impl/UniqueCustomerMetric";
import {SummationMetric, SummationMetricProps} from "../interfaces/metrics/impl/SummationMetric";
import {StateExecutionTimeMetric} from "../interfaces/metrics/impl/StateExecutionTimeMetric";
import {
    StateTransitionCountMetric,
    StateTransitionCountMetricProps
} from "../interfaces/metrics/impl/StateTransitionCountMetric";
import {RequestCountMetric} from "../interfaces/metrics/impl/RequestCountMetric";
import {StateTagCountMetric, StateTagCountMetricProps} from "../interfaces/metrics/impl/StateTagCountMetric";
import {FrictionCountMetric, FrictionCountMetricProps} from "../interfaces/metrics/impl/FrictionCountMetric";
import {ValuePresentMetric, ValuePresentMetricProps} from "../interfaces/metrics/impl/ValuePresentMetric";
import {StateVisitCountMetric, StateVisitCountMetricProps} from "../interfaces/metrics/impl/StateVisitCountMetric";
import {SessionTurnCountMetric} from "../interfaces/metrics/impl/SessionTurnCountMetric";
import {SessionTimeMetric, SessionTimeMetricProps} from "../interfaces/metrics/impl/SessionTimeMetric";
import {
    MetadataJsonValuesFilter,
    MetadataJsonValuesFilterProps
} from "../interfaces/filters/impl/MetadataJsonValuesFilter";

/**
 * FilterExecutor factory
 * Return a filter executor for corresponding key
 */
export const filterExecutorOptions: {
    [name: string]: {
        constructor: (props: any) => FilterEvaluator
    }
} = {
    "MetadataValuesFilter": {
        constructor: (props: FilterEvaluatorProps) => new MetadataValuesFilter(props)
    },
    "MetadataJsonValuesFilter": {
        constructor: (props: MetadataJsonValuesFilterProps) => new MetadataJsonValuesFilter(props)
    },
    "DeviceFamilyFilter": {
        constructor: (props: DeviceFamilyFilterProps) => new DeviceFamilyFilter(props)
    },
    "DoesValueExistFilter": {
        constructor: (props: FilterEvaluatorProps) => new DoesValueExistFilter(props)
    },
    "StateFilter": {
        constructor: (props: StateFilterProps) => new StateFilter(props)
    }
}

/**
 * MetricEvaluator factory
 * Return the metric evaluator for corresponding key
 */
export const metricEvaluatorOptions: {
    [name: string]: {
        constructor: (props: any) => MetricEvaluator
    }
} = {
    "SessionCountMetricEvaluator": {
        constructor: (props: MetricEvaluatorProps) => new SessionCountMetric(props)
    },
    "UniqueCustomerMetricEvaluator": {
        constructor: (props: MetricEvaluatorProps) => new UniqueCustomerMetric(props)
    },
    "SummationMetricEvaluator": {
        constructor: (props: SummationMetricProps) => new SummationMetric(props)
    },
    "StateExecutionTimeMetricEvaluator": {
        constructor: (props: StateMetricEvaluatorProps) => new StateExecutionTimeMetric(props)
    },
    "StateTransitionCountMetricEvaluator": {
        constructor: (props: StateTransitionCountMetricProps) => new StateTransitionCountMetric(props)
    },
    "RequestCountMetricEvaluator": {
        constructor: (props: MetricEvaluatorProps) => new RequestCountMetric(props)
    },
    "StateTagCountMetricEvaluator": {
        constructor: (props: StateTagCountMetricProps) => new StateTagCountMetric(props)
    },
    "FrictionCountMetricEvaluator": {
        constructor: (props: FrictionCountMetricProps) => new FrictionCountMetric(props)
    },
    "ValuePresentMetricEvaluator": {
        constructor: (props: ValuePresentMetricProps) => new ValuePresentMetric(props)
    },
    "StateVisitCountMetricEvaluator": {
        constructor: (props: StateVisitCountMetricProps) => new StateVisitCountMetric(props)
    },
    "SessionTimeMetricEvaluator": {
        constructor: (props: SessionTimeMetricProps) => new SessionTimeMetric(props)
    },
    "SessionTurnCountMetricEvaluator": {
        constructor: (props: SessionTurnCountMetric) => new SessionTurnCountMetric(props)
    }
}
