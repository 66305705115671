import React, {useEffect, useState} from "react";
import {FrictionMetrics, PageMetrics} from "../../../interfaces";
import {Box, Header, SpaceBetween, StatusIndicator, Table} from "@awsui/components-react";
import {convertMetricDataToPageMetrics} from "../../../utils/frictionMetricUtils";
import {subscribeLoadInProgressChanges} from "../../../data/globalState";
import {getComparisonDisplayValue, getFrictionMetricSum} from "../../../common/utils";
import {MetricAwareFilter} from "../../../interfaces/filters/filter";
import {subscribeFrictionMetricChanges, subscribeSummaryMetricChanges} from "../../../data/metricStateManager";
import {convertMetricAwareFilterToPageMetrics} from "../../../utils/pageMetricsUtils";
import goblinChild from "../../../assets/goblin-child.png"

export function SummaryTable() {

    const [summaryMetric, setSummaryMetrics] = useState<MetricAwareFilter>();
    const [frictionMetric, setFrictionMetric] = useState<FrictionMetrics[]>([]);
    const [loadInProgress, setLoadInProgress] = useState<boolean>();


    useEffect(() => {
        subscribeSummaryMetricChanges(setSummaryMetrics);
    }, [setSummaryMetrics]);

    useEffect(() => {
        subscribeFrictionMetricChanges(setFrictionMetric);
    }, [setFrictionMetric]);

    useEffect(() => {
        subscribeLoadInProgressChanges(setLoadInProgress);
    }, [setLoadInProgress])

    function getCellValue(item: any, key: string) {
        if (item.dataTrend === undefined) {
            return item
        }

        if (item.dataTrend === "NO_TREND") {
            return (
                <>{getComparisonDisplayValue(item, key)}</>
            )
        }

        return (
            <StatusIndicator type={(item.dataTrend === "POSITIVE")? 'success': 'warning'} key={item.metricLabel}>
                {getComparisonDisplayValue(item, key)}
            </StatusIndicator>
        )
    }

    function getSummaryTable() {
        if (summaryMetric === undefined) return <></>
        let pageMetrics: PageMetrics[] = convertMetricDataToPageMetrics(summaryMetric.lineSeriesAndMetricData!.metricData);
        return (
            <Table
                key={`${summaryMetric.filterName}`}
                loading={loadInProgress}
                header={<Header variant={"h3"} >{summaryMetric.filterName}</Header>}
                columnDefinitions={Object.keys(pageMetrics[0])
                    .filter(key => key !== "id")
                    .map(key => {
                        return {
                            id: key,
                            header: "",
                            cell: (item: any) => getCellValue(item[key], key),
                        }
                    })
                }
                items={pageMetrics}
                wrapLines={true}
            />
        )
    }

    function getFrictionTable(label: string, filters: MetricAwareFilter[]) {
        let pageMetrics: PageMetrics[] = convertMetricAwareFilterToPageMetrics(filters);
        let frictionSum = getFrictionMetricSum({[label]: filters})[label]
        let totalLabel = getCellValue({dataTrend: "NEGATIVE", actualValue: frictionSum.actualSum, comparisonValue: frictionSum.comparisonSum}, label);
        return (
            <>
                <Table
                    loading={loadInProgress}
                    columnDefinitions={Object.keys(pageMetrics[0])
                        .filter(key => key !== "id")
                        .map(key => {
                            return {
                                id: key,
                                header: key,
                                cell: (item: any) => getCellValue(item[key], key),
                            }
                        })
                    }
                    items={pageMetrics}
                    wrapLines={true}
                />
                <div style={{borderStyle: "solid", borderColor: "lightgrey", paddingLeft: "20px"}}>
                    <Header variant={"h2"}>Total {label} -  {totalLabel}</Header>
                </div>
            </>

        )
    }

    function getFrictionTables() {
        if (frictionMetric === undefined || frictionMetric.length === 0) return <></>

        return (
            <SpaceBetween size={"xl"} direction={"horizontal"}>
                {frictionMetric.map((val, index) => {
                    return (
                        <Box key={`${val.label}-${index}`}>
                            <Header variant={"h2"} >{val.label}</Header>
                            {getFrictionTable(val.label, val.filters)}
                        </Box>
                    )
                })}
            </SpaceBetween>
        )
    }


    return (
        <SpaceBetween size={"xl"} direction={"horizontal"}>

            {getSummaryTable()}
            <img height={400} width={300} src={goblinChild}/>
            {getFrictionTables()}
        </SpaceBetween>
    )
}
