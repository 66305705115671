import {Lambda} from "@aws-sdk/client-lambda";
import {Auth} from "aws-amplify";
import {S3} from "@aws-sdk/client-s3";
import {canaryCustomers} from "../config/filters/filterConfig";
import {AggregatedView, State, Transition} from "../interfaces";
import Pako from "pako";
import {MetricAwareFilter} from "../interfaces/filters/filter";
import {getRegionalConfig} from "../config/configs";

export const MILLIS_IN_MINUTES = 60 * 1000;
export const MILLIS_IN_FIVE_MINUTES = 5 * 60 * 1000;
export const MILLIS_IN_FIFTEEN_MINUTES = 15 * 60 * 1000;
export const MILLIS_IN_HOUR = 60 * 60 * 1000;
export const MILLIS_IN_THREE_HOURS = 3 * 60 * 60 * 1000;
export const MILLIS_IN_DAY = 24 * 60 * 60 * 1000;

export function getServicePrefixFromUrlString(serviceNameAndStage: string) {
  let values: string[] = serviceNameAndStage.split("-");
  if (values.length === 0) return ""
  return values[0]
}

export function getServiceStageFromUrlString(serviceNameAndStage: string) {
  let values: string[] = serviceNameAndStage.split("-");
  if (values.length === 0) return ""
  if (values.length === 1) return "prod"
  return values[1]
}

export function getServiceNameStageString(availableServiceStageKeys: string[]) {
  let serviceNameAndStage = window.location.hash.split("/")[1]

  if (serviceNameAndStage === undefined) return undefined;

  if (availableServiceStageKeys.indexOf(serviceNameAndStage) < 0) return null;

  return `${getServicePrefixFromUrlString(serviceNameAndStage)}-${getServiceStageFromUrlString(serviceNameAndStage)}`
}

/**
 * Initialize lambda clients per region.
 */
export function getLambdaClientsPerRegion(regions: string[]): { [region: string]: Lambda } {
  const lambdaClients: { [region: string]: Lambda } = {}
  for (const region of regions) {
    lambdaClients[region] = new Lambda({credentials: Auth.currentCredentials, region: region});
  }
  return lambdaClients;
}

/**
 * Initialize S3 clients per region.
 */
export function getS3ClientsPerRegion(regions: string[]): { [region: string]: S3 } {
  const s3Clients: { [region: string]: S3 } = {}
  for (const region of regions) {
    s3Clients[region] = new S3({credentials: Auth.currentCredentials, region: region});
  }
  return s3Clients;
}

/**
 * Get aggregated view storage bucket for a region.
 */
export function getAVBucketForRegion(region: string) {
  return getRegionalConfig().filter(r => r.region === region)[0].avBucket;
}

/**
 * Get caching lambda name for a region.
 */
export function getCachingLambdaForRegion(region: string) {
  return getRegionalConfig().filter(r => r.region === region)[0].cachingFunction;
}

/**
 * Get the prefixes for which data should be cached and loaded.
 */
export function getPrefixList(startDate: string, endDate: string): string[] {
  let begin = new Date(startDate).getTime()
  const end = new Date(endDate).getTime()

  const results: string[] = []
  while (begin <= end) {
    results.push(new Date(begin).toISOString().split('T')[0].replace(/-/g, "/"));
    begin = begin + 24 * 60 * 60 * 1000; // one per day.
  }
  return results;
}

/**
 * Utility method used to check is a date range is valid
 * @param range
 */
export const isValidRangeFunction = (range: { type: string; startDate: string; endDate: string; amount: number; unit: any; }): any => {
  // If 'relative' option is clicked with/without value, return. (for now)
  if (!range || range?.type === 'relative') return

  if (range.type === 'absolute') {
    const [startDateWithoutTime] = range.startDate.split('T');
    const [endDateWithoutTime] = range.endDate.split('T');

    if (!startDateWithoutTime || !endDateWithoutTime) {
      return {
        valid: false,
        errorMessage: 'The selected date range is incomplete. Select a start and end date for the date range.'
      }
    }

    if (new Date(range.startDate).getTime() > new Date(range.endDate).getTime()) {
      return {
        valid: false,
        errorMessage: 'Start date cannot be after the end date'
      }
    }

    if (new Date(range.endDate).getTime() - new Date(range.startDate).getTime() > 31 * 24 * 60 * 60 * 1000) {
      return {
        valid: false,
        errorMessage: 'The selected date range is too large. Select a range up to 31 days.'
      };
    }
  }
  return {valid: true};
};

/**
 * Get cached aggregated views for a prefix.
 *
 * If no cache exists, the lambda function invoked as part of this will generate it and return its uri.
 */
export async function getCachedAVForPrefix(prefix: string, region: string, lambda: Lambda, s3: S3, avs: AggregatedView[], signal: any) {

  const lambdaOutput = await lambda.invoke({
    FunctionName: getCachingLambdaForRegion(region),
    Payload: new TextEncoder().encode(JSON.stringify({"prefix": prefix}))
  })

  const key = JSON.parse(new TextDecoder().decode(lambdaOutput.Payload!))["cacheKey"];
  const s3Output = await s3.getObject({
    Bucket: getAVBucketForRegion(region),
    Key: key + ".gz"
  }, {abortSignal : signal})

  // const text: string = await readableStreamToString(s3Output.Body as ReadableStream);
  const text: string = await compressedReadableStreamToString(s3Output.Body as ReadableStream);
  // // filter out canary customers.
  let canaryCounter : number = 0;


  (JSON.parse(text) as AggregatedView[]).forEach(av => {
    av.filterGroupOutcomes = {}  // never delete these two declarations
    av.filterOutcomes = {}

    if (canaryCustomers.indexOf(av.customerIdentifier) >= 0) canaryCounter += 1;
    else {
      handleMissingTransitionData(av.states, av.transitions)
      handleDanglingState(av.states, av.transitions)
      endSession(av.states, av.transitions)
      detectNoopSessionEnd(av.transitions)
      avs.push(av)
    }
  });
  console.log(`Filtered out ${canaryCounter} sessions for canary customers - prefix ${prefix}`);
}

/**
 * Converts a readable stream to string. Primarily used for reading ~10-15 MB data  from S3 in streaming fashion.
 */
async function readableStreamToString(stream: ReadableStream) {
  const chunks: Buffer[] = [];
  const reader = stream.getReader();
  while (true) {
    const {done, value} = await reader.read();
    if (done) break;
    chunks.push(Buffer.from(value as Uint8Array));
  }
  return Buffer.concat(chunks).toString('utf-8');
}

/**
 * Converts a readable stream to string. Primarily used for reading ~10-15 MB data  from S3 in streaming fashion.
 */
async function compressedReadableStreamToString(stream: ReadableStream) {
  const reader = stream.getReader();
  const inflater = new Pako.Inflate({to: 'string'});
  while (true) {
    const {done, value} = await reader.read();
    if (done) break;
    inflater.push(value as Uint8Array);
  }

  if (inflater.err) {
    console.log(inflater.err);
    throw inflater.err;
  }

  return inflater.result as string;
}

/**
 * Convert milliseconds to a human-readable format.
 */
export function convertMillsToReadable(time: number): string {
  // try hours
  if (Math.floor(time / MILLIS_IN_DAY) > 0) {
    return `${(time / MILLIS_IN_DAY).toFixed(1)} d`;
  } else if (Math.floor(time / MILLIS_IN_HOUR)) {
    return `${(time / MILLIS_IN_HOUR).toFixed(1)} h`;
  } else if (Math.floor(time / MILLIS_IN_MINUTES)) {
    return `${(time / MILLIS_IN_MINUTES).toFixed(1)} m`;
  } else {
    return `${(time / 1000).toFixed(1)} s`;
  }
}

/**
 * Convert numbers to readable format.
 */
export function convertNumbersToReadable(value: number): string {
  return Math.abs(value) >= 1e9
      ? (value / 1e9).toFixed(2).replace(/\.0$/, "") +
      "G"
      : Math.abs(value) >= 1e6
          ? (value / 1e6).toFixed(2).replace(/\.0$/, "") +
          "M"
          : Math.abs(value) >= 1e3
              ? (value / 1e3).toFixed(2).replace(/\.0$/, "") +
              "K"
              : value.toFixed(2);
}

/**
 * Convert dates to readable format in charts.
 */
export function convertDateToReadable(date: Date): string {
  return date.toLocaleDateString(window.navigator.language, {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: !1
  })
      .split(",")
      .join("\n")
}

/**
 * Counts frequencies of values within a list.
 */
export function frequencyCounter(list: string[]): { [value: string]: number } {
  const result: { [key: string]: number } = {};
  list.forEach(val => {
    if (!result.hasOwnProperty(val)) result[val] = 0;
    result[val] += 1;
  });
  return result;
}

/**
 * Converts a number to a particular number of fractional digits.
 */
export function numberWithFraction(value: number, fraction?: number): number {
  if (typeof fraction === 'undefined') return value;
  return Number(value.toFixed(fraction));
}

/**
 * Sums a list.
 */
export function sumValue(list: any[]): number {
  if (list.length === 0) return 0;
  let sum = 0;
  list.forEach(val => {
    if (typeof val !== "number") {
      sum += 1
    } else {
      sum = sum + val;
    }
  });
  return sum;
}

/**
 * Averages a list, and returns the average with the desired precision.
 */
export function averageValue(list: number[], precision?: number): number {
  if (list.length === 0) return 0;
  let sum = 0;
  list.forEach(val => {
    sum = sum + val;
  });
  return numberWithFraction(sum / list.length, precision);
}

/**
 * Get error message.
 */
export function getErrorMessage(error: any) {
  let message = 'unknown';
  if (error instanceof Error) message = `${error.message}`;
  if (typeof error === 'string') message = error;
  return message;
}

/**
 * Get unique state name for display
 * @param state
 * @param showTags
 */
export function getStateNameForDisplay(state: State, showTags: boolean) {
  if (showTags && typeof state.tag !== 'undefined') return `${state.name}/${state.tag}`;
  return state.name;
}

/**
 * Get unique transition name for display
 * @param transition
 * @param showTransitionTags
 */
export function getTransitionNameForDisplay(transition: Transition, showTransitionTags: boolean): string {
  let result = typeof transition.reason === 'undefined' ? undefined : transition.reason!;
  if (showTransitionTags && typeof transition.transitionTag !== 'undefined')
    result = (result ? result + "/" : '') + transition.transitionTag!
  return result ? result : "*";
}

/**
 * Get unique state key
 * @param state
 * @param showTags
 */
export function getStateKey(state: State, showTags: boolean) {
  if (showTags && typeof state.tag !== 'undefined') return `state=${state.name},tag=${state.tag!}`
  return `state=${state.name}`;
}

/**
 * Get unique transition key
 * @param transition
 * @param showTags
 * @param showTransitionTags
 */
export function getTransitionKey(transition: Transition, showTags: boolean, showTransitionTags: boolean) {
  let key = `origin=${getStateKey(transition.origin, showTags)},destination=${getStateKey(transition.destination, showTags)}`;
  if (transition.reason !== 'undefined') key = `${key},reason=${transition.reason}`
  if (showTransitionTags && typeof transition.transitionTag !== 'undefined') key = `${key},tag=${transition.transitionTag}`
  return key;
}

/**
 * Utility to format display string for different metrics
 * @param key
 * @param value
 */
export function getDisplayValue(key: string, value: number) {
  switch (key) {
    case "Time Spent": return convertMillsToReadable(value);
    case "Execution Time": return convertMillsToReadable(value);
    default: return value.toLocaleString(window.navigator.language);
  }
}

export function getComparisonDisplayValue(data: any, key?: string): string {
  if (data === undefined) return "";
  if (data.actualValue === undefined) return data;

  let result: string = `${getDisplayValue(key!, data.actualValue)}`

  let val = numberWithFraction((100 * (+data.actualValue - +data.comparisonValue)/+data.comparisonValue), 0);
  let trendSign: string = (data.actualValue > data.comparisonValue)? '+': '';
  if (!isNaN(val) && val != 0 && val != Number.POSITIVE_INFINITY && val != Number.NEGATIVE_INFINITY) {
    return `${result} (${trendSign}${getDisplayValue("", val)}%)`
  }
  return result
}

/**
 * Get scaled value
 * @param series
 */
export function getScaledValues(series: { title: string, type: string, data: { x: Date, y: number }[]}[]) {

  let result: { title: string, type: string, data: { x: Date, y: number }[], valueFormatter: (y: number, x: Date) => string}[] = []

  series
      .forEach((k: { title: string, type: string, data: { x: Date, y: number }[]}, index) => {

        let new_data: { x: Date, y: number }[] = []

        let max = Number.MIN_VALUE

        k.data.forEach((d: { x: Date, y: number }) => {
          max = Math.max(max, d.y)
        })

        k.data.forEach((d: { x: Date, y: number }) => {
          new_data.push({
            x: d.x,
            y: numberWithFraction((10*(d.y)/(max)),2)
          })
        })

        result.push({
          title: k.title,
          type: k.type,
          data: new_data,
          valueFormatter: (num: number, xDate) => Math.round(max*num/(10)).toLocaleString(window.navigator.language)
        })

      })

  return result

}

export function getTrend(isUpwardTrendGood: boolean | undefined, actualValue: any, comparisonValue: any) {
  if (isUpwardTrendGood === undefined) return 'NO_TREND'
  return isUpwardTrendGood ? ((actualValue >= comparisonValue) ? 'POSITIVE' : 'NEGATIVE') :
      (actualValue > comparisonValue) ? 'NEGATIVE' : 'POSITIVE'
}

function handleMissingTransitionData(states: State[], transitions: Transition[]) {
  // if there are no states, transitions
  if (states.length === 0 && transitions.length === 0) {
    states.push({name: "SESSION_STARTED"}) // no need for pushing session_end. handleDanglingState will do it.
    transitions.push({origin: {name: "SESSION_STARTED"}, destination: {name: "SESSION_END"}})
  }
}

function handleDanglingState(states: State[], transitions: Transition[]) {
  // in general, for goblin, transitions are added first, and the destination state is not added
  // until the next transition, where it would be the origin.
  // for Karaoke, this isn't always guaranteed to happen

  if (transitions.length === 0) return; // nothing to do, if there aren't any transitions

  // for key calculation for states here, we MUST use true for showTags. This acts as an implicit comparison of both
  // name and tag, as is desirable here, and has nothing to do with what we display on the dashboard.
  const lastTransition = transitions[transitions.length - 1]

  // states is guaranteed to have > 0 elements - handleMissingTransitionData + transition length check above ensures that.
  if (getStateKey(lastTransition.destination, true) !== getStateKey(states[states.length - 1], true)) {
    states.push(lastTransition.destination)
  }
}

function endSession(states: State[], transitions: Transition[]) {
  // if originally, state transition were empty, handleMissingTransitionData makes them non-empty.
  // if originally, state was empty, transition not empty, handleDanglingState makes state non-empty.
  // if originally, state was non-empty, transition empty, nothing changed so far.

  if (states[states.length - 1].name !== "SESSION_END") states.push({name: "SESSION_END"});

  // if transitions is empty, but states is not, we're not going to touch this.
  if (transitions.length !== 0 && transitions[transitions.length - 1].destination.name !== "SESSION_END")
    transitions.push({origin: transitions[transitions.length - 1].destination, destination: {name: "SESSION_END"}});

}

function detectNoopSessionEnd(transitions: Transition[]) {
  if (transitions.length === 0) return;
  // if there's only one transition, and its destination is session_end - mark it as direct_session_end.
  if (transitions.length === 1 && transitions[0].destination.name === "SESSION_END") {
    transitions[0].transitionTag = (transitions[0].transitionTag ? transitions[0].transitionTag + ";" : "") + "DIRECT_EXIT" ;
  }

  // if there's only two transitions, session_started -> state, state -> session_end, classify as direct_session_end.
  if (transitions.length === 2
      && transitions[0].origin.name === "SESSION_STARTED"
      && transitions[1].destination.name === "SESSION_END"
      && getStateKey(transitions[0].destination, true) === getStateKey(transitions[1].origin, true)
  ) {
    transitions[1].transitionTag = (transitions[1].transitionTag ? transitions[1].transitionTag + ";" : "") + "DIRECT_EXIT" ;
  }
}

/**
 * Utility method to get the sum of a friction metric
 * Eg: {
 *     metric: [{irrelevant data, metricValue1}, {irrelevant data, metricValue2}]
 * }
 *           |
 *           |
 *           |
 *           |
 *  {
 *      metric: {actualSum: metricValue1 + metricValue2}
 *  }
 * @param item
 */
export function getFrictionMetricSum(item: { [x: string]: MetricAwareFilter[] }): {[x:string]: {actualSum: number, comparisonSum: number} } {

  let result: {[x:string]: {actualSum: number, comparisonSum: number} } = {}

  Object.keys(item)
      .forEach(key => {
        let actualSum = 0;
        let comparisonSum = 0;
        for (let maf of item[key]) {
          for (let md of maf.lineSeriesAndMetricData!.metricData) {
            if (md.metricLabel === "Count") {
              actualSum += md.actualValue
              comparisonSum += md.comparisonValue
            }
          }
        }
        if (result[key] === undefined) result[key] = {
          actualSum: 0,
          comparisonSum: 0
        };
        result[key].actualSum += actualSum
        result[key].comparisonSum += comparisonSum
      })

  return result
}
