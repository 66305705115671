import {RegionDataMap} from "../interfaces";
import {
    getServicePrefixFromUrlString,
    getServiceStageFromUrlString
} from "../common/utils";
import {setRegions} from "../data/dataSelectState";

let regionalConfig: RegionDataMap[] = [];

let servicePrefix: string = ""

let metricOptions: any = []
let filterOptions: any = []
export var additionalSummaryMetricOptions: any = []
export var frictionMetricOptions: any = {}

/**
 * Loads skill specs from config files defined by skills
 * TODO: Remove this dependency of loading skill specification asynchronously
 */
export async function loadSkillSpecifications() {

    let serviceNameAndStage = localStorage.getItem("previousServiceNameAndStage")!

    servicePrefix = getServicePrefixFromUrlString(serviceNameAndStage);

    // load service specs
    regionalConfig = (await import(`./skills/${servicePrefix}/service-spec.json`))[getServiceStageFromUrlString(serviceNameAndStage)]
    setRegions(regionalConfig.map(r => r.region));

    // load filter specs
    let filters: any = []
    let skillFilterOptions = await import(`./skills/${servicePrefix}/filter-spec.json`)

    for (let c=0; c<skillFilterOptions.length; c++) {
        filters.push(skillFilterOptions[c])
    }

    // load page metrics
    let metrics: any = []
    let skillMetricOptions = await import(`./skills/${servicePrefix}/page-metric-spec.json`)

    for (let c=0; c<skillMetricOptions.length; c++) {
        metrics.push(skillMetricOptions[c])
    }
    metricOptions = metrics
    filterOptions = filters

    // load summary metrics
    let skillSummaryMetrics = await import(`./skills/${servicePrefix}/additional-metric-spec.json`)
    additionalSummaryMetricOptions = skillSummaryMetrics["additionalSummaryMetrics"]
    frictionMetricOptions = skillSummaryMetrics["frictionMetrics"]

}

export function getFilterOptions() {
    return filterOptions
}

export function getMetricOptions() {
    return metricOptions
}

export function getRegionalConfig() {
    return regionalConfig
}

export function getServicePrefix() {
    return servicePrefix
}