import {Box, Header, PieChart} from "@awsui/components-react";
import * as React from "react";
import {useEffect, useState} from "react";
import {subscribeLoadInProgressChanges} from "../../../../data/globalState";
import {MetricAwareFilter} from "../../../../interfaces/filters/filter";

export function ChartComponent(props: {
    frictionMetrics: {[x: string]: MetricAwareFilter[]}
}) {

    const [loadInProgress, setLoadInProgress] = useState<boolean>();

    useEffect(() => {
        subscribeLoadInProgressChanges(setLoadInProgress);
    }, [setLoadInProgress])

    function getVisibleComponents(key: string): any[] {
        return props.frictionMetrics[key]
            .map((filter: MetricAwareFilter) => {
                for (let md of filter.lineSeriesAndMetricData!.metricData){
                    if (md.metricLabel === "Count") {
                        return {
                            title: filter.filterName,
                            value: md.actualValue
                        }
                    }
                }
            })
    }

    return (
        <>
            {Object.keys(props.frictionMetrics)
                .map((key: any, index: number) => {
                    let sum = 0;
                    for (let val of props.frictionMetrics[key]) {
                        for (let md of val.lineSeriesAndMetricData!.metricData){
                            if (md.metricLabel === "Count") {
                                sum += md.actualValue
                            }
                        }
                    }
                    return (
                        <div key={key} style={{borderBottom: "1px", borderBottomStyle: "solid"}}>
                            <Header variant={"h2"}>{key}</Header>
                            <PieChart
                                statusType={loadInProgress ? 'loading' : 'finished'}
                                data={getVisibleComponents(key)}
                                hideFilter={true}
                                detailPopoverContent={(datum: { value: number; }, sum: number) => [
                                    { key: "Total", value: datum.value.toLocaleString(window.navigator.language) },
                                    {
                                        key: "Percentage",
                                        value: `${((datum.value / sum) * 100).toFixed(2)}%`
                                    }]}
                                segmentDescription={(datum: { value: number; }, sum: number) =>
                                    `${datum.value.toLocaleString(window.navigator.language)}, ${(
                                        (datum.value / sum) *
                                        100
                                    ).toFixed(2)}%`
                                }
                                i18nStrings={{
                                    detailsValue: "Value",
                                    detailsPercentage: "Percentage",
                                    detailPopoverDismissAriaLabel: "Dismiss",
                                    legendAriaLabel: "Legend",
                                    chartAriaRoleDescription: "pie chart",
                                    segmentAriaRoleDescription: "segment"
                                }}
                                ariaLabel="Pie chart"
                                errorText="Error loading data."
                                loadingText="Loading chart"
                                recoveryText="Retry"
                                variant="donut"
                                innerMetricDescription={key}
                                innerMetricValue={sum.toLocaleString(window.navigator.language)}
                                empty={
                                    <Box textAlign="center" color="inherit">
                                        <b>No data available</b>
                                        <Box variant="p" color="inherit">
                                            There is no data available
                                        </Box>
                                    </Box>
                                }
                                noMatch={
                                    <Box textAlign="center" color="inherit">
                                        <b>No matching data</b>
                                        <Box variant="p" color="inherit">
                                            There is no matching data to display
                                        </Box>
                                    </Box>
                                }
                            />
                        </div>
                    )
                })}
        </>
    )
}