import {AggregatedView, MetadataDescriptor, State} from "../../interfaces";
import {
  Filter,
  FilterGroup,
  MetricAwareFilter,
  RegisteredFilters,
  StateMetricFilter
} from "../../interfaces/filters/filter";
import {filterExecutorOptions, metricEvaluatorOptions} from "../../common/specsMap";
import {StateFilter} from "../../interfaces/filters/impl/StateFilter";
import {getStateKey, getStateNameForDisplay} from "../../common/utils";
import {getPageMetricEvaluators} from "../metrics/metricConfig";
import {getDistinctValues} from "../../common/stringMetadata";


// TODO automate this filtering via the Apex pools.
export const canaryCustomers: string[] = ["A2RTBC2F9Q6E1N", "A1IAXVN0FLRJRJ", "AC0X2J5DJYQ70", "AA1X0C5BWYWB0", "A3IEXKZDKV5NMM",
  "A25GFD9ARG6AHV", "A29F26BRLU5112", "A30DN0GS093CJL", "A1O4KOGMZVY8LW", "A3LDC7JMF5SX86", "A2T65ZHBS5G951", "A2Q4OLEKUGOTR8",
  "AFRDFC6LZHXIQ", "A1KTF85NKOOO38", "A3HN7R2BKS6L0B", "A1K53ETNJEWN3D", "A1FP4YN03D4VIK", "A122F9QG29G5C1", "A1FPRONVJRFD3A",
  "A5U83TETMZZ2K", "A3EWKWUZK34LGR", "AYP1R1JCSXIMP", "A1V8O9XC096294", "A3CSX7JGIQYZV7", "AMXWDM1HIQDCU", "A1FBRNH686PZ3W",
  "A18LHV8TIZ73UB", "AL1ZGUBE6SZ9P", "A3PDARIAKKLZOT", "A1V9PAP087F6Z4", "AOEZXWCRQ3U1C", "AOASI54RNU0CD", "AA706HZW4XCWQ",
  "A2AD44P5OELB7H", "AWE2N1O6PM4U9", "A33MJWDXEUTWZ6", "A1OAB01YI6WKT6", "A3EUYE4GH2ZOB5", "A3HZGXCZ4G5CP7", "A2GSZWSSYZ5W8H",
  "AZWRT3I60G40V", "A8FOXHTLTVQER", "A2D36WPO2WBT5Z", "A9A7NULMTK858", "A3U9SGQEP6GBM", "A3AGL2X315V60E", "ALBFWKW9UTO2E",
  "A1TQ32GI6GFVMP", "A1PFSHD78PIC8P", "A3U4GQTZ35SRUC", "A1TNPURJLFCXYT", "AZH1NMIBLH08L", "A1O3DHIXPQGS92", "A2EDQS0MM1K47S",
  "A2HKH7JEWG3TDQ", "A1OMVRMFSLJMIO", "A1N8D8S2KN07VO", "ATWNIEYANR7WW", "A338RDDJ0HR3KT", "A3N3F4ADM6SIPB", "ALI3XV7A3AGPK",
  "AKF685SGEVUG6", "AO3M4KF5KHWR3", "AIVTTUKL7BC10", "AIPUPYS2PMSJX", "AMEHQ3DHK9XXS", "AH3UIW8O1YK9K", "A34ZVE32S5X810",
  "A1AIY9XF62R1JQ", "A20Z01N2SVUL6Q", "A1CJC7F5ZSVM9M", "A10G3Z3Q4SBR9L", "A3DKXUOBZGRBJM"];



function getDefaultGroupEvaluator(groupName: string) {
  return (filters: { [filter: string] : Filter }, av: AggregatedView) => {

    let matches = false;

    let ignoreCounter = 0;
    for (let filterName of Object.keys(filters)) {

      let filter: Filter = filters[filterName]
      if (av.filterOutcomes[groupName] === undefined) {
        av.filterOutcomes[groupName] = {}
      }

      if (filter.mode === "IGNORE") {
        ignoreCounter += 1;
        if (av.filterOutcomes[groupName][filterName] === undefined) {
          av.filterOutcomes[groupName][filterName] = filter.executorFunction.evaluate(av, filter.currentState);
        }
        continue;
      }

      let temp;
      if (filter.shouldEvaluateFilter || av.filterOutcomes[groupName][filterName] === undefined) {
        temp = filter.executorFunction.evaluate(av, filter.currentState);
        av.filterOutcomes[groupName][filterName] = temp;
      } else {
        temp = av.filterOutcomes[groupName][filterName]
      }

      if (filter.mode === "EXCLUDE") {
        if (temp) {
          matches = false;
          break;
        }
        temp = !temp;
      }

      matches = temp || matches;
    }

    if (ignoreCounter === Object.keys(filters).length) return true;
    return matches;
  }
}

function getStateFilterGroupEvaluator(groupName: string) {
  return (filters: { [filter: string] : Filter }, av: AggregatedView) => {

    for (let filterName of Object.keys(filters)) {

      let filter: StateMetricFilter = filters[filterName] as StateMetricFilter
      if (av.filterOutcomes[groupName] === undefined) {
        av.filterOutcomes[groupName] = {}
      }

      if (av.filterOutcomes[groupName][filterName] === undefined) {
        av.filterOutcomes[groupName][filterName] = filter.executorFunction.evaluate(av, filter.currentState);
      }

    }
    return true;
  }
}

/**
 * Utility to create a filter group
 * @param groupName
 * @param excludeFromDataFiltering
 * @param groupEvaluator
 * @param placeholder
 */
function createFilterGroup(groupName: string, excludeFromDataFiltering: boolean, groupEvaluator: any, placeholder?: string): FilterGroup {
  return {
    groupName: groupName,
    filters: {},
    shouldEvaluateFilterGroup: true,
    options: [],
    placeholder: placeholder? placeholder: "",
    excludeFromDataFiltering,
    evaluate: groupEvaluator
  }
}


/**
 * Creates a MetricAwareFilter
 * @param label
 * @param value
 * @param filterGroup
 * @param filterEvaluator
 * @param metricEvaluators
 */
function createMetricAwareFilter(label: string, value: string, filterGroup: FilterGroup, filterEvaluator: any, metricEvaluators: any): MetricAwareFilter {
  return {
    filterName: label,
    mode: "IGNORE",
    currentState: { label, value },
    parentFilterGroup: filterGroup,
    filterType: "MetricAwareFilter",
    executorFunction: filterExecutorOptions[filterEvaluator.type].constructor(filterEvaluator.props),
    metricEvaluators: metricEvaluators.map((evaluator: any) => {
      return metricEvaluatorOptions[evaluator.evaluationFunction.type].constructor(evaluator.evaluationFunction.props)
    }),
    shouldEvaluateFilter: true,
    lineSeriesAndMetricData: undefined
  }
}

function createStateMetricFilter(state: State, key: string, showPageTags: boolean): StateMetricFilter {
  return {
    currentState: {label: (state.tag)? state.tag!: "", value: key},
    executorFunction: new StateFilter({mDesc: {name: "", version: ""}, stateKey: key, showTags: showPageTags}),
    filterName: getStateNameForDisplay(state, showPageTags),
    filterType: "MetricAwareFilter",
    metricEvaluators: [
      metricEvaluatorOptions["StateVisitCountMetricEvaluator"].constructor({
        metricLabel: "Visits", isTrendRequired: false, isUpwardTrendGood: true, stateKey: key, showTags: showPageTags
      }),
      metricEvaluatorOptions["UniqueCustomerMetricEvaluator"].constructor({
        metricLabel: "Unique Customers", isTrendRequired: false, isUpwardTrendGood: true
      }),
      ...getPageMetricEvaluators(key, showPageTags)
    ],
    mode: "IGNORE",
    parentFilterGroup: undefined,
    shouldEvaluateFilter: true,
    lineSeriesAndMetricData: undefined,
    incomingTransitions: [],
    isStatePageFilter: showPageTags,
    outgoingTransitions: []
  }
}

export function getStateMetricFilters(avs: AggregatedView[], avsToCompare: AggregatedView[]): FilterGroup {
  const stateToFilterMap = new Map<string, StateMetricFilter>();

  avs.forEach(av => {
    let states = av.states.slice();
    let count = states.length;

    for (let ct = 0; ct < count; ct++) {
      let state = states[ct];
      let stateKey = getStateKey(state, false);
      let stateDisplayName = getStateNameForDisplay(state, false);
      let statePageKey = getStateKey(state, true);
      let statePageDisplayName = getStateNameForDisplay(state, true);

      if (!stateToFilterMap.has(stateDisplayName)) {
        stateToFilterMap.set(stateDisplayName, createStateMetricFilter(state, stateKey, false))
      }

      if (av.filterOutcomes["StateFilterGroup"] === undefined) {
        av.filterOutcomes["StateFilterGroup"] = {}
      }

      av.filterOutcomes["StateFilterGroup"][stateDisplayName] = true;

      if (stateDisplayName !== statePageDisplayName) {
        stateToFilterMap.set(statePageDisplayName, createStateMetricFilter(state, statePageKey, true))
        av.filterOutcomes["StateFilterGroup"][statePageDisplayName] = true;
      }
    }
  })

  avsToCompare.forEach(av => {
    let states = av.states.slice();
    let count = states.length;

    for (let ct = 0; ct < count; ct++) {
      let state = states[ct];
      let stateDisplayName = getStateNameForDisplay(state, false);
      let statePageDisplayName = getStateNameForDisplay(state, true);

      if (av.filterOutcomes["StateFilterGroup"] === undefined) {
        av.filterOutcomes["StateFilterGroup"] = {}
      }

      if (stateToFilterMap.has(stateDisplayName)) {
        av.filterOutcomes["StateFilterGroup"][stateDisplayName] = true;
      }

      if (stateDisplayName !== statePageDisplayName && stateToFilterMap.has(statePageDisplayName)) {
        av.filterOutcomes["StateFilterGroup"][statePageDisplayName] = true;
      }
    }

  })

  let filterGroup: FilterGroup = createFilterGroup("StateFilterGroup", true, getStateFilterGroupEvaluator("StateFilterGroup"));

  Array.from(stateToFilterMap.values())
      .forEach(filter => filterGroup.filters[filter.filterName] = filter);

  return filterGroup;
}

/**
 * Creates a DropDownFilter
 * @param label
 * @param value
 * @param placeholder
 * @param filterGroup
 * @param filterEvaluator
 */
function createDropDownFilter(label: string, value: string, placeholder: string, filterGroup: FilterGroup, filterEvaluator: any): Filter {
  return {
    filterName: label,
    mode: "IGNORE",
    currentState: { label, value },
    parentFilterGroup: filterGroup,
    filterType: "DropdownFilter",
    executorFunction: filterExecutorOptions[filterEvaluator.type].constructor(filterEvaluator.props),
    shouldEvaluateFilter: true
  }
}

/**
 * Checks if autoFilters attribute is defined and adds auto generated filter options from aggregated views
 * @param avs
 * @param filterOptions
 */
function preprocessFilterOptions(avs: AggregatedView[], filterOptions: any) {

  let groupSpecMap: {[x:string]: {mDesc: MetadataDescriptor, fallbackMetadata: MetadataDescriptor, metricEvaluators: any}}= {};
  let groupUniqueValuesMap = new Map<string, Set<string>>();

  filterOptions
      .filter((group: any) => group["autoFilters"] !== undefined)
      .forEach((group: any) => {
        groupSpecMap[group.name] = {
          mDesc: group.evaluationFunction.props.mDesc,
          fallbackMetadata: group.evaluationFunction.props.fallbackMetadata,
          metricEvaluators: group.autoFilters.metricEvaluators
        }
        groupUniqueValuesMap.set(group.name, new Set<string>());
      })

  avs.forEach(av => {
    Object.keys(groupSpecMap)
        .forEach((key:any) => {
          const values = getDistinctValues(groupSpecMap[key].mDesc, av, groupSpecMap[key].fallbackMetadata)

          if (values !== undefined) {
            values.forEach(val => groupUniqueValuesMap.get(key)!.add(val))
          }
        })
  })

  filterOptions
      .filter((group: any) => group["autoFilters"] !== undefined)
      .forEach((group: any) => {
        if (group.values === undefined) {
          group.values = [];
        } else {
          group.values.forEach((value: any) => {
            if (groupUniqueValuesMap.get(group.name)!.has(value.value)) {
              groupUniqueValuesMap.get(group.name)!.delete(value.value)
            }
          })
        }

        Array.from(groupUniqueValuesMap.get(group.name)!)
            .forEach(value => {
              let result: any = {
                "label": value,
                "value": value
              }

              if (group.filterType === "MetricAwareFilter") {
                result["metricEvaluators"] = groupSpecMap[group.name].metricEvaluators
              }
              group.values.push(result)
            })
      })
}

/**
 * Return RegisteredFilters by reading specs provided by the skill and creating Filter objects accordingly.
 */
export function createFilters(avs: AggregatedView[], filterOptions: any): RegisteredFilters {
  const metricAwareFilterGroups: { [group: string]: FilterGroup } = {}
  const dropDownFilterGroups: { [group: string]: FilterGroup } = {};

  preprocessFilterOptions(avs, filterOptions);

  filterOptions
      .forEach((group: any) => {
        if (group.filterType === "MetricAwareFilter") {

          let metricAwareFilterGroup: FilterGroup = createFilterGroup(group.name, false, getDefaultGroupEvaluator(group.name));

          group.values.forEach((val: any) => {
            let metricAwareFilter: MetricAwareFilter = createMetricAwareFilter(val.label, val.value, metricAwareFilterGroup, group.evaluationFunction, val.metricEvaluators);
            metricAwareFilterGroup.filters[metricAwareFilter.filterName] = metricAwareFilter;
          })

          Object.keys(metricAwareFilterGroup.filters).forEach(filterName => {
            metricAwareFilterGroup.options.push(metricAwareFilterGroup.filters[filterName].currentState)
          });

          metricAwareFilterGroups[group.name] = metricAwareFilterGroup;

        } else {

          let dropDownFilterGroup: FilterGroup = createFilterGroup(group.name, false, getDefaultGroupEvaluator(group.name), group.placeholder);

          group.values.forEach((val: any) => {
            let dropDownFilter: Filter = createDropDownFilter(val.label, val.value, group.placeholder!, dropDownFilterGroup, group.evaluationFunction);
            dropDownFilterGroup.filters[dropDownFilter.filterName] = dropDownFilter;
          })

          Object.keys(dropDownFilterGroup.filters).forEach(filterName => {
            dropDownFilterGroup.options.push(dropDownFilterGroup.filters[filterName].currentState)
          });

          dropDownFilterGroups[group.name] = dropDownFilterGroup;

        }
      });

  return {
    metricAwareFilterGroups,
    dropDownFilterGroups,
  }
}
