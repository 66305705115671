import HelpPanel from '@awsui/components-react/help-panel';
import React from "react";

export const toolsTopics: Record<string, React.ReactNode> = {
  // List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
  dataSelectorInfo: (
      <HelpPanel
          header={<h2>Goblin Analytics</h2>}
      >
        <p>
          By default, when the page is loaded, a default range of past 15 days will be selected. Aggregated Views for this date range
          will be downloaded, and processed, post which you can begin applying filters to filter out sessions matching your criteria.
          All visualizations will be updated automatically based on this filtered data.
        </p>
      </HelpPanel>
  ),
  filtersInfo: (
      <HelpPanel
          header={<h2>Data Selection</h2>}
      >
        <p>
          This is the first step in controlling which set of data you analyze. Choosing a date range will download all sessions for that
          date range, and these sessions will be filtered based on the selections you make in this section for different criteria such as
          COR, locale, device type, etc. Note that, if no value is selected for a filter, it is considered inactive (not <i>filter all
          sessions which have a value other than the ones listed in the dropdowns.</i>
        </p>
      </HelpPanel>
  )
};
