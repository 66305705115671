import {MetricAwareFilter, StateMetricFilter} from "../../../../interfaces/filters/filter";
import {AggregatedView} from "../../../../interfaces";
import {evaluateFilterForAggregatedViews, getAnalyticsData} from "../../../../data/dataManager";
import {Pagination, Table, TextFilter} from "@awsui/components-react";
import React, {useEffect, useState} from "react";
import {getDistinctValues} from "../../../../common/stringMetadata";
import {useCollection} from "@awsui/collection-hooks";

export function TableComponent(props: {
    stateMetricFilter: StateMetricFilter
    frictionMetrics: {[x: string]: MetricAwareFilter[]}
}) {

    const [loadInProgress, setLoadInProgress] = useState<boolean>(true);
    const [result, setResult] = useState<{[x:string]: string}[]>([]);

    let aggregatedViews: AggregatedView[] = []


    function getDateTimeFromUnixTimeStamp(timestamp: number) {
        var ts_ms = timestamp;

        // initialize new Date object
        var date_ob = new Date(ts_ms);

        // year as 4 digits (YYYY)
        var year = date_ob.getFullYear();

        // month as 2 digits (MM)
        var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

        // date as 2 digits (DD)
        var date = ("0" + date_ob.getDate()).slice(-2);

        // hours as 2 digits (hh)
        var hours = ("0" + date_ob.getHours()).slice(-2);

        // minutes as 2 digits (mm)
        var minutes = ("0" + date_ob.getMinutes()).slice(-2);

        // seconds as 2 digits (ss)
        var seconds = ("0" + date_ob.getSeconds()).slice(-2);

        var completeTime = date + "-" + month + "-" + year + "-" + hours + ":" + minutes + ":" + seconds;
        return completeTime;
    }


    useEffect(() => {
        setLoadInProgress(true)
        aggregatedViews = evaluateFilterForAggregatedViews(props.stateMetricFilter, getAnalyticsData().filteredViews);

        let temp: {[x:string]: string}[] = []

        Object.keys(props.frictionMetrics)
            .forEach(frictionMetric => {
                props.frictionMetrics[frictionMetric]
                    .forEach(filter => {
                        let filteredAvs: AggregatedView[] = evaluateFilterForAggregatedViews(filter, aggregatedViews);

                        filteredAvs
                            .forEach(av => {
                                temp.push({
                                    "Session Id": av.sessionId,
                                    "Friction": `${filter.filterName} (${frictionMetric})`,
                                    "Intent": `${getDistinctValues({name: "ProcessedIntent", version: "1"}, av)}`,
                                    "Locale": `${getDistinctValues({name: "Locale", version: "1"}, av)}`,
                                    "HFC Category": `${getDistinctValues({name: "HFCCategory", version: "1"}, av)}`,
                                    "Device Type": `${getDistinctValues({name: "DeviceType", version: "1"}, av)}`,
                                    "Timestamp": getDateTimeFromUnixTimeStamp(av.timestamp)
                                })
                            })
                    })
            })

        temp.sort((a:any,b:any) => a["Timestamp"] - b["Timestamp"])

        setResult(temp);
        setLoadInProgress(false)

    }, [])

    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, propertyFilterProps} = useCollection(
        result,
        {
            filtering: {},
            sorting: {},
            pagination: {pageSize: 30}
        }
    );

    return (
        <Table
            {...collectionProps}
            loading={loadInProgress}
            columnDefinitions={result.length > 0 ? Object.keys(result[0])
                .filter(key => key !== "id")
                .map(key => {
                    return {
                        id: key,
                        header: key,
                        cell: (item: any) => item[key],
                        width: 500
                    }
                }): []}
            items={items}
            selectionType="single"
            variant="full-page"
            stickyHeader={true}
            resizableColumns={true}
            wrapLines={true}
            filter={
                <TextFilter
                    {...filterProps}
                    filteringAriaLabel="Filter"
                    filteringPlaceholder="Filter"
                />
            }
            pagination={<Pagination {...paginationProps}/>}
        />
    )
}