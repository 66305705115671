import React, {useEffect, useState} from "react";
import {
    Badge,
    Button,
    Container,
    Header,
} from "@awsui/components-react";
import {setFilterMode, subscribeFilterModeChanges} from "../../../../data/filterStateManager";
import {
    setApplyFilterButtonDisabledFlag,
    setGlobalGraphModalProps,
    subscribeLoadInProgressChanges
} from "../../../../data/globalState";
import {FilterMode, MetricAwareFilter} from "../../../../interfaces/filters/filter";
import {MetricValue} from "./metricsValue";
import {
    evaluateFilterForAggregatedViews,
    evaluateMetricsForFilter,
    getAnalyticsData
} from "../../../../data/dataManager";
import {MILLIS_IN_DAY} from "../../../../common/utils";
import {AggregatedView} from "../../../../interfaces";

export interface MetricsAwareFilterProps {
    metricsAwareFilter: MetricAwareFilter,
    filterGroupName: string,
}

export function MetricsAwareFilter(props: MetricsAwareFilterProps) {

    const [loadInProgress, setLoadInProgress] = useState<boolean>();
    const [mode, setMode] = useState<FilterMode>(props.metricsAwareFilter.mode);

    useEffect(() => {
        console.log(`rendered Filters component ${props.metricsAwareFilter.filterName}`);
        subscribeLoadInProgressChanges(setLoadInProgress);
    }, [setLoadInProgress])

    useEffect(() => {
        subscribeFilterModeChanges(setMode, props.metricsAwareFilter);
    }, [setMode, props.metricsAwareFilter])


    function getModeColor() {
        switch (mode) {
            case "INCLUDE": return "blue";
            case "EXCLUDE": return "red";
            default: return undefined;
        }
    }

    return (
        <Badge color={getModeColor()}>
            <Container header={<Header
                variant={'h3'}
                actions={
                    <>
                        <Button variant={'icon'} iconName={'status-positive'} disabled={mode === "INCLUDE" || loadInProgress}
                                onClick={() => {
                                    setFilterMode("MetricAwareFilter", props.metricsAwareFilter.filterName, props.filterGroupName, "INCLUDE");
                                    setApplyFilterButtonDisabledFlag(false);
                                }}/>
                        <Button variant={'icon'} iconName={'status-negative'} disabled={mode === "EXCLUDE" || loadInProgress}
                                onClick={() => {
                                    setFilterMode("MetricAwareFilter", props.metricsAwareFilter.filterName, props.filterGroupName, "EXCLUDE");
                                    setApplyFilterButtonDisabledFlag(false);
                                }}/>
                        <Button variant={'icon'} iconName={'status-stopped'} disabled={mode === "IGNORE" || loadInProgress}
                                onClick={() => {
                                    setFilterMode("MetricAwareFilter", props.metricsAwareFilter.filterName, props.filterGroupName, "IGNORE");
                                    setApplyFilterButtonDisabledFlag(false);
                                }}/>
                    </>
                }>
                {props.metricsAwareFilter.filterName}
                <Button variant={'icon'} iconName={'expand'} disabled={loadInProgress}
                        onClick={() => {
                            let aggregatedViews: AggregatedView[] = evaluateFilterForAggregatedViews(props.metricsAwareFilter, getAnalyticsData().filteredViews);
                            setGlobalGraphModalProps({
                                additionalInfo: undefined,
                                aggregatedViews: aggregatedViews,
                                lineSeriesData: evaluateMetricsForFilter(props.metricsAwareFilter,
                                    { period: MILLIS_IN_DAY, stat: "sum" },
                                    { period: MILLIS_IN_DAY, isTrendRequired: false },
                                    aggregatedViews,
                                    []).lineDataSeries,
                                title: props.metricsAwareFilter.filterName,
                                yFormatter: undefined,
                                visible: true,
                                metricsAwareFilter: props.metricsAwareFilter
                            });
                        }}/>
            </Header>} disableContentPaddings={true}>
                <MetricValue filterGroupName={props.filterGroupName} metricsAwareFilter={props.metricsAwareFilter}/>
            </Container>
        </Badge>
    )
}
