import {useEffect, useState} from "react";
import {
    setPieChartModalProps,
    subscribePieChartModalPropsChanges
} from "../../../../data/globalState";
import * as React from "react";
import {
    Header,
    Modal, Tabs,
} from "@awsui/components-react";
import {ChartComponent} from "./chart";
import {MetricAwareFilter, StateMetricFilter} from "../../../../interfaces/filters/filter";
import {TableComponent} from "./table";

export interface PieChartModalProps {
    stateMetricFilter: StateMetricFilter | undefined,
    frictionMetrics: {[x: string]: MetricAwareFilter[]},
    attr: {
        title: string,
        tag: string,
        customers: string
    }
    visible: boolean,
}

export function PieChartModal() {

    const [pieChartProps, setPieChartProps] = useState<PieChartModalProps>({
        frictionMetrics: {},
        attr: {
            title: "",
            tag: "",
            customers: ""
        },
        visible: false,
        stateMetricFilter: undefined
    });

    const [currentTabId, setCurrentTabId] = useState<string>("chart");

    useEffect(() => {
        subscribePieChartModalPropsChanges(setPieChartProps);
    }, [setPieChartProps]);

    return (
        <Modal
            onDismiss={() => {
                setPieChartModalProps({...pieChartProps, visible: false})
                setCurrentTabId("chart")
            }}
            visible={pieChartProps.visible}
            closeAriaLabel="Close modal"
            header={
                <>
                    <Header variant={"h2"}>{pieChartProps.attr.title}</Header>
                    <Header variant={"h3"}>{pieChartProps.attr.tag}</Header>
                    <div style={{fontSize: "10px", fontWeight: "bold"}}>Customers: {pieChartProps.attr.customers}</div>
                    <div style={{fontSize: "10px", fontWeight: "bold"}}>Incoming Requests: {pieChartProps.stateMetricFilter?.incomingTransitions.map(t => t.count).reduce((a,b) => {
                        return a+b
                    }, 0)}</div>
                    <div style={{fontSize: "10px", fontWeight: "bold"}}>Outgoing Requests: {pieChartProps.stateMetricFilter?.outgoingTransitions.map(t => t.count).reduce((a,b) => {
                        return a+b
                    }, 0)}</div>
                </>
            }
            size={"large"}
        >
            <Tabs variant={"default"}
                  activeTabId={currentTabId}
                  onChange={(event => {
                      setCurrentTabId(event.detail.activeTabId)
                  })}
                  tabs={[
                      {
                          id: 'chart',
                          label: 'Chart',
                          content: <ChartComponent frictionMetrics={pieChartProps.frictionMetrics}/>
                      },
                      {
                          id: 'table',
                          label: 'Table',
                          content: <TableComponent stateMetricFilter={pieChartProps.stateMetricFilter!} frictionMetrics={pieChartProps.frictionMetrics}/>
                      },
                  ]}
            />
        </Modal>
    )
}
