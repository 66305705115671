import {
    AggregatedView,
    FrictionMetrics,
    MetricData, PageMetrics
} from "../interfaces";
import {FilterEvaluator, FilterEvaluatorProps, MetricAwareFilter} from "../interfaces/filters/filter";
import {getTrend} from "../common/utils";
import {Metric, MetricEvaluator} from "../interfaces/metrics/metric";
import {
    getFrictionSummarySpecs,
    getSummaryMetricEvaluators
} from "../config/metrics/metricConfig";
import {TransitionReasonFilter} from "../interfaces/filters/impl/TransitionReasonFilter";
import {metricEvaluatorOptions} from "../common/specsMap";

class DummyFilter extends FilterEvaluator {
    constructor(props: FilterEvaluatorProps) {
        super(props);
    }
    evaluate(av: AggregatedView, currentState: { label: string, value: string }): boolean {
        return true;
    }
}

function getMetricEvaluators(evaluatorSpecs: []): MetricEvaluator[] {
    let metricEvaluators: MetricEvaluator[] = []


    evaluatorSpecs
        .forEach((metricOption: any) => {
            metricEvaluators.push(metricEvaluatorOptions[metricOption.evaluationFunction.type]
                .constructor({...metricOption.evaluationFunction.props}));
        })

    return metricEvaluators
}

export function getFrictionMetric(avs: AggregatedView[], comparisonAvs: AggregatedView[]): FrictionMetrics[] {

    avs = Array.from(new Set<AggregatedView>(avs))
    comparisonAvs = Array.from(new Set<AggregatedView>(comparisonAvs))

    let frictionSummarySpecs: any = getFrictionSummarySpecs()

    if (frictionSummarySpecs === undefined) return [];

    let frictionData: {[x:string]: MetricAwareFilter[]} = {}

    const transitionReasonToMAFMap = new Map<string, MetricAwareFilter>();
    const transitionReasonToAVMap = new Map<string, AggregatedView[]>();
    const transitionReasonToComparisonAVMap = new Map<string, AggregatedView[]>();

    const transitionReasonToCategoryMap = frictionSummarySpecs["reasonMap"]
    const categoryToMetricSpecMap = frictionSummarySpecs["categories"]

    avs.forEach(av => {
        let transitions = av.transitions.slice();
        let count = transitions.length;

        for (let ct = 0; ct < count; ct++) {
            let transition = transitions[ct];

            if (transition.reason === undefined) continue;

            if (!transitionReasonToMAFMap.has(transition.reason)) {
                let category: string = transitionReasonToCategoryMap[transition.reason];

                if (categoryToMetricSpecMap[category] === undefined) continue;

                let newFilter: MetricAwareFilter = {
                    currentState: {label: "", value: ""},
                    executorFunction: new TransitionReasonFilter({mDesc: {name: "", version: ""}, transitionReason: transition.reason}),
                    filterName: transition.reason,
                    filterType: "MetricAwareFilter",
                    metricEvaluators: getMetricEvaluators(categoryToMetricSpecMap[category].metricEvaluators),
                    mode: "IGNORE",
                    parentFilterGroup: undefined,
                    shouldEvaluateFilter: true,
                    lineSeriesAndMetricData: undefined
                }

                transitionReasonToMAFMap.set(transition.reason, newFilter)

                if (frictionData[category] === undefined) {
                    frictionData[category] = []
                }
                frictionData[category].push(newFilter)
            }

            if (!transitionReasonToAVMap.has(transition.reason)) {
                transitionReasonToAVMap.set(transition.reason, []);
            }

            transitionReasonToAVMap.get(transition.reason)!.push(av);
        }

    })

    comparisonAvs.forEach(av => {
        let transitions = av.transitions.slice();
        let count = transitions.length;

        for (let ct = 0; ct < count; ct++) {
            let transition = transitions[ct];

            if (transition.reason === undefined) continue;

            if (transitionReasonToAVMap.has(transition.reason)) {
                if (!transitionReasonToComparisonAVMap.has(transition.reason)) {
                    transitionReasonToComparisonAVMap.set(transition.reason, []);
                }
                transitionReasonToComparisonAVMap.get(transition.reason)!.push(av);
            }
        }
    })

    transitionReasonToMAFMap.forEach((metricAwareFilter, key) => {

        const metricData: MetricData[] = [];

        for(let metricEvaluator of metricAwareFilter.metricEvaluators) {
            let filteredViewsMetric: Metric = metricEvaluator.evaluate(transitionReasonToAVMap.get(key) !== undefined ? transitionReasonToAVMap.get(key)!: []);
            let comparisonViewsMetric: Metric = metricEvaluator.evaluate(transitionReasonToComparisonAVMap.get(key) !== undefined ? transitionReasonToComparisonAVMap.get(key)!: []);

            metricData.push({
                metricLabel: filteredViewsMetric.metricLabel,
                actualValue: filteredViewsMetric.metricValue,
                comparisonValue: comparisonViewsMetric.metricValue,
                dataTrend: getTrend(filteredViewsMetric.isUpwardTrendGood, filteredViewsMetric.metricValue, comparisonViewsMetric.metricValue),
            })
        }

        metricAwareFilter.lineSeriesAndMetricData =  {
            lineDataSeries: [],
            metricData: metricData
        };
    })

    return Object.keys(frictionData)
        .map(key => {
            return {
                label: key,
                filters: frictionData[key]
            }
        })
}

export function getSummaryMetric(avs: AggregatedView[], comparisonAvs: AggregatedView[]): MetricAwareFilter {

    let summaryMetricEvaluator = getSummaryMetricEvaluators();

    let summaryTable: MetricAwareFilter = {
        currentState: {label: "", value: ""},
        executorFunction: new DummyFilter({mDesc: {name: "", version: ""}}), // this is dummy filter and won't be used anywhere
        filterName: "Summary",
        filterType: "MetricAwareFilter",
        metricEvaluators: [
            metricEvaluatorOptions["RequestCountMetricEvaluator"].constructor({
                metricLabel: "Total Requests", isTrendRequired: false, isUpwardTrendGood: true
            }),
            metricEvaluatorOptions["SessionCountMetricEvaluator"].constructor({
                metricLabel: "Total Sessions", isTrendRequired: false, isUpwardTrendGood: true
            }),
            metricEvaluatorOptions["UniqueCustomerMetricEvaluator"].constructor({
                metricLabel: "Total Unique Customers", isTrendRequired: false, isUpwardTrendGood: true
            }),
            ...summaryMetricEvaluator
        ],
        mode: "IGNORE",
        parentFilterGroup: undefined,
        shouldEvaluateFilter: true,
        lineSeriesAndMetricData: undefined
    }

    const metricData: MetricData[] = [];
    for (let metricEvaluator of summaryTable.metricEvaluators) {
        let filteredViewsMetric: Metric = metricEvaluator.evaluate(avs);
        let comparisonViewsMetric: Metric = metricEvaluator.evaluate(comparisonAvs);

        metricData.push({
            metricLabel: filteredViewsMetric.metricLabel,
            actualValue: filteredViewsMetric.metricValue,
            comparisonValue: comparisonViewsMetric.metricValue,
            dataTrend: getTrend(filteredViewsMetric.isUpwardTrendGood, filteredViewsMetric.metricValue, comparisonViewsMetric.metricValue)
        })

        summaryTable.lineSeriesAndMetricData =  {
            lineDataSeries: [],
            metricData: metricData
        }
    }

    return summaryTable
}

export function convertMetricDataToPageMetrics(metricData: MetricData[]): PageMetrics[] {

    let pageMetrics: PageMetrics[] = []

    metricData
        .forEach(data => {
            pageMetrics.push({
                "id": data.metricLabel,
                "label": data.metricLabel,
                "value": data,
            })
        })

    return pageMetrics;

}
