import {averageValue, sumValue} from './utils';

const percentile = require('stats-percentile');

export type Stats = 'sum' | 'avg' | 'n' | 'p90' | 'p99' | 'p100'
export type StatsFunction = (values: number[]) => number;

export const StatsFunctions: Map<Stats, StatsFunction> = new Map<Stats, StatsFunction>([[
  'sum', (list) => sumValue(list)
], [
  'avg', (list: number[]) => averageValue(list)
], [
  'n', (list: number[]) => list.length
], [
  'p90', (list: number[]) => percentile(list, 90)
], [
  'p99', (list: number[]) => percentile(list, 99)
], [
  'p100', (list: number[]) => percentile(list, 100)
]]);
