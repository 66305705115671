import {AggregatedView} from "../../index";
import {FilterEvaluator, FilterEvaluatorProps} from "../filter";
import {getMetadataByProperty} from "../../../common/stringMetadata";

export interface MetadataJsonValuesFilterProps extends FilterEvaluatorProps {
    metadataJsonProperty: string
}

/**
 * This filter evaluates true if a value is present in a list of values.
 *
 * Calls getDistinctValues() which returns all the distinct values of a metadata
 * and returns true if the filter option is present in the list.
 */
export class MetadataJsonValuesFilter extends FilterEvaluator {

    metadataJsonProperty: string

    constructor(props: MetadataJsonValuesFilterProps) {
        super(props);
        this.metadataJsonProperty = props.metadataJsonProperty
    }

    evaluate(av: AggregatedView, currentState: { label: string, value: string | string[] }): boolean {
        if (currentState === undefined || currentState.value === undefined) return true;
        if (typeof currentState.value === "string") {
            currentState.value = [currentState.value]
        }
        const values = getMetadataByProperty(this.mDesc, av, "values", this.fallbackMetadata);

        if (currentState.value.indexOf("*") >= 0) {
            if (this.valuesToExclude !== undefined) {
                if (typeof values !== 'undefined') {
                    const distilledValues = values.filter(opt => this.valuesToExclude!.indexOf(opt.paymentAmount) < 0)
                    if (distilledValues.length > 0) return true
                }
                return false;
            }
            return true;
        }

        if (typeof values !== 'undefined' && currentState.value.some(opt => values.some((val: any) => val[this.metadataJsonProperty] === opt))) {
            return true
        }// matched
        return false;
    }

}
