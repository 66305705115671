import React, {useEffect, useRef, useState} from 'react';
import G6 from '@antv/g6';
import {Button, Header, SpaceBetween} from "@awsui/components-react";
import {
    subscribeGlobalTransitionAnalysisModeChanges, subscribeTransitionStateMetricsChanges
} from "../../../../../../data/metricStateManager";
import {getKaraokeGraphDataStruct} from "../../../../../../utils/graphUtils";

const colors = ['#1d8102', '#03524a', '#59309d', '#8b1b42', '#bc4d01', '#2c46b1', '#d13212', '#654d03', '#721e03']
let graph = null;

export function KaraokeTransitionsGraph() {
    const ref = useRef(null);
    const toolbarRef = useRef(null);

    const [transitionAnalysisMode, setTransitionAnalysisMode] = useState("customers");
    const [stateMetrics, setStateMetrics] = useState([]);

    const graphDataStruct = getKaraokeGraphDataStruct(stateMetrics, transitionAnalysisMode);

    useEffect(() => {
        subscribeTransitionStateMetricsChanges(setStateMetrics)
    }, [setStateMetrics])

    useEffect(() => {
        subscribeGlobalTransitionAnalysisModeChanges(setTransitionAnalysisMode)
    }, [setTransitionAnalysisMode])

    useEffect(() => {

        G6.registerEdge(
            'custom-label-edge',
            {
                afterDraw(cfg, group) {
                    const shape = group.get('children')[0];
                    const color = colors[Math.floor(Math.random() * 9)];
                    shape.setAttr('stroke', color);
                    shape.setAttr('lineWidth', 4);
                    const midPoint = shape.getPoint(Math.random() / 4 + 0.20);
                    group.addShape('text', {
                        attrs: {
                            text: `${cfg['displayName']}`,
                            x: midPoint.x - 9,
                            y: midPoint.y - 9,
                            fill: color,
                            fontSize: Math.min(25, 20 + 20 * cfg['strength']),
                            textAlign: 'center',
                            textBaseline: 'middle',
                            fontWeight: 'bold',
                        },
                    });
                },
                update: undefined,
            },
            'polyline',
        );

        G6.registerNode(
            'sql',
            {
                drawShape(cfg, group) {
                    const rect = group.addShape('rect', {
                        attrs: {
                            x: -150,
                            y: -50,
                            width: 300,
                            height: 100,
                            radius: 10,
                            stroke: '#5B8FF9',
                            fill: '#C6E5FF',
                            lineWidth: `${15 * cfg['strength']}`,
                        },
                        name: 'rect-shape',
                    });
                    group.addShape('text', {
                        attrs: {
                            text: `${cfg['displayName']}`,
                            x: 0,
                            y: -35,
                            fill: '#00287E',
                            fontSize: 20,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            fontWeight: 'bold',
                        },
                        name: 'text-shape',
                    });
                    group.addShape('text', {
                        attrs: {
                            text: `Customers = ${cfg['uniqueCustomers']}`,
                            x: 0,
                            y: -11,
                            fill: '#125e61',
                            fontSize: 18,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            fontWeight: 'bold'
                        },
                    });
                    group.addShape('text', {
                        attrs: {
                            text: `Time = ${cfg['timeSpent']} / Views = ${cfg['pageViews']}`,
                            x: 0,
                            y: 11,
                            fill: '#125e61',
                            fontSize: 18,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            fontWeight: 'bold'
                        },
                    });
                    group.addShape('text', {
                        attrs: {
                            text: `Session Ends = ${cfg['sessionEnds']}`,
                            x: 0,
                            y: 33,
                            fill: '#831f1f',
                            fontSize: 18,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            fontWeight: 'bolder'
                        },
                    })
                    return rect;
                },
            },
            'single-node',
        );

        if (graph !== null) graph.destroy();
        const toolbar = new G6.ToolBar({
            container: toolbarRef.current
        });
        graph = new G6.Graph({
            container: ref.current,
            width: 1500,
            height: 800,
            fitViewPadding: 20,
            layout: {
                type: 'dagre',
                nodesepFunc: (d) => {
                    return 150;
                },
                ranksep: 200,
                rankdir: 'LR',
                controlPoints: true,
            },
            nodeStateStyles: {
                selected: {
                    stroke: '#d9d9d9',
                    fill: '#5394ef',
                },
                inactive: {
                    opacity: '0.5',
                    fillOpacity: '0.5'
                }
            }, edgeStateStyles: {
                inactive: {
                    opacity: '0.5',
                    fillOpacity: '0.5'
                }
            },
            defaultNode: {
                type: 'sql',
            },
            defaultEdge: {
                type: 'custom-label-edge',
                // autorotate: true,
                // labelCfg: {
                //     refY: 10,
                //     style: {
                //         fontWeight: 'bold',
                //         fontSize: 20
                //     }
                // },
                style: {
                    radius: 20,
                    offset: 45,
                    endArrow: {path: G6.Arrow.vee(), fill: '#D'},
                    lineWidth: 2,
                    stroke: '#D'
                },
            },
            plugins: [toolbar],
            modes: {
                default: [
                    'drag-canvas',
                    'drag-node'
                ],
            },
        });

        // listen to the node click event
        //graph.on('node:click', displayChart);
        graph.data(graphDataStruct);
        graph.render();
        graph.on('afterlayout', () => graph.fitView());

    }, [graphDataStruct])


    return (
        <>
            <Header actions={<SpaceBetween size={'xs'} direction={'horizontal'}>
                <Button variant={'link'}>
                    <div ref={toolbarRef}/>
                </Button>
            </SpaceBetween>}/>
            <div ref={ref} style={{width: 1200, height: 800}}/>
            <Header actions={<SpaceBetween size={'xs'} direction={'horizontal'}>
                <Button variant={'normal'} onClick={() => graph.downloadFullImage('transitions', 'image/jpeg', {
                    backgroundColor: '#ffffff'
                })}>Download image</Button>
            </SpaceBetween>}/>
        </>

    );
}
