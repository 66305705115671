import {AggregatedView} from "../../index";
import {Metric, MetricEvaluator, StateMetricEvaluatorProps} from "../metric";
import {getStateKey} from "../../../common/utils";

/**
 * This evaluates the total execution time of a given state
 * The given state is filtered using {@link stateKey} and {@link showTags}
 */
export class StateExecutionTimeMetric extends MetricEvaluator {

    stateKey: string;
    showTags: boolean

    constructor(props: StateMetricEvaluatorProps) {
        super(props);
        this.stateKey = props.stateKey;
        this.showTags = props.showTags;
    }

    evaluate(aggregatedViews: AggregatedView[]): Metric {

        let uniqueAggregatedViews: AggregatedView[] = Array.from(new Set<AggregatedView>(aggregatedViews))

        let seriesStatValue: number = 0;
        uniqueAggregatedViews.forEach(av => {
            for (let state of av.states.filter(state => getStateKey(state, this.showTags) === this.stateKey)) {
                if (state.executionTime !== undefined) {
                    seriesStatValue += state.executionTime
                }
            }
        })

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue,
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
