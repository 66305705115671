import React from 'react';
import {render} from 'react-dom';
import {reportWebVitals} from "./reportWebVitals";
import 'core-js/features/promise';
import '@awsui/global-styles/index.css';
import App from "./App";

document.addEventListener('DOMContentLoaded', () => {
  render(
      <React.StrictMode>
        <App/>
      </React.StrictMode>,
  document.querySelector('#app')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
