import {AggregatedView, MetadataDescriptor} from "../../index";
import {Metric, MetricEvaluator, MetricEvaluatorProps} from "../metric";
import {getDistinctValues} from "../../../common/stringMetadata";

export interface ValuePresentMetricProps extends MetricEvaluatorProps {
    value: string | string[];
    mDesc: MetadataDescriptor;
    fallbackMetadata?: MetadataDescriptor
}

/**
 * This evaluator increments the metric count if any value in metadata list is present in the input {@link value} list.
 * Eg: if value 'success' is present in ExecutionStatusMetadata list increment the count.
 */
export class ValuePresentMetric extends MetricEvaluator {

    value: string[];
    mDesc: MetadataDescriptor;
    fallbackMetadata?: MetadataDescriptor;

    constructor(props: ValuePresentMetricProps) {
        super(props);
        this.value = typeof props.value === 'string'? [props.value] : props.value;
        this.mDesc = props.mDesc
        this.fallbackMetadata = props.fallbackMetadata
    }

    evaluate(aggregatedViews: AggregatedView[]): Metric {

        let seriesStatValue: number = 0;
        aggregatedViews.forEach(av => {
            const values = getDistinctValues(this.mDesc, av, this.fallbackMetadata);
            if (typeof values !== 'undefined' && this.value.some(opt => values.indexOf(opt) >= 0)) return seriesStatValue += 1;
        })

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue,
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
