import {GraphLayoutPrefs} from "../../../../../../interfaces";

const BASIC_GRAPH_LAYOUT: GraphLayoutPrefs = {
  "HOME_SCREEN_TOKEN": ["SEARCH_SCREEN_TOKEN", "COLLECTION_TOKEN", "LIST_SONG_SCREEN_TOKEN", "FAVOURITES_TOKEN", "PLAYER_SCREEN_TOKEN", "SETTINGS_SCREEN_TOKEN"],
  "SEARCH_SCREEN_TOKEN": ["LIST_SONG_SCREEN_TOKEN", "COLLECTION_TOKEN", "PLAYER_SCREEN_TOKEN"],
  "FAVOURITES_TOKEN": ["SONG_DETAILS_TOKEN", "PLAYER_SCREEN_TOKEN"],
  "COLLECTION_TOKEN": ["SONG_DETAILS_TOKEN", "PLAYER_SCREEN_TOKEN"],
  "LIST_SONG_SCREEN_TOKEN": ["SONG_DETAILS_TOKEN", "PLAYER_SCREEN_TOKEN"],
  "PLAYER_SCREEN_TOKEN": ["END_OF_SONG"],
  "SETTINGS_SCREEN_TOKEN": ["SUBSCRIPTION"],
  "SONG_DETAILS_TOKEN": ["PLAYER_SCREEN_TOKEN", "SUBSCRIPTION"],
  "END_OF_SONG": [],
  "SUBSCRIPTION": []
};

// TODO load from local storage
export function getKaraokeGraphLayout() {
  return BASIC_GRAPH_LAYOUT;
}
