import React, {useEffect, useState} from 'react';
import AppLayout from '@awsui/components-react/app-layout';
import Flashbar, {FlashbarProps} from '@awsui/components-react/flashbar';
import {toolsTopics} from "../../config/tools";
import {
  Box,
  Button, Header,
  SpaceBetween, StatusIndicator,
} from "@awsui/components-react";
import {DateRangeSelector} from "./data/dateRangeSelector";
import {DataRegionSelector} from "./data/dataRegionSelector";
import {DateComparisonSelector} from "./data/dateRangeComparisonSelector";
import {loadComparisonData, loadData, stopLoad} from "../../data/dataManager";
import {
  subscribeComparisonLoadButtonDisabledFlagChanges,
  subscribeLoadButtonDisabledFlagChanges,
  subscribeLoadInProgressChanges,
  subscribeNotificationChanges, subscribeStopLoadButtonDisabledFlagChanges
} from "../../data/globalState";
import Link from "@awsui/components-react/link";
import {Visualizations} from "./visualizations/visualizations";
import {appLayoutLabels} from "../../common/labels";
import {NotificationItem} from "../../interfaces";
import goblin from "../../assets/goblin.png";


export function AnalyticsLayout() {
  const [toolsTopic, setToolsTopic] = useState('dataSelectorInfo');
  const [toolsOpen, setToolsOpen] = useState(false);

  const onTopicSelect = function (topic: string) {
    setToolsTopic(topic);
    setToolsOpen(true);
  }

  // global notification for the analytics page
  const [notificationItems, setNotificationItems] = useState<NotificationItem[]>([]);

  const [loadButtonDisabledFlag, setLoadButtonDisabledFlag] = useState<boolean>(true);
  const [comparisonLoadButtonDisabledFlag, setComparisonLoadButtonDisabledFlag] = useState<boolean>(true);
  const [stopLoadButtonDisabledFlag, setStopLoadButtonDisabledFlag] = useState<boolean>(true);
  const [loadInProgress, setLoadInProgress] = useState<boolean>();

  useEffect(() => {
    subscribeNotificationChanges(setNotificationItems);
  }, [setNotificationItems])

  useEffect(() => {
    subscribeLoadButtonDisabledFlagChanges(setLoadButtonDisabledFlag);
  }, [setLoadButtonDisabledFlag])

  useEffect(() => {
    subscribeComparisonLoadButtonDisabledFlagChanges(setComparisonLoadButtonDisabledFlag);
  }, [setComparisonLoadButtonDisabledFlag])

  useEffect(() => {
    subscribeStopLoadButtonDisabledFlagChanges(setStopLoadButtonDisabledFlag);
  }, [setStopLoadButtonDisabledFlag])

  useEffect(() => {
    subscribeLoadInProgressChanges(setLoadInProgress);
  }, [setLoadInProgress])

  const StopButton = () => {
    const buttonStyles = {
      backgroundColor: '#d13212',
      padding: '5px 15px 5px 0px',
      border: 'none',
      borderRadius: '2px',
      cursor: 'pointer',
    };

    return (
          <button style={buttonStyles} onClick={stopLoad} hidden={stopLoadButtonDisabledFlag}>
            <StatusIndicator type={"warning"}>
              <span style={{color: "white"}}>
                <b>Stop Loading</b>
              </span>
            </StatusIndicator>
          </button>
    );
  };

  return (
      <AppLayout
          content={
            <SpaceBetween size={'xxl'} direction={'vertical'}>
              <Box textAlign={"center"}>
                <Box>
                  <img height={100} width={150} src={goblin}/>
                  <h1>Goblin Analytics Dashboard</h1>
                </Box>
              </Box>
              <Header variant={'h2'} info={<Link variant="info" onFollow={() => onTopicSelect('dataSelectorInfo')}>Info</Link>}>Data Selection</Header>
              <SpaceBetween size={'xxl'} direction={'horizontal'}>
                <DateRangeSelector/>
                <DataRegionSelector/>
                <DateComparisonSelector/>
                <Button variant={"primary"} onClick={loadData} disabled={loadButtonDisabledFlag} loading={loadInProgress}>Load data</Button>
                <Button variant={"primary"} onClick={loadComparisonData} disabled={comparisonLoadButtonDisabledFlag} loading={loadInProgress}>Load Comparison data</Button>
                <StopButton/>
              </SpaceBetween>
              <Visualizations/>
            </SpaceBetween>
          }
          notifications={<Flashbar items={notificationItems as FlashbarProps.MessageDefinition[]}/>}
          navigationHide={true}
          tools={toolsTopics[toolsTopic]}
          toolsOpen={toolsOpen}
          onToolsChange={event => setToolsOpen(event.detail.open)}
          ariaLabels={appLayoutLabels}
      />
  );
}
