import * as React from "react";
import {useEffect, useState} from "react";
import {setRegions, subscribeRegionsChanges} from "../../../data/dataSelectState";
import {Header, Multiselect, SpaceBetween} from "@awsui/components-react";
import {OptionDefinition} from "@awsui/components-react/internal/components/option/interfaces";
import {subscribeLoadInProgressChanges} from "../../../data/globalState";
import {getRegionalConfig, loadSkillSpecifications} from "../../../config/configs";


export function DataRegionSelector() {
  const [value, setValue] = React.useState<string[]>();
  const [loadInProgress, setLoadInProgress] = useState<boolean>(true);

  const getOptions = (list: string[]) => {
    const result: OptionDefinition[] = []
    list.forEach(l => result.push({label: l, value: l}));
    return result;
  }

  useEffect(() => {
    console.log("rendering data region selector");

    if (typeof value === 'undefined') {
        setRegions(getRegionalConfig().map(r => r.region));
    }

    subscribeRegionsChanges(setValue)
    subscribeLoadInProgressChanges(setLoadInProgress);
  }, [setValue]);

  return (
      <SpaceBetween size={'xxs'} direction={'vertical'}>
        <Header variant={"h3"}>Service regions</Header>
        <Multiselect
            placeholder={"Select regions"}
            onChange={d => setRegions(d.detail.selectedOptions.map(o => o.value!))}
            selectedOptions={typeof value === 'undefined' ? [] : getOptions(value)}
            options={getOptions(getRegionalConfig().map(r => r.region))}
            disabled={loadInProgress}
        />
      </SpaceBetween>
  );
}
