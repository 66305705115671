import {AggregatedView} from "../../index";
import {FilterEvaluator, FilterEvaluatorProps, FilterType} from "../filter";
import {getDistinctValues} from "../../../common/stringMetadata";
import {getDeviceFamilies, getDeviceFamilyFor} from "../../../common/deviceFamily";
import {getFilterGroup} from "../../../data/filterStateManager";


/**
 * Props for DeviceFamilyFilter
 * filterType: this is used to query the filterGroup.
 * filterGroup: this is used to query the filterGroup.
 */
export interface DeviceFamilyFilterProps extends FilterEvaluatorProps {
    filterType: FilterType
    filterGroup: string
}

/**
 * This filter is specific to deviceFamily metadata which has OTHERS option in the filter.
 * This filter evaluates true if a value is present in a list of values.
 * For OTHERS option it includes all deviceFamilies from getDeviceFamilies() list.
 * Calls getDistinctValues() which returns all the distinct values of a metadata
 * and returns true if the filter option is present in the list
 */
export class DeviceFamilyFilter extends FilterEvaluator {

    filterType: FilterType
    filterGroup: string

    constructor(props: DeviceFamilyFilterProps) {
        super(props);
        this.filterType = props.filterType
        this.filterGroup = props.filterGroup
    }

    evaluate(av: AggregatedView, currentState: { label: string, value: string | string[] }): boolean {
        if (currentState === undefined || currentState.value === undefined) return true;
        if (typeof currentState.value === "string") {
            currentState.value = [currentState.value]
        }
        if (currentState.value.indexOf("*") >= 0) return true;
        const toBeMatchedValues: string[] = [...currentState.value]
        if (currentState.value.indexOf("OTHERS") >= 0) {
            const notSelected: string[] = getFilterGroup(this.filterType, this.filterGroup)
                .options
                .map(v => v.value)

            getDeviceFamilies()
                .filter(v => notSelected.indexOf(v) < 0)
                .forEach(v => toBeMatchedValues.push(v))
        }

        const values = getDistinctValues(this.mDesc, av, this.fallbackMetadata);
        const families = typeof values === 'undefined' ? undefined : values.filter(v => v !== 'None').map(val => getDeviceFamilyFor(val))
        if (typeof families !== 'undefined' && toBeMatchedValues.some(opt => families.indexOf(opt) >= 0)) return true; // matched
        return false;
    }

}
