import {AggregatedView} from "../../index";
import {EvaluationProps, Metric, MetricEvaluator, MetricEvaluatorProps} from "../metric";

export interface SessionTurnCountMetricProps extends MetricEvaluatorProps{
    latencyPercent: number
}

/**
 * Returns the percentile of session turns in an aggregated list
 * The percentile is defined using {@link latencyPercent}
 */
export class SessionTurnCountMetric extends MetricEvaluator {

    latencyPercent: number

    constructor(props: SessionTurnCountMetricProps) {
        super(props);
        this.latencyPercent = props.latencyPercent;
    }

    evaluate(aggregatedViews: AggregatedView[], props?: EvaluationProps): Metric {

        let sessionTurnCountList: number[] = []
        let seriesStatValue = 0;
        aggregatedViews.forEach(av => {
            if (av.icDescriptors !== undefined) {
                sessionTurnCountList.push(av.icDescriptors!.length);
            }
        })

        if ((this.latencyPercent != undefined && this.latencyPercent > 0)) {
            sessionTurnCountList.sort(function(a, b){return a - b});
        }

        var sum = 0;
        var n = (this.latencyPercent == undefined || this.latencyPercent == 0) ? sessionTurnCountList.length : sessionTurnCountList.length * (this.latencyPercent / 100);

        for (var i = 0; i < n; i++) {
            sum += sessionTurnCountList[i];
        }

        if (n > 0) {
            seriesStatValue = (sum / n);
        }

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue.toFixed(1),
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
