import {AggregatedView, MetadataDescriptor} from '../interfaces';
import {frequencyCounter, getErrorMessage} from './utils';

/**
 * Get frequencies of each value for a string metadata.
 *
 * Rules:
 * 1. If the result of frequencies operation exists for the metadata name and version, return it.
 * 2. If the result of values operations for the metadata name and version exists, construct frequencies from it and return it.
 * 3. Fallback to the fallback metadata if not undefined, otherwise returns undefined.
 */
export function getFrequencies(mDesc: MetadataDescriptor, av: AggregatedView,
                               fallbackMetadata?: MetadataDescriptor): { [op: string]: number } | undefined {
  if (typeof av.metadata[mDesc.name] !== 'undefined' && typeof av.metadata[mDesc.name][mDesc.version] !== 'undefined') {
    const operationResults = av.metadata[mDesc.name][mDesc.version].values[0] as { [operation: string]: any };

    if (!operationResults.hasOwnProperty('frequencies') && !operationResults.hasOwnProperty('values')) {
      throw new Error(`failed to evaluate frequencies for ${mDesc.name}/${mDesc.version}. No values/frequencies found (most likely).`);
    }

    if (operationResults.hasOwnProperty('frequencies')) {
      return operationResults['frequencies'] as { [value: string]: number };
    } else {
      return frequencyCounter(operationResults['values'] as string[]);
    }
  }

  return typeof fallbackMetadata === 'undefined' ? undefined : getFrequencies(fallbackMetadata, av);
}

/**
 * Get distinct values for a string metadata.
 *
 * Rules:
 * 1. If the result of distinct_values operation exists for the metadata name and version, return it.
 * 2. If the result of {@link getFrequencies} is defined for the metadata name and version, return its keys.
 * 3. Fallback to the fallback metadata if not undefined, otherwise returns undefined.
 */
export function getDistinctValues(mDesc: MetadataDescriptor, av: AggregatedView,
                                  fallbackMetadata?: MetadataDescriptor): string[] | undefined {
  if (typeof av.metadata[mDesc.name] !== 'undefined' && typeof av.metadata[mDesc.name][mDesc.version] !== 'undefined') {
    const operationResults = av.metadata[mDesc.name][mDesc.version].values[0] as { [operation: string]: any };

    if (operationResults.hasOwnProperty('distinct_values')) {
      return operationResults['distinct_values'] as string[];
    } else {
      try {
        return Object.keys(getFrequencies(mDesc, av)!);
      } catch (e) {
        if (e instanceof Error) console.log(e.stack);
        throw new Error(
            `failed to evaluate distinct values for ${mDesc.name}/${mDesc.version}. No values/frequencies/
                    distinct_values found (most likely). Cause: ${getErrorMessage(e)}`);
      }
    }
  }

  return typeof fallbackMetadata === 'undefined' ? undefined : getDistinctValues(fallbackMetadata, av);
}

/**
 * Get values for a metadata (irrespective of Metadata Type)
 *
 * Rules:
 * 1. If the result of distinct_values operation exists for the metadata name and version, return it.
 * 2. If the result of sum operation exists for the metadata name and version, return it.
 * 3. If the result of values operation exists for the metadata name and version, return it.
 * 4. Return the result of {@link getFrequencies} at last
 * 5. Fallback to the fallback metadata if not undefined, otherwise returns undefined.
 *
 * @param mDesc
 * @param av
 * @param fallbackMetadata
 */
export function getMetadataValues(mDesc: MetadataDescriptor, av: AggregatedView,
                                  fallbackMetadata?: MetadataDescriptor): any[] | undefined {
  if (typeof av.metadata[mDesc.name] !== 'undefined' && typeof av.metadata[mDesc.name][mDesc.version] !== 'undefined') {
    const operationResults = av.metadata[mDesc.name][mDesc.version].values[0] as { [operation: string]: any };

    if (operationResults.hasOwnProperty('distinct_values')) {
      return operationResults['distinct_values'] as string[];
    }

    if (operationResults.hasOwnProperty('sum')) {
      return [operationResults['sum']];
    }

    if (operationResults.hasOwnProperty('values')) {
      return operationResults['values'] as string[];
    }

    try {
      return Object.keys(getFrequencies(mDesc, av)!);
    } catch (e) {
      if (e instanceof Error) console.log(e.stack);
      throw new Error(
          `failed to evaluate distinct values for ${mDesc.name}/${mDesc.version}. No values/frequencies/
                    distinct_values found (most likely). Cause: ${getErrorMessage(e)}`);
    }
  }

  return typeof fallbackMetadata === 'undefined' ? undefined : getMetadataValues(fallbackMetadata, av);
}

/**
 * Returns a property of Metadata if exists else falls back to fallbackMetadata.
 * @param mDesc
 * @param av
 * @param property
 * @param fallbackMetadata
 */
export function getMetadataByProperty(mDesc: MetadataDescriptor, av: AggregatedView, property: string,
                                      fallbackMetadata?: MetadataDescriptor): any[] | undefined {
  if (typeof av.metadata[mDesc.name] !== 'undefined' && typeof av.metadata[mDesc.name][mDesc.version] !== 'undefined') {
    const operationResults = av.metadata[mDesc.name][mDesc.version].values[0] as { [operation: string]: any };

    if (operationResults.hasOwnProperty(property)) {
      return operationResults[property] as any[];
    }
  }

  return typeof fallbackMetadata === 'undefined' ? undefined : getMetadataByProperty(fallbackMetadata, av, property);
}