import {
    GraphLayoutPrefs,
    KaraokeGraphDataStruct,
    PageMetrics, PWAGraphDataStruct,
    TransitionAnalysisMode, TransitionMetrics
} from "../interfaces";
import {convertMillsToReadable, getFrictionMetricSum, numberWithFraction, sumValue} from "../common/utils";
import {getKaraokeGraphLayout} from "../components/analytics/visualizations/transitions/graph/karaoke/prefs";
import {convertStateMetricsToPageMetrics} from "./pageMetricsUtils";
import {MetricAwareFilter, StateMetricFilter} from "../interfaces/filters/filter";

export function getKaraokeGraphDataStruct(stateMetrics: StateMetricFilter[], analysisMode: TransitionAnalysisMode): KaraokeGraphDataStruct {
    const result: KaraokeGraphDataStruct = {nodes: [], edges: []};

    let pageMetrics: PageMetrics[] = convertStateMetricsToPageMetrics(stateMetrics, false, false);

    const totalTimeSpent = sumValue(pageMetrics
        .filter(p => p["Page"] !== "SESSION_STARTED" && p["Page"] !== "SESSION_END")
        .map(p => p["Time Spent"].actualValue));
    const totalPageViews = sumValue(pageMetrics
        .filter(p => p["Page"] !== "SESSION_STARTED" && p["Page"] !== "SESSION_END")
        .map(p => p["Visits"].actualValue));

    const layout: GraphLayoutPrefs = getKaraokeGraphLayout()

    Object.keys(layout)
        .forEach(page => {
            const matches: PageMetrics[] = pageMetrics.filter(p => p["Page"] === page)

            if (matches.length !== 0) {
                const pageMatch: PageMetrics = matches[0]
                const sessionEndingTransitions = pageMatch["Outgoing Transitions"].filter((t: any) => t.destinationDisplay === "SESSION_END")

                result.nodes.push({
                    id: pageMatch["id"],
                    label: "",
                    displayName: pageMatch["Page"],
                    pageViews: pageMatch["Visits"].actualValue.toLocaleString(window.navigator.language),
                    timeSpent: convertMillsToReadable(pageMatch["Time Spent"].actualValue),
                    uniqueCustomers: pageMatch["Unique Customers"].actualValue.toLocaleString(window.navigator.language),
                    strength: Math.max(pageMatch["Time Spent"].actualValue / totalTimeSpent, pageMatch["Visits"].actualValue / totalPageViews),
                    sessionEnds: sessionEndingTransitions.length === 0 ? "0 (0%)" : getTransitionDescription(pageMatch, sessionEndingTransitions[0], analysisMode)
                })
            }
        })

    Object.keys(layout)
        .forEach(page => {
            layout[page].forEach(nextPage => {

                const originMatch = pageMetrics.filter(p => p["Page"] === page)
                const destinationMatch = pageMetrics.filter(p => p["Page"] === nextPage)
                const transitionMatch = originMatch.length === 0 ? [] : originMatch[0]["Outgoing Transitions"].filter((t: any) => t.destinationDisplay === nextPage)

                if (originMatch.length !== 0 && destinationMatch.length !== 0 && transitionMatch.length !== 0) {

                    result.edges.push({
                        id: transitionMatch[0].id,
                        source: transitionMatch[0].originId,
                        target: transitionMatch[0].destinationId,
                        label: '',
                        displayName: getTransitionDescription(originMatch[0], transitionMatch[0], analysisMode),
                        sourceDisplayName: transitionMatch[0].originDisplay,
                        destinationDisplayName: transitionMatch[0].destinationDisplay,
                        strength: Math.max(transitionMatch[0].customers / originMatch[0]["Unique Customers"].actualValue, transitionMatch[0].count / originMatch[0]["Visits"].actualValue)
                    })
                }
            })
        })

    return result;
}

export function getPWAGraphDataStruct(stateMetrics: StateMetricFilter[], analysisMode: TransitionAnalysisMode): PWAGraphDataStruct {

    const result: PWAGraphDataStruct = {nodes: [], edges: []};

    let pageMetrics: PageMetrics[] = convertStateMetricsToPageMetrics(stateMetrics, true, true);

    const totalPageViews = sumValue(pageMetrics
        .filter(p => p["Page"] !== "SESSION_STARTED" && p["Page"] !== "session_ended" && p["Page"] !== "SESSION_END")
        .map(p => p["Visits"].actualValue));

    const layout: GraphLayoutPrefs = getPWAGraphLayout(pageMetrics, totalPageViews)

    Object.keys(layout)
        .forEach(page => {
            let correspondingStateMetricIndex = 0;
            const matches: PageMetrics[] = pageMetrics.filter((p, index )=> {
                if (p["Page"] === page) {
                    correspondingStateMetricIndex = index;
                    return true
                }
                return false
            })

            if (matches.length !== 0) {
                const pageMatch: PageMetrics = matches[0]

                let inlineFrictionLabel: string | undefined = getInlineFrictionMetricDescription(pageMatch["Friction Metrics"]);

                result.nodes.push({
                    id: pageMatch["id"],
                    stateMetricFilter: stateMetrics[correspondingStateMetricIndex],
                    incoming: stateMetrics[correspondingStateMetricIndex].incomingTransitions.map(t => t.count).reduce((a,b) => {
                        return a+b
                    }, 0),
                    outgoing: stateMetrics[correspondingStateMetricIndex].outgoingTransitions.map(t => t.count).reduce((a,b) => {
                        return a+b
                    }, 0),
                    displayName: pageMatch["Page"],
                    displayTag: pageMatch["Page Tag"],
                    pageViews: pageMatch["Visits"].actualValue.toLocaleString(window.navigator.language),
                    frictionLabel: inlineFrictionLabel,
                    frictionMetrics: pageMatch["Friction Metrics"],
                    uniqueCustomers: pageMatch["Unique Customers"].actualValue.toLocaleString(window.navigator.language),
                    strength: pageMatch["Visits"].actualValue / totalPageViews
                })
            }
        })

    Object.keys(layout)
        .forEach(page => {
            layout[page].forEach(nextPage => {

                const originMatch = pageMetrics.filter(p => p["Page"] === page)
                const destinationMatch = pageMetrics.filter(p => p["Page"] === nextPage)
                const transitionMatch = originMatch.length === 0 ? [] : originMatch[0]["Outgoing Transitions"].filter((t: any) => t.destinationDisplay === nextPage)

                if (originMatch.length !== 0 && destinationMatch.length !== 0 && transitionMatch.length !== 0) {
                    result.edges.push({
                        id: transitionMatch[0].id,
                        source: transitionMatch[0].originId,
                        target: transitionMatch[0].destinationId,
                        label: '',
                        displayName: getTransitionDescription(originMatch[0], transitionMatch[0], analysisMode),
                        sourceDisplayName: transitionMatch[0].originDisplay,
                        destinationDisplayName: transitionMatch[0].destinationDisplay,
                        strength: Math.max(transitionMatch[0].customers / originMatch[0]["Unique Customers"].actualValue, transitionMatch[0].count / originMatch[0]["Visits"].actualValue)
                    })
                }
            })
        })

    return result;
}

export function getInlineFrictionMetricDescription(item: { [x: string]: MetricAwareFilter[] }) {

    if (item === undefined) return undefined;

    let frictionMetricSum = getFrictionMetricSum(item)

    let result: string = ""

    Object.keys(frictionMetricSum)
        .forEach(key => {
            result += `${key}: ${frictionMetricSum[key].actualSum.toLocaleString(window.navigator.language)} / `
        })

    if (result.length === 0) return undefined
    return result.slice(0, -2)
}

export function getTransitionDescription(page: PageMetrics, transition: TransitionMetrics, analysisMode: TransitionAnalysisMode) {

    const sessionEndMetric = analysisMode === 'customers' ? transition.customers : transition.count
    const sessionEndPercentage = numberWithFraction(100 * sessionEndMetric / (analysisMode === 'customers' ? page["Unique Customers"].actualValue : page["Visits"].actualValue), 0)
    return `${sessionEndMetric.toLocaleString(window.navigator.language)} (${sessionEndPercentage.toLocaleString(window.navigator.language)}%)`;
}

export function getPWAGraphLayout(pageMetrics: PageMetrics[], totalPageViews: number): GraphLayoutPrefs {

    const result: GraphLayoutPrefs = {};

    pageMetrics
        .filter(p => p["Page"] !== "session_ended" && p["Page"] !== "SESSION_END"
            // && (20 * p["Visits"].actualValue > totalPageViews)
        )
        .forEach(p => {
            result[p["Page"]] = [];

            p["Outgoing Transitions"]
                .filter((t: any) => t.destinationDisplay !== "session_ended" && t.destinationDisplay !== "SESSION_END"
                    // && (20 * t.count > p["Visits"].actualValue || 20 * t.customers > p["Unique Customers"].actualValue)
                )
                .forEach((t: any) => result[p["Page"]].push(t.destinationDisplay))
        })

    return result;
}
