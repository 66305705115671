import {FilterEvaluator, FilterEvaluatorProps} from "../filter";
import {AggregatedView} from "../../index";

export interface TransitionReasonFilterProps extends FilterEvaluatorProps {
    transitionReason: string;
}
/**
 * This filter evaluates to true if a transitionReason for a transition is same as {@link transitionReason}.
 */
export class TransitionReasonFilter extends FilterEvaluator {

    transitionReason: string;

    constructor(props: TransitionReasonFilterProps) {
        super(props);
        this.transitionReason = props.transitionReason;
    }

    evaluate(av: AggregatedView, currentState: { label: string, value: string | string[] }): boolean {
        let transitions = av.transitions.slice();
        for (let transition of transitions) {
            if (transition.reason === this.transitionReason) return true;
        }
        return false;
    }

}
