import {AggregatedView, MetadataDescriptor} from '../interfaces';
import {averageValue, numberWithFraction, sumValue} from './utils';

/**
 * Get sum for a numeric metadata.
 *
 * Rules:
 * 1. If the result of sum operation exists for the metadata name and version, return it.
 * 2. If the result of values operations for the metadata name and version exists as a fallback, compute sum from it and return it.
 * 3. Fallback to the fallback metadata if not undefined, otherwise returns undefined.
 */
export function getSum(mDesc: MetadataDescriptor, av: AggregatedView,
                       fallbackMetadata?: MetadataDescriptor): number | undefined {
  if (typeof av.metadata[mDesc.name] !== 'undefined' && typeof av.metadata[mDesc.name][mDesc.version] !== 'undefined') {
    const operationResults = av.metadata[mDesc.name][mDesc.version].values[0] as { [operation: string]: any };

    if (!operationResults.hasOwnProperty('sum') && !operationResults.hasOwnProperty('values')) {
      throw new Error(`failed to evaluate sum for ${mDesc.name}/${mDesc.version}. No sum/values found (most likely).`);
    }

    if (operationResults.hasOwnProperty('sum')) {
      return operationResults['sum'] as number;
    } else {
      return sumValue(operationResults['values']);
    }
  }

  return typeof fallbackMetadata === 'undefined' ? undefined : getSum(fallbackMetadata, av);
}

/**
 * Get avg for a numeric metadata.
 *
 * Rules:
 * 1. If the result of avg operation exists for the metadata name and version, return it.
 * 2. If the result of values operations for the metadata name and version exists as a fallback, compute avg from it and return it.
 * 3. Fallback to the fallback metadata if not undefined, otherwise returns undefined.
 */
export function getAverage(mDesc: MetadataDescriptor, av: AggregatedView,
                           fallbackMetadata?: MetadataDescriptor,
                           precision?: number): number | undefined {
  if (typeof av.metadata[mDesc.name] !== 'undefined' && typeof av.metadata[mDesc.name][mDesc.version] !== 'undefined') {
    const operationResults = av.metadata[mDesc.name][mDesc.version].values[0] as { [operation: string]: any };

    if (!operationResults.hasOwnProperty('avg') && !operationResults.hasOwnProperty('values')) {
      throw new Error(`failed to evaluate avg for ${mDesc.name}/${mDesc.version}. No avg/values found (most likely).`);
    }

    if (operationResults.hasOwnProperty('avg')) {
      return numberWithFraction(operationResults['avg'] as number, precision);
    } else {
      return averageValue(operationResults['values'] as number[], precision);
    }
  }

  return typeof fallbackMetadata === 'undefined' ? undefined : getAverage(fallbackMetadata, av);
}
