import {Button, SpaceBetween} from "@awsui/components-react";
import React, {useEffect, useState} from "react";
import {MetricAwareFilterGrid} from "./filters/metricsAwareFilterGrid";
import {applyFilters, clearFilters, loadData} from "../../../data/dataManager";
import {
    subscribeApplyButtonDisabledFlagChanges,
    subscribeLoadInProgressChanges
} from "../../../data/globalState";
import {subscribeRegisteredFiltersChanges} from "../../../data/filterStateManager";
import {BasicMultiSelectFilter} from "./filters/basicMultiSelectFilter";
import {FilterGroup, RegisteredFilters} from "../../../interfaces/filters/filter";
import {GraphModal} from "./modals/graphModal";
import {SummaryTable} from "./summaryTable";
import {AnalyticsTabs} from "./AnalyticsTabs";
import {PieChartModal} from "./modals/pieChartModal";
import goblinPeek from "../../../assets/goblin-peeking.png";


export function Visualizations() {

    const [applyButtonDisabledFlag, setApplyButtonDisabledFlag] = useState<boolean>();
    const [loadInProgress, setLoadInProgress] = useState<boolean>();
    const [registeredFilters, setRegisteredFilters] = useState<RegisteredFilters>({
        metricAwareFilterGroups: {},
        dropDownFilterGroups: {}
    });

    useEffect(() => {
        subscribeRegisteredFiltersChanges(setRegisteredFilters);
        console.log("rendered Filters grid")
    }, [])

    useEffect(() => {
        subscribeApplyButtonDisabledFlagChanges(setApplyButtonDisabledFlag);
    }, [setApplyButtonDisabledFlag]);

    useEffect(() => {
        subscribeLoadInProgressChanges(setLoadInProgress);
    }, [setLoadInProgress]);

    useEffect(() => {
        loadData().then(() => {
            console.log("Loaded initial data");
        })
    }, [])

    return (
        <SpaceBetween size={'xxl'} direction={'vertical'}>
            <SummaryTable/>
            <Button variant={"primary"} onClick={applyFilters} disabled={applyButtonDisabledFlag} loading={loadInProgress}>Apply filter</Button>
            <Button variant={"normal"} onClick={clearFilters} loading={loadInProgress}>Clear filters</Button>
            <SpaceBetween size={'xs'} direction={'horizontal'}>
                {Object.keys(registeredFilters.dropDownFilterGroups)
                    .map(groupName => {
                        let group: FilterGroup = registeredFilters.dropDownFilterGroups[groupName];
                        return (
                            <BasicMultiSelectFilter key={groupName} filterGroup={group} />
                        )
                    })}
                <img height={200} width={500} src={goblinPeek}/>
            </SpaceBetween>
            <MetricAwareFilterGrid/>
            <GraphModal/>
            <PieChartModal/>
            <AnalyticsTabs/>
        </SpaceBetween>

    );
}
