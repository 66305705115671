
// Global states

import {GraphModalProps} from "../components/analytics/visualizations/modals/graphModal";
import {PieChartModalProps} from "../components/analytics/visualizations/modals/pieChartModal";
import {NotificationItem} from "../interfaces";

// list containing all the notifications to be displayed at the top
let notificationItems: NotificationItem[] = []
// main load in progress flag
let loadInProgress: boolean = false;
// this loading flag is specific to page metrics
let analyticsTabsLoadInProgress: boolean = false;
// main data load button disable flag
let loadButtonDisabledFlag: boolean = false;
// comparison data load button disable flag
let comparisonLoadButtonDisabledFlag: boolean = true;
// stop load button disable flag
let stopLoadButtonDisabledFlag: boolean = false;
// apply filter button disable flag
let applyFilterButtonDisabledFlag: boolean = true;
// load page metrics disable flag
let loadPageMetricsButtonDisabledFlag: boolean = true;
// props that need to passed for GraphModal.
let graphModalProps: GraphModalProps = {
    additionalInfo: undefined,
    aggregatedViews: [],
    lineSeriesData: [],
    title: "",
    yFormatter: undefined,
    visible: false,
    metricsAwareFilter: undefined
};
// props that need to passed for PieChartModal.
let pieChartModalProps: PieChartModalProps = {
    frictionMetrics: {},
    attr: {
        title: "",
        tag: "",
        customers: ""
    },
    visible: false,
    stateMetricFilter: undefined
};

// listeners subscribed for updates to notificationItems
const notificationItemsListeners = new Set<(notificationItems: NotificationItem[]) => void>();
// listeners subscribed for updates to loadInProgress
const loadInProgressListeners = new Set<(loadInProgress: boolean) => void>();
// listeners subscribed for updates to analyticsTabsLoadInProgress
const analyticsTabsLoadInProgressListeners = new Set<(analyticsTabsLoadInProgress: boolean) => void>();
// listeners subscribed for updates to loadButtonDisabledFlag
const loadButtonDisabledFlagListeners = new Set<(loadButtonDisabledFlag: boolean) => void>();
// listeners subscribed for updates to comparisonLoadButtonDisabledFlag
const comparisonLoadButtonDisabledFlagListeners = new Set<(comparisonLoadButtonDisabledFlag: boolean) => void>();
// listeners subscribed for updates to stopLoadButtonDisabledFlag
const stopLoadButtonDisabledFlagListeners = new Set<(stopLoadButtonDisabledFlag: boolean) => void>();
// listeners subscribed for updates to applyFilterButtonDisabledFlag
const applyFilterButtonDisabledFlagListeners = new Set<(applyFilterButtonDisabledFlag: boolean) => void>();
// listeners subscribed for updates to loadPageMetricsButtonDisabledFlag
const loadPageMetricsButtonDisabledFlagListeners = new Set<(loadPageMetricsButtonDisabledFlag: boolean) => void>();
// listeners subscribed for updates to graphModalProps
const graphModalPropsListeners = new Set<(graphModalProps: GraphModalProps) => void>();
// listeners subscribed for updates to pieChartModalProps
const pieChartModalPropsListeners = new Set<(pieChartModalProps: PieChartModalProps) => void>();



/**
 * Set new notificationItems stack
 */
export function setNotificationItems(newNotificationItems: NotificationItem[]) {
    console.log('setNotificationItems: current notificationItems: ', notificationItems);

    if (notificationItems === newNotificationItems) {
        console.log('setNotificationItems: No change identified for notificationItems - skipping executing listeners')
        return;
    }

    notificationItems = newNotificationItems;

    console.log('setNotificationItems: updated notificationItems: ', notificationItems)

    // notify everyone
    notificationItemsListeners.forEach(listener => listener(notificationItems));
}

/**
 * Adds a notificationItem to the existing stack
 * @param newNotification
 */
export function addNotificationItem(newNotification: NotificationItem) {
    notificationItems.push(newNotification);
    setNotificationItems([...notificationItems])
}

/**
 * Removes a notificationItem to the existing stack
 * @param id
 */
export function removeNotificationItem(id: string) {
    notificationItems = notificationItems.filter(notificationItem => notificationItem.id !== id)
    setNotificationItems([...notificationItems])
}

/**
 * Removes the items from existing notificationItems stack
 */
export function removeAllNotifications() {
    setNotificationItems([])
}

/**
 * Set loadInProgress
 */
export function setLoadInProgress(newLoadInProgress: boolean) {
    console.log('setLoadInProgress: current loadInProgress: ', loadInProgress);

    if (loadInProgress === newLoadInProgress) {
        console.log('setLoadInProgress: No change identified for loadInProgress - skipping executing listeners')
        return;
    }

    loadInProgress = newLoadInProgress;

    console.log('setLoadInProgress: updated loadInProgress: ', loadInProgress)

    // notify everyone
    loadInProgressListeners.forEach(listener => listener(loadInProgress));
}

/**
 * Set analyticsTabsLoadInProgress
 */
export function setAnalyticsTabsLoadInProgress(newsAnalyticsTabsLoadInProgress: boolean) {
    console.log('setAnalyticsTabsLoadInProgress: current analyticsTabsLoadInProgress: ', analyticsTabsLoadInProgress);

    if (analyticsTabsLoadInProgress === newsAnalyticsTabsLoadInProgress) {
        console.log('setAnalyticsTabsLoadInProgress: No change identified for analyticsTabsLoadInProgress - skipping executing listeners')
        return;
    }

    analyticsTabsLoadInProgress = newsAnalyticsTabsLoadInProgress;

    console.log('setAnalyticsTabsLoadInProgress: updated analyticsTabsLoadInProgress: ', analyticsTabsLoadInProgress)

    // notify everyone
    analyticsTabsLoadInProgressListeners.forEach(listener => listener(analyticsTabsLoadInProgress));
}

/**
 * Set loadButtonDisabledFlag
 */
export function setLoadButtonDisabledFlag(newLoadButtonDisabledFlag: boolean) {
    console.log('setLoadButtonDisabledFlag: current loadButtonDisabledFlag: ', loadButtonDisabledFlag);

    if (loadButtonDisabledFlag === newLoadButtonDisabledFlag) {
        console.log('setLoadButtonDisabledFlag: No change identified for loadButtonDisabledFlag - skipping executing listeners')
        return;
    }

    loadButtonDisabledFlag = newLoadButtonDisabledFlag;

    console.log('setLoadButtonDisabledFlag: updated loadButtonDisabledFlag: ', loadButtonDisabledFlag)

    // notify everyone
    loadButtonDisabledFlagListeners.forEach(listener => listener(loadButtonDisabledFlag));
}

/**
 * Set comparisonLoadButtonDisabledFlag
 */
export function setComparisonLoadButtonDisabledFlag(newLoadButtonDisabledFlag: boolean) {
    console.log('setComparisonLoadButtonDisabledFlag: current comparisonLoadButtonDisabledFlag: ', comparisonLoadButtonDisabledFlag);

    if (comparisonLoadButtonDisabledFlag === newLoadButtonDisabledFlag) {
        console.log('setComparisonLoadButtonDisabledFlag: No change identified for comparisonLoadButtonDisabledFlag - skipping executing listeners')
        return;
    }

    comparisonLoadButtonDisabledFlag = newLoadButtonDisabledFlag;

    console.log('setComparisonLoadButtonDisabledFlag: updated comparisonLoadButtonDisabledFlag: ', comparisonLoadButtonDisabledFlag)

    // notify everyone
    comparisonLoadButtonDisabledFlagListeners.forEach(listener => listener(comparisonLoadButtonDisabledFlag));
}

/**
 * Set stopLoadButtonDisabledFlag
 */
export function setStopLoadButtonDisabledFlag(newStopLoadButtonDisabledFlag: boolean) {
    console.log('setStopLoadButtonDisabledFlag: current stopLoadButtonDisabledFlag: ', stopLoadButtonDisabledFlag);

    if (stopLoadButtonDisabledFlag === newStopLoadButtonDisabledFlag) {
        console.log('setStopLoadButtonDisabledFlag: No change identified for stopLoadButtonDisabledFlag - skipping executing listeners')
        return;
    }

    stopLoadButtonDisabledFlag = newStopLoadButtonDisabledFlag;

    console.log('setStopLoadButtonDisabledFlag: updated stopLoadButtonDisabledFlag: ', stopLoadButtonDisabledFlag)

    // notify everyone
    stopLoadButtonDisabledFlagListeners.forEach(listener => listener(stopLoadButtonDisabledFlag));
}

/**
 * Set applyFilterButtonDisabledFlag
 */
export function setApplyFilterButtonDisabledFlag(newApplyFilterButtonDisabledFlag: boolean) {
    console.log('setApplyFilterButtonDisabledFlag: current applyFilterButtonDisabledFlag: ', applyFilterButtonDisabledFlag);

    if (applyFilterButtonDisabledFlag === newApplyFilterButtonDisabledFlag) {
        console.log('setApplyFilterButtonDisabledFlag: No change identified for applyFilterButtonDisabledFlag - skipping executing listeners')
        return;
    }

    applyFilterButtonDisabledFlag = newApplyFilterButtonDisabledFlag;

    console.log('setApplyFilterButtonDisabledFlag: updated applyFilterButtonDisabledFlag: ', applyFilterButtonDisabledFlag)

    // notify everyone
    applyFilterButtonDisabledFlagListeners.forEach(listener => listener(applyFilterButtonDisabledFlag));
}

/**
 * Set loadPageMetricsButtonDisabledFlag
 */
export function setLoadPageMetricsButtonDisabledFlag(newLoadPageMetricsButtonDisabledFlag: boolean) {
    console.log('setLoadPageMetricsButtonDisabledFlag: current loadPageMetricsButtonDisabledFlag: ', loadPageMetricsButtonDisabledFlag);

    if (loadPageMetricsButtonDisabledFlag === newLoadPageMetricsButtonDisabledFlag) {
        console.log('setLoadPageMetricsButtonDisabledFlag: No change identified for loadPageMetricsButtonDisabledFlag - skipping executing listeners')
        return;
    }

    loadPageMetricsButtonDisabledFlag = newLoadPageMetricsButtonDisabledFlag;

    console.log('setLoadPageMetricsButtonDisabledFlag: updated loadPageMetricsButtonDisabledFlag: ', loadPageMetricsButtonDisabledFlag)

    // notify everyone
    loadPageMetricsButtonDisabledFlagListeners.forEach(listener => listener(loadPageMetricsButtonDisabledFlag));
}

/**
 * Set graphModalProps
 */
export function setGlobalGraphModalProps(newGraphModalProps: GraphModalProps) {

    console.log('setGraphModalProps: current graphModalProps: ', graphModalProps);

    if (graphModalProps === newGraphModalProps) {
        console.log('setGraphModalProps: No change identified for graphModalProps - skipping executing listeners');
        return;
    }

    graphModalProps = newGraphModalProps;

    console.log('setGraphModalProps: updated graphModalProps: ', graphModalProps);

    // notify everyone
    graphModalPropsListeners.forEach(listener => listener(graphModalProps));
}

/**
 * Set pieChartModalProps
 */
export function setPieChartModalProps(newPieChartModalProps: PieChartModalProps) {

    console.log('setPieChartModalProps: current pieChartModalProps: ', pieChartModalProps);

    if (pieChartModalProps === newPieChartModalProps) {
        console.log('setPieChartModalProps: No change identified for pieChartModalProps - skipping executing listeners');
        return;
    }

    pieChartModalProps = newPieChartModalProps;

    console.log('setPieChartModalProps: updated pieChartModalProps: ', pieChartModalProps);

    // notify everyone
    pieChartModalPropsListeners.forEach(listener => listener(pieChartModalProps));
}

/**
 * Subscribe to get notified whenever 'notificationItems' state changes
 * @param listener
 */
export function subscribeNotificationChanges(listener: (notificationItems: NotificationItem[]) => void) {
    notificationItemsListeners.add(listener);
    listener(notificationItems);
}

/**
 * Subscribe to get notified whenever 'loadInProgress' state changes
 * @param listener
 */
export function subscribeLoadInProgressChanges(listener: (loadInProgress: boolean) => void) {
    loadInProgressListeners.add(listener);
    listener(loadInProgress);
}

/**
 * Subscribe to get notified whenever 'analyticsTabsLoadInProgress' state changes
 * @param listener
 */
export function subscribeAnalyticsTabsLoadInProgress(listener: (analyticsTabsLoadInProgress: boolean) => void) {
    analyticsTabsLoadInProgressListeners.add(listener);
    listener(analyticsTabsLoadInProgress);
}

/**
 * Subscribe to get notified whenever 'loadButtonDisabledFlag' state changes
 * @param listener
 */
export function subscribeLoadButtonDisabledFlagChanges(listener: (loadButtonDisabledFlag: boolean) => void) {
    loadButtonDisabledFlagListeners.add(listener);
    listener(loadButtonDisabledFlag);
}

/**
 * Subscribe to get notified whenever 'loadButtonDisabledFlag' state changes
 * @param listener
 */
export function subscribeComparisonLoadButtonDisabledFlagChanges(listener: (comparisonLoadButtonDisabledFlag: boolean) => void) {
    comparisonLoadButtonDisabledFlagListeners.add(listener);
    listener(comparisonLoadButtonDisabledFlag);
}

/**
 * Subscribe to get notified whenever 'stopLoadButtonDisabledFlag' state changes
 * @param listener
 */
export function subscribeStopLoadButtonDisabledFlagChanges(listener: (stopLoadButtonDisabledFlag: boolean) => void) {
    stopLoadButtonDisabledFlagListeners.add(listener);
    listener(stopLoadButtonDisabledFlag);
}

/**
 * Subscribe to get notified whenever 'applyFilterButtonDisabledFlag' state changes
 * @param listener
 */
export function subscribeApplyButtonDisabledFlagChanges(listener: (applyFilterButtonDisabledFlag: boolean) => void) {
    applyFilterButtonDisabledFlagListeners.add(listener);
    listener(applyFilterButtonDisabledFlag);
}

/**
 * Subscribe to get notified whenever 'loadPageMetricsButtonDisabledFlag' state changes
 * @param listener
 */
export function subscribeLoadPageMetricsButtonDisabledFlagChanges(listener: (loadPageMetricsButtonDisabledFlag: boolean) => void) {
    loadPageMetricsButtonDisabledFlagListeners.add(listener);
    listener(loadPageMetricsButtonDisabledFlag);
}

/**
 * Subscribe to get notified whenever 'graphModalProps' state changes
 * @param listener
 */
export function subscribeGraphModalPropsChanges(listener: (graphModalProps: GraphModalProps) => void) {
    graphModalPropsListeners.add(listener);
    listener(graphModalProps);
}

/**
 * Subscribe to get notified whenever 'pieChartModalProps' state changes
 * @param listener
 */
export function subscribePieChartModalPropsChanges(listener: (pieChartModalProps: PieChartModalProps) => void) {
    pieChartModalPropsListeners.add(listener);
    listener(pieChartModalProps);
}