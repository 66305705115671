import {AggregatedView} from "../../index";
import {FilterEvaluator, FilterEvaluatorProps} from "../filter";
import {getStateKey} from "../../../common/utils";


export interface StateFilterProps extends FilterEvaluatorProps {
    stateKey: string;
    showTags: boolean
}
/**
 * This filter evaluates to true if a state is present in the state list in aggregatedViews.
 * The state is filtered using {@link stateKey} and {@link showTags}
 */
export class StateFilter extends FilterEvaluator {

    stateKey: string;
    showTags: boolean;

    constructor(props: StateFilterProps) {
        super(props);
        this.stateKey = props.stateKey;
        this.showTags = props.showTags;
    }

    evaluate(av: AggregatedView, currentState: { label: string, value: string | string[] }): boolean {
        let states = av.states.slice();
        for (let state of states) {
            if (getStateKey(state, this.showTags) === this.stateKey) return true;
        }
        return false;
    }

}
