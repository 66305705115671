import {getServicePrefix} from "../../../../../config/configs";
import {KaraokeTransitionsGraph} from "./karaoke/graph";
import {PWATransitionsGraph} from "./pwa/graph";
import React from "react"

export function TransitionsGraph() {

    switch (getServicePrefix()) {
        case "karaoke": return <KaraokeTransitionsGraph/>
        case "pwa": return <PWATransitionsGraph/>
        default: return <></>
    }
}