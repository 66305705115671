import React, {useEffect, useState} from "react";
import {Header, SpaceBetween} from "@awsui/components-react";
import {MetricsAwareFilter} from "./metricsAwareFilter";
import {FilterGroup, MetricAwareFilter, RegisteredFilters} from "../../../../interfaces/filters/filter";
import {subscribeRegisteredFiltersChanges} from "../../../../data/filterStateManager";


export function MetricAwareFilterGrid() {

    const [registeredFilters, setRegisteredFilters] = useState<RegisteredFilters>({
        metricAwareFilterGroups: {},
        dropDownFilterGroups: {}
    });

    useEffect(() => {
        subscribeRegisteredFiltersChanges(setRegisteredFilters);
        console.log("rendered Filters grid")
    }, [setRegisteredFilters])

    return (
        <SpaceBetween size={'xxs'} direction={'vertical'} >
            {Object.keys(registeredFilters.metricAwareFilterGroups)
                .filter(groupName => groupName !== "StateFilterGroup")
                .map(groupName => {
                    let group: FilterGroup = registeredFilters.metricAwareFilterGroups[groupName];
                    return (
                        <SpaceBetween key={group.groupName} size={'xxs'}>
                            <Header variant={'h2'}>{group.groupName}</Header>
                            <SpaceBetween size={'xxs'} direction={'horizontal'}>
                                {Object.keys(group.filters).map((filterName) => {
                                    let metricAwareFilter: MetricAwareFilter = group.filters[filterName] as MetricAwareFilter
                                    return (
                                        <MetricsAwareFilter key={metricAwareFilter.filterName}
                                                            filterGroupName={group.groupName}
                                                            metricsAwareFilter={metricAwareFilter}
                                        />
                                    )
                                })}
                            </SpaceBetween>
                        </SpaceBetween>
                    )
                })}
        </SpaceBetween>
    )
}
