import React, {useEffect, useRef, useState} from 'react';
import G6 from '@antv/g6';
import {Button, Header, SpaceBetween} from "@awsui/components-react";
import {
    subscribeGlobalTransitionAnalysisModeChanges, subscribeTransitionStateMetricsChanges,
} from "../../../../../../data/metricStateManager";
import {setPieChartModalProps} from "../../../../../../data/globalState";
import {getPWAGraphDataStruct} from "../../../../../../utils/graphUtils";

let graph = null;

export function PWATransitionsGraph()  {
    const ref = useRef(null);
    const toolbarRef = useRef(null);

    const [transitionAnalysisMode, setTransitionAnalysisMode] = useState("customers");
    const [stateMetrics, setStateMetrics] = useState([]);

    const graphDataStruct = getPWAGraphDataStruct(stateMetrics, transitionAnalysisMode);

    useEffect(() => {
        subscribeTransitionStateMetricsChanges(setStateMetrics)
    }, [setStateMetrics])

    useEffect(() => {
        subscribeGlobalTransitionAnalysisModeChanges(setTransitionAnalysisMode)
    }, [setTransitionAnalysisMode])


    useEffect(() => {

        G6.registerEdge(
            'custom-label-edge',
            {
                afterDraw(cfg, group) {
                    const shape = group.get('children')[0];
                    const color = '#000000';
                    shape.setAttr('stroke', color);
                    shape.setAttr('lineWidth', 4);
                    const midPoint = shape.getPoint(Math.random() /10 + 0.50);
                    group.addShape('text', {
                        attrs: {
                            text: `${cfg['displayName']}`,
                            x: midPoint.x - 20,
                            y: midPoint.y - 20,
                            fill: color,
                            fontSize: 35,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            fontWeight: 'bold',
                        },
                    });
                },
                update: undefined,
            },
            'polyline',
        );

        G6.registerNode(
            'sql',
            {
                drawShape(cfg, group) {
                    const rect = group.addShape('rect', {
                        attrs: {
                            x: -350,
                            y: -40,
                            width: 700,
                            height: 200,
                            radius: 30,
                            stroke: '#000000',
                            fill: (cfg['frictionLabel'] === undefined)? '#236fa6': '#8a2b2b',
                            lineWidth: 7,
                        },
                        name: 'rect-shape',
                    });
                    group.addShape('text', {
                        attrs: {
                            text: `${cfg['displayName']}`,
                            x: 0,
                            y: 0,
                            fill: '#FFFFFF',
                            fontSize: 30,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            fontWeight: 'bold',
                        },
                        name: 'text-shape',
                    });
                    group.addShape('text', {
                        attrs: {
                            text: `Customers = ${cfg['uniqueCustomers']}`,
                            x: 0,
                            y: 45,
                            fill: '#FFFFFF',
                            fontSize: 28,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            fontWeight: 'bold'
                        },
                    });
                    group.addShape('text', {
                        attrs: {
                            text: `Views = ${cfg['pageViews']}`,
                            x: 0,
                            y: 85,
                            fill: '#FFFFFF',
                            fontSize: 28,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            fontWeight: 'bold'
                        },
                    });

                    if (cfg['frictionLabel'] !== undefined) {
                        group.addShape('text', {
                            attrs: {
                                text: cfg['frictionLabel'],
                                x: 0,
                                y: 125,
                                fill: '#FFFFFF',
                                fontSize: 28,
                                textAlign: 'center',
                                textBaseline: 'middle',
                                fontWeight: 'bolder'
                            },
                        })
                    }
                    return rect;
                },
            },
            'single-node',
        );

        if (graph !== null) graph.destroy();
        const toolbar = new G6.ToolBar({
            container: toolbarRef.current
        });
        const tooltip = new G6.Tooltip({
            // offsetX and offsetY include the padding of the parent container
            offsetX: 60,
            offsetY: 260,
            // the types of items that allow the tooltip show up
            itemTypes: ['node'],
            // custom the tooltip's content
            getContent: (e) => {
                const outDiv = document.createElement('div');
                outDiv.style.width = 'fit-content';
                outDiv.innerHTML = `
                  <h4>${e.item.getModel().displayName} ${e.item.getModel().displayTag || ""}</h4>
                  <ul>
                    <li>Unique Customers: ${e.item.getModel().uniqueCustomers}</li>
                  </ul>
                  <ul>
                    <li>Incoming Requests: ${e.item.getModel().incoming}</li>
                  </ul>
                  <ul>
                    <li>Outgoing Request: ${e.item.getModel().outgoing}</li>
                  </ul>
                  <ul>
                    <li>${e.item.getModel().frictionLabel || "No Friction"}</li>
                  </ul>`;
                return outDiv;
            },
        });
        graph = new G6.Graph({
            container: ref.current,
            fitViewPadding: 20,
            layout: {
                type: 'dagre',
                nodesep: 300,
                ranksep: 400,
                rankdir: 'LR',
                controlPoints: true,
            },
            nodeStateStyles: {
                selected: {
                    stroke: '#d9d9d9',
                    fill: '#5394ef',
                },
                inactive: {
                    opacity: '0.5',
                    fillOpacity: '0.5'
                }
            }, edgeStateStyles: {
                inactive: {
                    opacity: '0.5',
                    fillOpacity: '0.5'
                }
            },
            defaultNode: {
                type: 'sql',
            },
            defaultEdge: {
                type: 'custom-label-edge',
                style: {
                    radius: 20,
                    offset: 45,
                    endArrow: {path: G6.Arrow.vee(), fill: '#D'},
                    lineWidth: 2,
                    stroke: '#D'
                },
            },
            plugins: [toolbar, tooltip],
            modes: {
                default: [
                    'drag-canvas',
                    'drag-node',
                    'zoom-canvas'
                ],
            },
        });

        // listen to the node click event
        graph.on('node:click', (event) => {
            let metricData = event["item"]["_cfg"]["model"]["frictionMetrics"]
            if (metricData === undefined || Object.keys(metricData).length === 0) return
            let title = event["item"]["_cfg"]["model"]["displayName"]
            let tag = event["item"]["_cfg"]["model"]["displayTag"]
            let customers = event["item"]["_cfg"]["model"]["uniqueCustomers"]
            let stateMetricFilter = event["item"]["_cfg"]["model"]["stateMetricFilter"]
            setPieChartModalProps({
                visible: true,
                attr: {
                    title,
                    tag,
                    customers
                },
                frictionMetrics: metricData,
                stateMetricFilter
            })
        });
        graph.data(graphDataStruct);
        graph.render();
        graph.on('afterlayout', () => graph.fitView());
    }, [graphDataStruct])


    return (
        <>
            <Header actions={<SpaceBetween size={'xs'} direction={'horizontal'}>
                <Button variant={'link'}>
                    <div ref={toolbarRef}/>
                </Button>
            </SpaceBetween>}/>
            <div ref={ref} style={{width: 1600, height: 800}}/>
            <Header actions={<SpaceBetween size={'xs'} direction={'horizontal'}>
                <Button variant={'normal'} onClick={() => graph.downloadFullImage('transitions', 'image/jpeg', {
                    backgroundColor: '#ffffff'
                })}>Download image</Button>
            </SpaceBetween>}/>
        </>

    );
}
