import {AnalyticsLayout} from "./components/analytics";
import React from "react";
import Amplify, {Auth} from "aws-amplify";
import {getAllUserGroupsFromIdToken, isUserAuthorized} from "./utils/permissions";
import {DashboardUser} from "./interfaces";
import Flashbar from "@awsui/components-react/flashbar";
import {HashRouter, Navigate, Route, Routes} from "react-router-dom";
import {getServiceNameStageString} from "./common/utils";
import {Box, Button, Link} from "@awsui/components-react";
const clientCognitoConfigs = require("../client-cognito-configs.json")

function refreshPage() {
    window.location.reload()
}

function configureAmplifyApp(serviceNameAndStage: string) {
    const callbackUrl = window.location.origin;
    const config = {
        oauth: {
            domain: `${clientCognitoConfigs[serviceNameAndStage].UserPoolDomainPrefix}.auth.us-west-2.amazoncognito.com`, // dashboard stacks in us-west-2 only.
            scope: ['openid'],
            redirectSignIn: callbackUrl,
            redirectSignOut: callbackUrl,
            responseType: 'code'
        },
        // Cognito
        aws_cognito_identity_pool_id: clientCognitoConfigs[serviceNameAndStage].IdentityPoolId,
        aws_cognito_region: "us-west-2",
        aws_user_pools_id: clientCognitoConfigs[serviceNameAndStage].UserPoolId,
        aws_user_pools_web_client_id: clientCognitoConfigs[serviceNameAndStage].UserPoolClientId,
        aws_project_region: 'us-west-2',
    }

    console.log('Amplify config', config)
    Amplify.configure(config)
}

function getAnalyticsComponent(user: DashboardUser | undefined) {
    if (typeof user === 'undefined') {
        return (
            <div>
                <Flashbar items={[{
                    type: 'info',
                    content: 'Signing you in...',
                    loading: true,
                    dismissible: false
                }]}/>
            </div>
        )
    } else if (!isUserAuthorized(user.userId)) {
        return (
            <div>
                <Flashbar items={[{
                    type: 'error',
                    content: 'You don\'t have access to this dashboard. Please contact sachigoe@ to request access.',
                    dismissible: false
                }]}/>
            </div>
        )
    }
    return <AnalyticsLayout/>
}

function getAvailableKeysComponent() {
    return (
        <>
            <h2>Please select any of the following keys and hit <b>Submit</b></h2>
            <Box textAlign={"center"}>
                {Object.keys(clientCognitoConfigs).map(serviceNameAndStage => {
                    return (
                        <div style={{width: "500px"}}>
                            <Link variant={"info"} target={"_top"} href={`#/${serviceNameAndStage}`}>
                                <Box textAlign={"center"}>
                                    <div style={{borderStyle: "solid", borderColor: "lightgrey"}}>
                                        <h3>{serviceNameAndStage.toLocaleUpperCase()}</h3>
                                    </div>
                                </Box>
                            </Link>
                        </div>
                    )
                })}
            </Box>
            <Box margin={"xl"}>
                <Button variant={"primary"} onClick={refreshPage}>Submit</Button>
            </Box>
        </>
    )
}

function getEvaluatedComponent(user: DashboardUser | undefined) {
    let serviceNameAndStage = getServiceNameStageString(Object.keys(clientCognitoConfigs));
    console.log(serviceNameAndStage)
    if (typeof serviceNameAndStage !== 'undefined' && serviceNameAndStage !== null) return getAnalyticsComponent(user);
    return (
        <>
            {getAvailableKeysComponent()}
        </>
    )
}

// Class App is the "output" generated on every build,
// it is what you will see on the webpage.
// based on https://tiny.amazon.com/xjr2fmbb
export default function App() {

  const [user, updateUser] = React.useState<DashboardUser>();

  const parseAndUpdateUser = (cognitoUserData: any) => {
    const idToken = cognitoUserData.getSignInUserSession().getIdToken()
    const userId = idToken.payload['identities'][0].userId
    const groups = getAllUserGroupsFromIdToken(idToken)

    console.log('user id', userId)
    console.log('user groups', groups)
    updateUser({userId: userId, groups: groups})
  }


  React.useEffect(() => {
      let serviceNameAndStage = getServiceNameStageString(Object.keys(clientCognitoConfigs));

      if (serviceNameAndStage === undefined) {
          serviceNameAndStage = localStorage.getItem("previousServiceNameAndStage")!
      } else if (serviceNameAndStage !== null) {
          localStorage.setItem("previousServiceNameAndStage", serviceNameAndStage)
      }
      if (serviceNameAndStage !== null) {
          configureAmplifyApp(serviceNameAndStage)
      }
  }, [])


  React.useEffect(() => {
    console.log('checking user info...')

    if (localStorage.getItem("previousServiceNameAndStage") !== null) {
        Auth.currentAuthenticatedUser()
            .then(cognitoUserData => {
                parseAndUpdateUser(cognitoUserData)
            })
            .catch(() => {
                console.log('No signed in user. sign in...')
                Auth.federatedSignIn({customProvider: 'AmazonFederate'})
            })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={
          localStorage.getItem("previousServiceNameAndStage")?
              <Navigate to={`/${localStorage.getItem("previousServiceNameAndStage")}`}/>
                    :
              getAvailableKeysComponent()
        }>
        </Route>
        <Route path='/:serviceNameAndStage' element={getEvaluatedComponent(user)} />
      </Routes>
    </HashRouter>
);

}


