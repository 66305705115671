import {AggregatedView} from "../../index";
import {EvaluationProps, Metric, MetricEvaluator, MetricEvaluatorProps} from "../metric";

/**
 * This evaluates the total number of unique customers.
 * customerIdentifier is used to differentiate customers.
 */
export class UniqueCustomerMetric extends MetricEvaluator {

    constructor(props: MetricEvaluatorProps) {
        super(props);
    }

    evaluate(aggregatedViews: AggregatedView[], props?: EvaluationProps): Metric {

        const periodData: { [window: number]: Set<string> } = {};

        let isTrendRequired = undefined;

        if (typeof props !== 'undefined') {
            isTrendRequired = (props.isTrendRequired !== undefined ? props.isTrendRequired: this.isTrendRequired);
        }

        let totalCustomers: Set<string> = new Set<string>();
        aggregatedViews.forEach(av => {
            if (typeof props !== 'undefined') {
                const window = Math.floor(av.timestamp / props.period) * props.period;
                if (!periodData.hasOwnProperty(window)) periodData[window] = new Set<string>();
                periodData[window].add(av.customerIdentifier);
            }
            totalCustomers.add(av.customerIdentifier);
        })

        if (!isTrendRequired && isTrendRequired === false) {
            return {
                metricLabel: this.metricLabel,
                metricValue: totalCustomers.size,
                isUpwardTrendGood: this.isUpwardTrendGood
            };
        }

        const overallSeriesData: { x: Date, y: number }[] = [];

        const sortedWindows = Object.keys(periodData).map(v => Number(v)).sort();

        sortedWindows.forEach(window => {
            overallSeriesData.push({
                x: new Date(window), y: periodData[window].size
            });
        });

        return {
            metricLabel: this.metricLabel,
            metricValue: totalCustomers.size,
            trend: overallSeriesData,
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
