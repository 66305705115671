// this list is derived from https://tiny.amazon.com/1awgzj4og

export const DeviceFamilies: { [family: string]: string[] } = {
  "ACK": [
    "A18F6NPA9RYMW1",
    "A1D1RVLYN2VLPF",
    "A1UDLXF35FYCBZ"
  ],
  "ALEXA_AUTO": [
    "A11QEBJQSEKZQX",
    "A148MBL10NGRRK",
    "A14PWMM6Y8TT2H",
    "A163YI7FWQ38MH",
    "A17KNHDVUO2UVP",
    "A17LGWINFBUTZZ",
    "A18O45GXWMBZQH",
    "A195TXHV1M5D4A",
    "A19M041UD608X6",
    "A1C6Z2ZG39IG3X",
    "A1DPDJZ7PN9B16",
    "A1ER1POTPQXSVA",
    "A1EXR9A3NTNILI",
    "A1FP74QN2Q7G2G",
    "A1GKU85KF8WYMX",
    "A1MYA62AKO9I9Q",
    "A1N0QUTUAVQC6L",
    "A1NUZ05E4P8MKH",
    "A1RIWQMS09P02V",
    "A1SCI5MODUBAT1",
    "A1VY1PT8Z987F2",
    "A1W2YILXTG9HA7",
    "A1XAYHRBEBBG6N",
    "A1XO2UW68CMUGQ",
    "A1Y6L6T9GFPCTL",
    "A20Z7716PJF11S",
    "A21KBD8OKO02AX",
    "A23O8GXUKALYKZ",
    "A24B7QXDATJDR3",
    "A2713YGATZNNNP",
    "A27E28D30FV7WV",
    "A28V618DSNM82M",
    "A29KM6WBN96XYX",
    "A2E0XJJQRBRTOB",
    "A2FB6AJRYZZ08C",
    "A2GMZW2W3J397Y",
    "A2HDTYTTQXKMJT",
    "A2HETMNUKLJ27K",
    "A2HJBINR0EN3BS",
    "A2I0YEYAQW48N",
    "A2IASBXNGZRPWZ",
    "A2IKWKXSDYDTM0",
    "A2J7K7SBZ5AS4",
    "A2JTNNU49HNSC2",
    "A2KXYCEW2SLLHG",
    "A2L4H46OZY3QHG",
    "A2LCU1T1SGK8YF",
    "A2P6JP7RD047PW",
    "A2Q6PC3OS03J7K",
    "A2QZI8LO76109V",
    "A2SVJ222IYUAZJ",
    "A2T1VHZ1WSVSEY",
    "A2V5E77PSYEVB4",
    "A2VA2HQZI4V438",
    "A2XXJDBT0NNHUH",
    "A2YGUDBX0O7S89",
    "A312EUTL61IXW8",
    "A319VMKX4SJMYC",
    "A347G2JC8I4HC7",
    "A36DOJ9I8N9OAE",
    "A3BCYVUOMSBZQR",
    "A3BPM60VRT39ZQ",
    "A3BRTL7E5ZMKG4",
    "A3BW5ZVFHRCQPO",
    "A3C8UGQF20NAFV",
    "A3DVJ7IYE34OC2",
    "A3F0DEZGVS6QMD",
    "A3H1YN1ZZBK41H",
    "A3JBEIMAMX8A1U",
    "A3JVSJXVNTMELV",
    "A3M91KUSTM6A3P",
    "A3MVB58PFYYMI",
    "A3PJCGQ2IS6QB0",
    "A3QJD92P0IKIG2",
    "A3R1T9CCI7AZ37",
    "A3RRSBKAOY59X7",
    "A3SQ327AQ5AD9O",
    "A3TACBBPKMYKYK",
    "A3UMK5U83JWRIT",
    "A3VDGMCVN778B7",
    "A40OIC7AR1406",
    "A59DT7NTVKWQY",
    "A5D8NAIZGQG1G",
    "AAMFMBBEW2960",
    "AFYS4ZWCE5QDJ",
    "AGN5VBMK7STNS",
    "AHQQSJZP9IH8T",
    "AI2PYZ6PLHX73",
    "AJNUUEXI62WIP",
    "ALT7OQZBZ2X1I",
    "ALT9P69K6LORD",
    "AMLIYBR80C9A",
    "API9PC9RTOQWH",
    "AR1YF49M2O2NU",
    "AREJUVYYRTQFO",
    "ARS7C7Q06KSAK",
    "AT59ORPNYFP2Q",
    "AUHPPWKQGUIWA",
    "AV9IPYG8XV2OB",
    "AVE5HX13UR5NO",
    "AW3XCPH15LHBT",
    "AZK4V2SWEE43D"
  ],
  "AMAZONMOBILEMUSIC_ANDROID": [
    "A1DL2DVDQVK3Q",
    "A31ANRUHT2I2JF",
    "ATH4K2BAIXVHQ"
  ],
  "AMAZONMOBILEMUSIC_IOS": [
    "A2825NDLA7WDZV"
  ],
  "ANDROID_SOC": [
    "A1TLIYI5L8DNN9"
  ],
  "BATON": [
    "A3AE6R6GWGI6W4"
  ],
  "BEAGLE": [
    "A2OG7QZJJ4WE7K",
    "A337R0I9LZO7L3"
  ],
  "BELL": [
    "A2IGSRU5WQUW9Q",
    "AKOAGQTKAS9YB"
  ],
  "BELLINI": [
    "A34XLYGNNJVONN"
  ],
  "BOT": [
    "A2ST8JGGBDIVWW"
  ],
  "DASH_WAND": [
    "A3F1S88NTZZXS9"
  ],
  "DVR": [
    "A1NF7WOA3T3IL2"
  ],
  "ECHO": [
    "A12UVEIKD30VWY",
    "A13W6HQIHKEN3Z",
    "A15X1O61Z4Z9XQ",
    "A16MZVIFVHX6P6",
    "A18O6U1UQFJ0XK",
    "A1JJ0KFC4ZPNJ3",
    "A1RABVCI4QCIKC",
    "A23GSG4SHR37B6",
    "A27VEYGQBW3YR5",
    "A2DS1Q2TPDJ48U",
    "A2H4LV5GIZ1JFT",
    "A2JINMY1A452RF",
    "A2M35JJZWCQOMZ",
    "A2N87T5SAKRZEK",
    "A2OUFCM11BT9RB",
    "A2RU4B77X9R9NZ",
    "A2TEZTE9LMVXYP",
    "A2U21SRK4QGSE1",
    "A2UMIHMVDEPBPG",
    "A303PJF6ISQ7IC",
    "A30YDR2MK8HMRV",
    "A32DOYMUN6DTXA",
    "A38949IHXHRQ5P",
    "A3FX4UWTP28V1P",
    "A3RBAYBE7VM004",
    "A3RMGO6LYLH7YN",
    "A3S5BH2HU6VAYF",
    "A3SSG6GR8UU7SN",
    "A3VRME03NAXFUB",
    "A4ZXE0RM7LQ7A",
    "A7WXQPH584YP",
    "AB72C64C86AW2",
    "AE9FIEPOC6D9B",
    "AKNO1N0KSFN8L",
    "ALWUIN0P635PT",
    "AS8OPU4NNXJI8",
    "ASQZWP4GPYUT7"
  ],
  "EERO": [
    "A1F8M1KC94VTHW",
    "A1NPP2J03FTS0I",
    "A2Y4IIJMFCUR7J",
    "A3PAHYZLPKL73D"
  ],
  "FALCON": [
    "A2Y6633R9AA6PV",
    "A3B5K1G3EITBIF",
    "AIY82Q8P3JP1X"
  ],
  "FIRE_TV": [
    "A11LTYHMUA9IVC",
    "A12GXV8XMS007S",
    "A1AGU0A4YA5RF9",
    "A1D8YU8R1NDU9J",
    "A1F8D55J0FWDTN",
    "A1FLJREJD45584",
    "A1GAVERFF7K6QE",
    "A1J1VLGWV5DNH8",
    "A1LC8PD6F18B9D",
    "A1P7E7V3FCZKU6",
    "A1VGB7MHSIEYFK",
    "A1ZZ32RVTQ796E",
    "A2095X0TE4N6MG",
    "A23OB0X4CSIURJ",
    "A265XOI9586NML",
    "A2AFH666ZTTTE8",
    "A2E0SNTXJVT7WK",
    "A2FDUYD6UQ1BQ",
    "A2FEIG7C1SK0Y7",
    "A2GFL5ZMWNE0PX",
    "A2JKHJ0PX4J3L3",
    "A2JVGI9LPO2C46",
    "A2LWARUGJLBYEW",
    "A2MGXR8OYP6PPI",
    "A2NYIDFQSJW39B",
    "A2WJI2JG7UW2O1",
    "A2WV8TTM37P2CB",
    "A30OJ8LMIAF6GP",
    "A31DTMEEVDDOIV",
    "A33S43L213VSHQ",
    "A344VUVLX0R7YR",
    "A346DYAAR4WSNS",
    "A359HI7OGMDZRV",
    "A36URTS2ZYZ2VH",
    "A38XLO5JMXD34F",
    "A3D5JL73E6MKZ1",
    "A3EVMLQTU6WL1W",
    "A3GX5HW2XVSRYM",
    "A3HF4YRA2L7XGC",
    "A3IKB0DFR7GKZW",
    "A3MTL1JKF2IXY3",
    "A3N03DFW19H57L",
    "A3OR42JVG52D5C",
    "A3PP231UA7WSGV",
    "A3SUJTTQGF9GNF",
    "A8460TGQE1FL7",
    "A8MCGN45KMHDH",
    "A93SQJNJQLDSS",
    "ADOUDFQX2QVX0",
    "ADVBD696BHNV5",
    "AFF5OAL5E3DIU",
    "AGHZIK8D6X7QR",
    "AHCEDGRIFN5RP",
    "AJARKPEHE66NN",
    "AKPDBKOHKR3KF",
    "AKPGW064GI9HE",
    "AN630UQPG2CA4",
    "AO41ONMTRU6WE",
    "AP4RS91ZQ0OOI",
    "AQRMMA6JEEZF",
    "ARJHEDRXLP6DM",
    "AS9PISHXYUKN3",
    "AX3IM6H23Z3E9",
    "AXLL9HLUCP9OP"
  ],
  "GANDALF": [
    "A2HPYE8VLW820A"
  ],
  "GUI": [
    "ALEGCNGL9K0HM"
  ],
  "HALO": [
    "A2R2GLZH1DFYQO"
  ],
  "HEALTH": [
    "A2OZY36J6NKT3Z",
    "ATB3CATFQ3S80"
  ],
  "HENDRIX": [
    "A1ORT4KZ23OY88"
  ],
  "HUAYRA": [
    "A3R56WZBD53PLH"
  ],
  "KNIGHT": [
    "A11QM4H9HGV71H",
    "A15996VY63BQ2D",
    "A1EIANJ7PNB0Q7",
    "A1NL4BVLQ4L3N3",
    "A1XWJRHALS1REP",
    "A1Z88NGR2BK6A2",
    "A4ZP7ZC4PI6TO",
    "AIPK7MM90V7TB",
    "ARCD27QDPHJ2I",
    "AWZZ5CVHX2CD"
  ],
  "MMSDK": [
    "A1GPRTT66OBCF0",
    "A1JZ3N80E7O3AA",
    "A1MVW2BHJ252XQ",
    "A1UXW0G7D9HUT0",
    "A2HZENIFNYTXZD",
    "A2J0R2SD7G9LPA",
    "A2JF2BQ78WMYDW",
    "A2LKONZMYBK2H3",
    "A2PSMGX7BTEREO",
    "A2VPCFB2QWWMSD",
    "A2YNT4UBW9Y48K",
    "A343KZ8O4L173Q",
    "A3CY98NH016S5F",
    "A3D27JB1WYXZFJ",
    "A3D4YURNTARP5K",
    "A3KULB3NQN7Z1F",
    "A3V88YODYKUDL4",
    "A3VLO8LYU7A1AC",
    "A3VWZPS67K9MQG",
    "A52ARKF0HM2T4",
    "A5T6MKK4PAMHN",
    "AEAASXHOC6RKM",
    "AI40YINEO7XD8",
    "AWNAT8T0SIENJ"
  ],
  "MSHOP": [
    "A16IF9CU0JJZCN",
    "A1KBWZNXHS3TB4",
    "A1M64GCM7PNJOD",
    "A1MPSLFC7L5AFK",
    "A1N3V999TEASYF",
    "A1PY8QQU9P0WJV",
    "A1QDQOLAZW8689",
    "A1ZIKNL9GY1I31",
    "A2426QV4IEOY42",
    "A26P7JWNT6HZZG",
    "A2A5CI6UOXPNHT",
    "A2B66DFW4DLI35",
    "A2LWQIXHN1QXDS",
    "A2PQJQ3MUIN51U",
    "A2QST4JKWTT1YD",
    "A2RGCEY0HLPPLJ",
    "A3HM8AQLMWU2XZ",
    "A3NWHXTQ4EBCZS",
    "A3R0W4L0K18WJ1",
    "A3SUZ568VHKS4T",
    "AA66LBT15WGWH",
    "ADV8JTPGKKK3V",
    "AEC01P9FUVAD9"
  ],
  "OPTIMUS": [
    "A2YXL5WJ3AVDO"
  ],
  "REAVER": [
    "A1RTAM01W29CUP",
    "A34YGKZHU0TXX6",
    "A3GKWY7TH5SHPH"
  ],
  "ROOK": [
    "A10A33FOX2NUBK"
  ],
  "SPENCER": [
    "A1135D6MVNZREA"
  ],
  "TABLET": [
    "A15MU3EQ4XZ3Y5",
    "A1BSQJM6E77NJE",
    "A1C66CX2XD756O",
    "A1DOD0Z74XEFYC",
    "A1FYY15VCM5WG1",
    "A1G2XVSR1VA5DI",
    "A1H8RTR0E3Y362",
    "A1J16TEDOYCZTN",
    "A1LCAPNEM1C36Z",
    "A1Q7QCGNMXAKYW",
    "A1Q878J3NE8P81",
    "A1S15DUFSI8AUG",
    "A1TD5Z1R8IWBHA",
    "A1TG8VNKP4DSQR",
    "A1ZB65LA390I4K",
    "A25521KS9QCAMD",
    "A271DR1789MXDS",
    "A27XSKZJJPVQA4",
    "A2D0X18EHNKEOJ",
    "A2H1I0AR67NWAC",
    "A2LJ4A527WOX9J",
    "A2M1CHUCI6RHN8",
    "A2M4YX06LWP8WI",
    "A2N49KXGVA18AR",
    "A2O85NMVNLPKVN",
    "A2RJLFEH0UEKI9",
    "A2TIAYXTNQWU3T",
    "A2TX61L00VISA5",
    "A2VK42LC4L0RV1",
    "A2XZMRZUFPEDN4",
    "A31POKKHZJR1J4",
    "A31XSAHMUL97CR",
    "A324X3KDTS7NYA",
    "A38EHHIB10L47V",
    "A390CW53E1P0G4",
    "A3IWJ2DYJQRA3T",
    "A3JN21B5ZOWUAN",
    "A3L0T0VL9A921N",
    "A3QXXOBP9MU5LY",
    "A3R9S4ZZECZ6YL",
    "A3SSWQ04XYPXBH",
    "A3ZKCWKG4097P",
    "A6IUL9CVJZXRR",
    "AAJB0R7QJO84W",
    "ADP5BND5THPTX",
    "AE5DW8GVLP9NX",
    "AGQHFIWNI20PO",
    "AIE8AMJ60B7OK",
    "ALYWZPYF4JAIT",
    "AO4A5QLO9663Q",
    "AOLDXB6WYN0UM",
    "ATNLRCEBX3W4P",
    "AUNIXHOL9EVMI",
    "AVU7CPPF2ZRAS",
    "AZKAGPPWORIRY"
  ],
  "TBIRD_ANDROID": [
    "A2S24G29BFP88",
    "A32HYK5X3X4V16"
  ],
  "TBIRD_IOS": [
    "A1N9SW0I0LUX5Y"
  ],
  "TCOMM_ENABLED_AVBLAST_CLIENT": [
    "A11O6BDOSQGNFY",
    "A154KE3R2UHSZ3",
    "A192JU402NZ9BP",
    "A1BJ31R2PNL5QR",
    "A1DPOLXZDB1G1X",
    "A1EY5HZDPHPZ3R",
    "A1G1R46CXH00DS",
    "A1HATOGRMX0ZOT",
    "A1HCK0PFM80BGE",
    "A1IJNVP3L4AY8B",
    "A1KAXIG6VXSG8Y",
    "A1MWYRS2QVQH2N",
    "A1PCKT065NVG32",
    "A1Q9GHQ9VQ46SY",
    "A1TSWSABGEH6UU",
    "A1VG5CUJOEDAL0",
    "A21WMBJJ929L0V",
    "A23H4VHYAK052U",
    "A28RQHJKHM2A2W",
    "A2C5DH7DPBGPZE",
    "A2E5IRZFY3A1IE",
    "A2EHF10Z0YHPEK",
    "A2FUFRS8LZNP4X",
    "A2GEDS0PQ3RIEK",
    "A2JL926HVSLS1K",
    "A2OAZYH5YHRYJL",
    "A2XYE8X2YQ71O9",
    "A30VM2VYSCLFIY",
    "A31F4IT3LE16JF",
    "A32DO9W92Z785",
    "A346PUCF53XSMD",
    "A35PXMZO6ISFEI",
    "A368RSS99I9V9R",
    "A39VCYBAIIWVIL",
    "A3AV0HF4Q7U5RJ",
    "A3CUO5FD0HTNBX",
    "A3E60SVKQXJ0Z8",
    "A3IQ5RQKVIIK3M",
    "A3TS20BSR3CYVO",
    "A3VN4E5F7BBC7S",
    "A3VOTQ1N1Q6VW7",
    "A3W2K7M02KY3Q0",
    "A4Y3PSMRC158H",
    "A71EV7EKIOQHQ",
    "A7S2OP8OMZO2E",
    "AB6MI2Q1S9JSL",
    "AEUAXE7JLWMDC",
    "AFOQV1TK6EU6O",
    "AMGUKK2CPZK39",
    "AMGZ4CKCWIKYF",
    "AN5E8BRBXZCOF",
    "ANDBIO9TFKLV4",
    "AO0U100KEU7RN",
    "AOP2VR567VJAI",
    "AOVF0ZA6M93S1",
    "APG14WG337E8N",
    "APGZ6AXY81ZHL",
    "AR8DE21S8PINM",
    "AYDGJRHM5VYK4",
    "AZ6EQMK8B6918"
  ],
  "THIRD_PARTY_AVS_3P_APPSTORE": [
    "A2X45HMBPDWIUZ"
  ],
  "THIRD_PARTY_AVS_ARC_TESTING": [
    "A25Q4JJJQMAS4I",
    "A2AD82GX7GSAFI",
    "A2Q415CLDY3YX6",
    "A3C32ONDG9UAL2",
    "A3L0R46X3BJDI1",
    "AHQAJT71LN8D7"
  ],
  "THIRD_PARTY_AVS_MEDIA_DISPLAY": [
    "A112LJ20W14H95",
    "A11FNX956E6MZM",
    "A11MA7M1RKP4FK",
    "A11W4UISOS7TZ3",
    "A14KES4QWQ3HXV",
    "A15EICW0GCRC1U",
    "A15ERDAKK5HQQG",
    "A15EX3J1AD3GKD",
    "A15GD1MD8DI76H",
    "A16NRFFWYR1E0F",
    "A16RUDFSPGUZPW",
    "A18BI6KPKDOEI4",
    "A19RXMKXNPNVIB",
    "A1AQA54D221IDO",
    "A1C48WUJXIJAIG",
    "A1EJU47YN3Y5KE",
    "A1EZVYFQZHLEQ3",
    "A1G98ESICVZLMF",
    "A1H0CMF1XM0ZP4",
    "A1IIVXQMYBA3D9",
    "A1IOXA1EM6J5QX",
    "A1JHNMO0WFCVFQ",
    "A1KD2ZM0U3QUD7",
    "A1LHVAMD40PWWW",
    "A1M0A9L9HDBID3",
    "A1P31Q3MOWSHOD",
    "A1Q71L7NJS83IO",
    "A1ST3689LE3KDT",
    "A1TL02VVI46POY",
    "A1TP0LA8HM9USH",
    "A1UX9934WBU043",
    "A1UYSDLUE8ZMH7",
    "A1W2XVLSB8S7CG",
    "A1W9R0HPX7KKHN",
    "A1WAR447VT003",
    "A1WD2KSAJN0AMY",
    "A1X92YQU8MWAPD",
    "A1XLPGPVCZDIOH",
    "A1YCBVWPHOD7GM",
    "A200N7W1SGT587",
    "A20LVX3VBEHMDR",
    "A210E2EUXBC94K",
    "A21X6I4DKINIZU",
    "A21Z3CGI8UIP0F",
    "A233V8E09VRF2T",
    "A23JPG33Q8JDQ1",
    "A246WXEVWN19AS",
    "A24QIU91UHSYSX",
    "A25EC4GIHFOCSG",
    "A25N9GL7L4YDHC",
    "A25QVSAPCVNHWH",
    "A25SROGDU34JAE",
    "A262AL65IISEZB",
    "A26MSQ782PKOD2",
    "A26QSVO1LXOX5X",
    "A26TRKU1GG059T",
    "A2BC0E06YDROGI",
    "A2BL13Q3FM24N5",
    "A2CSPIVZKD69QG",
    "A2DC06EVYHTI53",
    "A2ECGUUQ2QH3PF",
    "A2EUYCTJW14SLT",
    "A2FHTZSQ8UFCOW",
    "A2GHJIFZJ15BTM",
    "A2I0PB41C6O8VI",
    "A2JZMCU09D6WLB",
    "A2K0PZIPEISM7H",
    "A2KIEIMDBCZYVV",
    "A2KN0TWVZGPF4D",
    "A2KQ4ES11X5QFI",
    "A2MFKDJEJT5RD4",
    "A2MHULRUS4IL7E",
    "A2QMBHSBBQHFZN",
    "A2SF6SYEOSBRYR",
    "A2SRL5KIDQZEZO",
    "A2TOXM6L8SFS8A",
    "A2UFOCV24X971V",
    "A2UJWYKR23U6JR",
    "A2W3K3LMTJPC5D",
    "A2WTJ6MN87XOVX",
    "A2ZK11NUNDVVWW",
    "A2ZLL48PLMNVGR",
    "A3088NSQEQ6A8T",
    "A31FRHQQMWSJ0X",
    "A33SIHFXPFVEPJ",
    "A34M06CXJCG0QF",
    "A366ZM1L6NC6L1",
    "A36SMCWUA2LQM2",
    "A377Q4JJMV2NVV",
    "A37M7RU8Z6ZFB",
    "A394K691QUJ5BY",
    "A3992JHVZ63WRP",
    "A39GAW1VS4JMKT",
    "A39Q1M1MBBVWIO",
    "A39Z9OEA20T55W",
    "A3B50IC5QPZPWP",
    "A3BABQ93QA7T7I",
    "A3BAW9O0N4VO05",
    "A3CJK0O2M6UNWP",
    "A3DC74GRGEKLKR",
    "A3DCLGVWN5CR5G",
    "A3E6JSNXJTY9NO",
    "A3E76BOYNP0JVO",
    "A3EMM4JC943AES",
    "A3EWS8DTTVZPVK",
    "A3GL3C80YAZ3UW",
    "A3HCZV7YTZUBM2",
    "A3HU07XM8MGS2F",
    "A3INNOT2B31OV7",
    "A3ISO8E4QGGUI0",
    "A3JC4Z5YWORS9F",
    "A3LJLOEFTRLUGQ",
    "A3LWXSSBADUQ29",
    "A3NV0DTVDD0PVJ",
    "A3NZHLGERJJSQ7",
    "A3Q6RA0OLPLREI",
    "A3Q87SI8VR90CW",
    "A3QPPX1R9W5RJV",
    "A3R8XIAIU4HJAX",
    "A3SCNRURY38NKC",
    "A3TH5VRFVTJXOU",
    "A3UJ53VM6LA98D",
    "A4DUK7NEVVLE",
    "A4SOPBLPUE9VX",
    "A5SSCWE0885MU",
    "A5YFJGL43EM6W",
    "A7FAGK6TS3QDG",
    "A7KJ6C6DOAMMB",
    "A8209FKACK7VQ",
    "A8DM4FYR6D3HT",
    "A8RBV3FWF370F",
    "AAHPDGCZMDT41",
    "ACW54XZ8LGSJO",
    "ACWFH0XQ9RYNH",
    "AD5IY7D5MHEOD",
    "ADSSUA93DUFR6",
    "AILBSA2LNTOYL",
    "AJSWFGN2G3JWF",
    "AKBKXHOVY59G3",
    "ALSVUOJXA2TTV",
    "AMKJ965WDZK78",
    "ANEVH5IT7RAF0",
    "AO6HHP9UE6EOF",
    "APFXW0SYGKZ9J",
    "AQCGW9PSYWRF",
    "AQOIAEYIOEYHU",
    "ARHXF2KTO48AH",
    "ARX0EARWDD9CK",
    "AT063JLTXM9NU",
    "AUPUQSVCVHXP0",
    "AUQVNPM5EP9GP",
    "AV571G8VHFL5H",
    "AVN2TMX8MU2YM",
    "AVX0D0IP69B7F",
    "AZO8T8N2FVMLW"
  ],
  "THIRD_PARTY_AVS_MEDIA_DISPLAY_BLUETOOTH": [
    "A1NCE44NCM3JH8",
    "A24FXB3NQFSSI1",
    "A2MERDPZ69D7L8",
    "A32P7BT695L4XT",
    "A33Z6KG5SUYNTJ",
    "A37CFAHI1O0CXT",
    "A3J0VWMSHFYHUE",
    "A3NRL1NMI7TZ9H",
    "AH6EJM5TKD4KL",
    "AINRG27IL8AS0",
    "AK6OPX8ZR3IL5"
  ],
  "THIRD_PARTY_AVS_NO_DISPLAY": [
    "A1EQ21HB3JA65K",
    "A1NYD797SQNYT4",
    "A37GAGAXPFF3E4",
    "A3IZNFNQCDJHBE",
    "A3OZU5N0VSLGKE",
    "A3SQO4FUBFC4C5",
    "A4R97J7RIUKGC"
  ],
  "THIRD_PARTY_AVS_SONOS_BOOTLEG": [
    "A13HOMIFCDC1WX",
    "A14OJEI1COZ47I",
    "A14ZH95E6SE9Z1",
    "A16MF408KAUNP9",
    "A1FWRGKHME4LXH",
    "A1NAMAI2YY0CF7",
    "A2EZ3TS0L1S2KV",
    "A2M3R96S2XVCV4",
    "A2OSP3UA4VC85F",
    "A2RG3FY1YV97SS",
    "A2S42UILWKCAY8",
    "A2TF3QDXJDUQU9",
    "A2WFDCBDEXOXR8",
    "A2Z8O30CD35N8F",
    "A30WO4LDB7X1DM",
    "A37NHWC6NTOTXP",
    "A37SHHQ3NUL7B5",
    "A3A2J2QVOH0LME",
    "A3BRT6REMPQWA8",
    "A3D4JFMW45AKEF",
    "A3H1YPES0DU0Y0",
    "A3NPD82ABCPIDP",
    "A8D2OKFFQKQ56",
    "AHJYKVA63YCAQ",
    "AL054WNOERJHW",
    "API9PC9RTOQWH",
    "AQNXPMWWDFHSE",
    "ASQJLMBL258O9",
    "AT269RG5KJ49S",
    "AVD3HM0HOJAAL",
    "AXSYN14WCFJDJ"
  ],
  "THIRD_PARTY_AVS_TEXT_ONLY_DISPLAY": [
    "A2954EXN8LJF9R",
    "A313PN1YYR965L",
    "A9YU3XB1OZ676"
  ],
  "THIRD_PARTY_AVS_VIDEO_FIRST": [
    "A10FTZVJ3JA3FX",
    "A10MDJ4NEQASTG",
    "A11DUJZ8M1DZDE",
    "A13652NLMIC3SM",
    "A138RVFAVDHOAJ",
    "A15DJLKNR8NSYN",
    "A166TOQEHV1FK6",
    "A16HL6HUAJMDFI",
    "A175GOHSSVGGCC",
    "A1B5D0OQEBFGH1",
    "A1EZHCWC4Z3GOQ",
    "A1F4U8HZOIIWBX",
    "A1G1684VM95Y20",
    "A1IB0Y66CP9RIJ",
    "A1NQ0LXWBGVQS9",
    "A1OBYLZ4EMA8SC",
    "A1OECNXBQCC1P9",
    "A1Q69AKRWLJC0F",
    "A1QKZ9D0IJY332",
    "A1QOKIB9X55KM9",
    "A1T2VM3OEK3QF9",
    "A1UER8R7JQ48M2",
    "A1UPGQTCDS04G8",
    "A1UTV3CB2AZ6O1",
    "A1W46V57KES4B5",
    "A1XP8DLP26S1LM",
    "A1YDTVRDKBNM2M",
    "A218GHTK4WJVLR",
    "A23LLH3HJY6X43",
    "A24DWRTSXWMLT6",
    "A25OJWHZA1MWNB",
    "A297QSVCFRF0R0",
    "A2AA8KTBAI5WW0",
    "A2BI7PKAM50YXK",
    "A2FZM0FV7CDLKL",
    "A2GYDZW7MHIN98",
    "A2IS7199CJBT71",
    "A2LKYT4MWB9MSH",
    "A2N3WZS9N0M3QR",
    "A2NYZQ63E0S782",
    "A2OAR4LJA5X5N0",
    "A2RCXHAH9A0JYP",
    "A2SX6V1Y8U4XJ3",
    "A2TTLILJHVNI9X",
    "A2X9BRUC6QY2LD",
    "A314G8M318UJ29",
    "A324YMIUSWQDGE",
    "A32NPTZLGT3UXB",
    "A33CQ7EDKZH14",
    "A33K0ISUWWREC4",
    "A34VRDMLOK92QZ",
    "A38OLON9E4G03O",
    "A3CTZ4QNJWYLA1",
    "A3DU883VH2HEFY",
    "A3DYGNE37TAX7D",
    "A3G2SOWYLR3W2Z",
    "A3HCL44G4HC2NQ",
    "A3HH5K6OISMGP4",
    "A3IDYDND2EWBP5",
    "A3LILE90BNZQW9",
    "A3RCTOK2V0A4ZG",
    "A3SAITM20V0MBX",
    "A3SSNUEIHTP31A",
    "A3U70UVKRKPDDL",
    "A3UZQ7BH7TX2IK",
    "A4I3EZPVT02JQ",
    "A4JGN63KFPBO1",
    "A5H8PG9RXVAAD",
    "A6JD0BY0Z6MJK",
    "A6L9WWA9SMCD3",
    "A8FVPJJ13HQJO",
    "AB4PDH3F44D5L",
    "AC9S7HB0ZRVO2",
    "AFX6DSJ7327B4",
    "AH1XQ0PZRT1II",
    "AHOZFGUP70VVQ",
    "AI58X5J09GNPC",
    "AK2E3SVBN8ZWC",
    "AK51N2GTK8WAA",
    "AKRQUD7VR255N",
    "ANJVW42Q3F320",
    "ANTBGXTF1ZR62",
    "ANY2FRII8YY6G",
    "APO0E7I7O64F2",
    "ARJKFE2F1LCJQ",
    "ATO48XRBVRA4J",
    "AUH18793VINXM",
    "AUK7F8AQXI8OQ",
    "AVOCYVVKE9IU7",
    "AZJ1FK3FRGTUR",
    "AZVNV1HBQHE46"
  ],
  "THIRD_PARTY_VOX_ACCESSORY": [
    "A10NL5MECLO0K",
    "A11L5ZU053A59I",
    "A16EFY1DSO880R",
    "A189LTZOO5ZTGA",
    "A192ZB03KIIO9R",
    "A1HONUDZ5Z1Q7U",
    "A1NXG43R89WKC",
    "A28X5U5THTXXM1",
    "A2IWX9HO0J2ST8",
    "A2J6HH7L27ARVE",
    "A2JR7Q2EAXKBJB",
    "A2P39DOAZNXZRC",
    "A2T7YGDN3MC48",
    "A32E8VQVU960EJ",
    "A32ZCQ1E25IJKR",
    "A33BAIYRB6XQE9",
    "A3BAP6Z889UXHC",
    "A3G59QJJZ3UOPP",
    "A3GQGKFD832MJ1",
    "A3IS3LDBB61R9W",
    "A3QS1XP2U6UJX9",
    "A4HO8LTG7QYJW",
    "AXVOTTTHZFIIG",
    "AZJ4GBZKAX4QH"
  ],
  "UNKNOWN": [
    "UNKNOWN"
  ],
  "VESTA": [
    "A2MDL5WTJIU8SZ"
  ],
  "VESTA_APP": [
    "A14DGXM2ATUBZ5",
    "A2L01CGF6XKOLB",
    "AR3ZLZEAIMD6C"
  ],
  "VISUAL_APPS": [
    "A351E3UNENJ84P"
  ],
  "VOX": [
    "A1J2IJI8OINDFT",
    "A26Z3MZII1YZ7",
    "A2IVLV5VM2W81",
    "A2MK57D88VPC79",
    "A2TF17PFR55MTB"
  ],
  "VOX_ACCESSORY": [
    "A1388YOZ88W373",
    "A15QWUTQ6FSMYX",
    "A168KS6Z8QG6RV",
    "A21YKVRGQV9TET",
    "A2QDHDQIWC2LTG",
    "A31PMVIWCRNTX2",
    "A3HND3J60V1OXX",
    "A3HVREY4JWAZ6K",
    "A3KF60B9RDMWLY",
    "ADXK6Q246T9EA"
  ],
  "WHA": [
    "A3C9PE6TNYLTCH",
    "AP1F6KUH00XPV"
  ],
  "ZEPHYR": [
    "A17FE8SC0QDWB5",
    "A27USEHWMB45FE",
    "A3IYPH06PH1HRA"
  ]
}

export function getDeviceFamilies(): string[] {
  return Object.keys(DeviceFamilies);
}

export function getDeviceFamilyFor(deviceType: string): string {
  const filtered = Object.keys(DeviceFamilies).filter(key => DeviceFamilies[key].indexOf(deviceType) >= 0)
  if (filtered.length === 0) return "UNKNOWN";
  if (filtered.length > 1) console.log(`multiple device families - ${JSON.stringify(filtered)} found for device type - ${deviceType}`)
  return filtered[0];
}
