import {AggregatedView, FrictionMetrics} from "../../index";
import {Metric, MetricEvaluator, MetricEvaluatorProps} from "../metric";
import {getFrictionMetric} from "../../../utils/frictionMetricUtils";
import {getFrictionMetricSum} from "../../../common/utils";

export interface FrictionCountMetricProps extends MetricEvaluatorProps {
    frictionMetric: string
}

/**
 * Evaluates the total count of a friction metric.
 */
export class FrictionCountMetric extends MetricEvaluator {

    frictionMetric: string

    constructor(props: FrictionCountMetricProps) {
        super(props);
        this.frictionMetric = props.frictionMetric
    }

    evaluate(aggregatedViews: AggregatedView[]): Metric {

        let frictionMetrics: FrictionMetrics[] = getFrictionMetric(aggregatedViews, [])
            .filter(fM => fM.label === this.frictionMetric)
        let seriesStatValue: number = 0;

        if (frictionMetrics.length === 1) {
            let result: {[x:string]: {actualSum: number, comparisonSum: number}} = getFrictionMetricSum({
                [this.frictionMetric]: frictionMetrics[0].filters
            })

            seriesStatValue = result[this.frictionMetric].actualSum
        }

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue,
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
