import {AggregatedView} from "../../index";
import {Metric, MetricEvaluator, MetricEvaluatorProps} from "../metric";
import {getStateKey} from "../../../common/utils";

export interface StateVisitCountMetricProps extends MetricEvaluatorProps {
    stateKey: string;
    showTags: boolean
}

/**
 * This evaluates total count of given {@link stateKey} in the av list
 */
export class StateVisitCountMetric extends MetricEvaluator {

    stateKey: string;
    showTags: boolean;

    constructor(props: StateVisitCountMetricProps) {
        super(props);
        this.stateKey = props.stateKey;
        this.showTags = props.showTags;
    }

    evaluate(aggregatedViews: AggregatedView[]): Metric {

        let seriesStatValue: number = 0;
        aggregatedViews.forEach(av => {
            for (let state of av.states) {
                if (getStateKey(state, this.showTags) === this.stateKey) {
                    seriesStatValue += 1
                }
            }
        })

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue,
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
