import {AggregatedView} from "../../index";
import {FilterEvaluator, FilterEvaluatorProps} from "../filter";
import {getDistinctValues} from "../../../common/stringMetadata";

/**
 * This filter evaluates true if a value is present in a list of values.
 *
 * Calls getDistinctValues() which returns all the distinct values of a metadata
 * and returns true if the filter option is present in the list.
 */
export class MetadataValuesFilter extends FilterEvaluator {

    constructor(props: FilterEvaluatorProps) {
        super(props);
    }

    evaluate(av: AggregatedView, currentState: { label: string, value: string | string[] }): boolean {
        if (currentState === undefined || currentState.value === undefined) return true;
        if (typeof currentState.value === "string") {
            currentState.value = [currentState.value]
        }
        const values = getDistinctValues(this.mDesc, av, this.fallbackMetadata);
        if (currentState.value.indexOf("*") >= 0) {
            if (this.valuesToExclude !== undefined) {
                if (typeof values !== 'undefined') {
                    const distilledValues = values.filter(opt => this.valuesToExclude!.indexOf(opt) < 0)
                    if (distilledValues.length > 0) return true
                }
                return false;
            }
            return true;
        }
        if (typeof values !== 'undefined' && currentState.value.some(opt => values.indexOf(opt) >= 0)) return true; // matched
        return false;
    }

}
