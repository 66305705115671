import {Button, Checkbox, Header, SpaceBetween, Tabs, TextContent} from "@awsui/components-react";
import {
    setAnalyticsTab,
    setGlobalTransitionAnalysisMode,
    subscribeGlobalTransitionAnalysisModeChanges
} from "../../../data/metricStateManager";
import React, {useEffect, useState} from "react";
import {PageMetricsTable} from "./pageMetrics/PageMetricsTable";
import {TransitionsGraph} from "./transitions/graph/serviceGraphMap";
import {AnalyticsTabType, TransitionAnalysisMode} from "../../../interfaces";
import {
    subscribeAnalyticsTabsLoadInProgress,
    subscribeLoadInProgressChanges,
    subscribeLoadPageMetricsButtonDisabledFlagChanges
} from "../../../data/globalState";
import {loadPageMetrics} from "../../../data/dataManager";


export function AnalyticsTabs() {

    const [transitionAnalysisMode, setTransitionAnalysisMode] = useState<TransitionAnalysisMode>("customers");
    const [loadPageMetricsDisableFlag, setLoadPageMetricsDisableFlag] = useState<boolean>(true);
    const [loadInProgress, setLoadInProgress] = useState<boolean>();
    const [loadAnalyticsTabInProgress, setLoadAnalyticsTabInProgress] = useState<boolean>();

    useEffect(() => {
        subscribeGlobalTransitionAnalysisModeChanges(setTransitionAnalysisMode)
    }, [setTransitionAnalysisMode])

    useEffect(() => {
        subscribeLoadPageMetricsButtonDisabledFlagChanges(setLoadPageMetricsDisableFlag);
    }, [setLoadPageMetricsDisableFlag]);

    useEffect(() => {
        subscribeLoadInProgressChanges(setLoadInProgress);
    }, [setLoadInProgress]);

    useEffect(() => {
        subscribeAnalyticsTabsLoadInProgress(setLoadAnalyticsTabInProgress);
    }, [setLoadAnalyticsTabInProgress]);

    
    return (
        <>
            <Button variant={"primary"} onClick={loadPageMetrics} disabled={loadPageMetricsDisableFlag} loading={loadInProgress || loadAnalyticsTabInProgress}>Load Page Metrics</Button>
            <Header
                variant="h2"
                actions={
                    <>
                        <Button variant={"normal"}>
                            <SpaceBetween size={'xs'} direction={'horizontal'}>
                                <TextContent>Transition Metric: </TextContent>
                                <Checkbox checked={transitionAnalysisMode === 'customers'}
                                          onChange={e => setGlobalTransitionAnalysisMode('customers')}>Unique customers</Checkbox>
                                <Checkbox checked={transitionAnalysisMode === 'count'}
                                          onChange={e => setGlobalTransitionAnalysisMode('count')}>Total instances</Checkbox>
                            </SpaceBetween>
                        </Button>
                    </>
                }
            />
            <Tabs variant={"default"}
                  onChange={event => {
                      setAnalyticsTab(event.detail.activeTabId as AnalyticsTabType)
                  }}
                  tabs={[
                    {
                        id: 'PageTab',
                        label: 'Page level metrics',
                        content: <PageMetricsTable/>
                    },
                    {
                        id: 'TransitionTab',
                        label: 'Transition diagram',
                        content: <TransitionsGraph/>
                    },
            ]}/>
        </>
    )
}