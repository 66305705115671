import {AggregatedView} from "../../index";
import {StatsFunctions} from "../../../common/stats";
import {EvaluationProps, Metric, MetricEvaluator, MetricEvaluatorProps} from "../metric";


/**
 * This evaluates the total number of requests.
 * Each IC in av is considered a single request.
 */
export class RequestCountMetric extends MetricEvaluator {

    constructor(props: MetricEvaluatorProps) {
        super(props);
    }

    evaluate(aggregatedViews: AggregatedView[], props?: EvaluationProps): Metric {

        const periodData: { [window: number]: number[] } = {};

        let isTrendRequired = undefined;

        if (typeof props !== 'undefined') {
            isTrendRequired = (props.isTrendRequired !== undefined ? props.isTrendRequired: this.isTrendRequired);
        }

        let seriesStatValue = 0;
        aggregatedViews.forEach(av => {
            let temp = typeof av.icDescriptors === 'undefined' ? 0 : av.icDescriptors.length
            if (typeof props !== 'undefined') {
                const window = Math.floor(av.timestamp / props.period) * props.period;
                if (!periodData.hasOwnProperty(window)) periodData[window] = [];
                periodData[window].push(temp);
            }
            seriesStatValue += temp;
        })

        if (!isTrendRequired && isTrendRequired === false) {
            return {
                metricLabel: this.metricLabel,
                metricValue: seriesStatValue,
                isUpwardTrendGood: this.isUpwardTrendGood
            };
        }

        const overallSeriesData: { x: Date, y: number }[] = [];

        const sortedWindows = Object.keys(periodData).map(v => Number(v)).sort();

        sortedWindows.forEach(window => {
            overallSeriesData.push({
                x: new Date(window), y: StatsFunctions.get(props!.stat? props!.stat: "sum")!(periodData[window])
            });
        });

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue,
            trend: overallSeriesData,
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
