import {
    AggregatedView,
    AnalyticsTabType,
    FrictionMetrics,
    TransitionAnalysisMode,
    TransitionMetrics
} from "../interfaces";
import {
    getFrictionMetric, getSummaryMetric,
} from "../utils/frictionMetricUtils";
import {MetricAwareFilter, RegisteredFilters, StateMetricFilter} from "../interfaces/filters/filter";
import {getRegisteredFilters} from "./filterStateManager";
import {getTransitionKey} from "../common/utils";
import {initializeTransitionMetric} from "../utils/pageMetricsUtils";
import {getAnalyticsData} from "./dataManager";

let global_showPageTags = false;
let global_showTransitionTags = true;
let global_transitionAnalysisMode: TransitionAnalysisMode = 'customers';
let stateMetrics: StateMetricFilter[] = []
let transitionStateMetrics: StateMetricFilter[] = []
let summaryMetric: MetricAwareFilter | undefined = undefined;
let frictionMetric: FrictionMetrics[] = []
// current selected analytics tab
let analyticsTab: AnalyticsTabType = "PageTab";

// listeners subscribed for updates to stateMetrics
const stateMetricsListeners = new Set<(stateMetrics: StateMetricFilter[]) => void>();
// listeners subscribed for updates to transitionStateMetrics
const transitionStateMetricsListeners = new Set<(transitionStateMetrics: StateMetricFilter[]) => void>();
// listeners subscribed for updates to summaryMetrics
const summaryMetricListeners = new Set<(summaryMetric: MetricAwareFilter) => void>();
// listeners subscribed for updates to summaryMetrics
const frictionMetricListeners = new Set<(summaryMetric: FrictionMetrics[]) => void>();
// listeners subscribed for updates to showPageTags
const showPageTagsListeners = new Set<(showPageTags: boolean) => void>();
// listeners subscribed for updates to showTransitionTags
const showTransitionTagsListeners = new Set<(showTransitionTags: boolean) => void>();
// listeners subscribed for updates to transitionAnalysisMode
const transitionAnalysisModeListeners = new Set<(transitionAnalysisMode: TransitionAnalysisMode) => void>();
// listeners subscribed for updates to analyticsTab
const analyticsTabListeners = new Set<(analyticsTab: AnalyticsTabType) => void>();

/**
 * Generate StateMetrics filters from avs
 * Calls {@link getRegisteredFilters}, filter then using 'StateFilterGroup' groupName
 * Pushes it to {@link stateMetrics} list based on {@link global_showPageTags} & isStatePageFilter flag
 * @param avs
 */
export function generateStateMetrics(avs: AggregatedView[]): StateMetricFilter[] {
    let stateMetrics: StateMetricFilter[] = []

    let registeredFilters: RegisteredFilters = getRegisteredFilters();

    Object.keys(registeredFilters.metricAwareFilterGroups)
        .filter(groupName => groupName === "StateFilterGroup")
        .map(groupName => registeredFilters.metricAwareFilterGroups[groupName])
        .forEach(group => {
            Object.keys(group.filters)
                .map(filterName => group.filters[filterName] as StateMetricFilter)
                .forEach(filter => {
                    if (!global_showPageTags) {
                        if (!filter.isStatePageFilter) {
                            stateMetrics.push(filter)
                        }
                    } else {
                        stateMetrics.push(filter)
                    }
                })
        })

    const transitionMetrics = new Map<string, TransitionMetrics>();
    const customersPerTransition = new Map<string, Set<string>>();

    avs
        .forEach(av => {
            let transitions = av.transitions.slice();
            for (let ct = 0; ct < transitions.length; ct++) {
                let transition = transitions[ct]
                let key = getTransitionKey(transition, global_showPageTags, global_showTransitionTags)
                if (!transitionMetrics.has(key)) transitionMetrics.set(key, initializeTransitionMetric(transition, global_showPageTags, global_showTransitionTags))
                if (!customersPerTransition.has(key)) customersPerTransition.set(key, new Set);
                transitionMetrics.get(key)!.count += 1;
                customersPerTransition.get(key)!.add(av.customerIdentifier);
            }
        })

    customersPerTransition.forEach((set, key) => transitionMetrics.get(key)!.customers = set.size);

    stateMetrics
        .forEach(filter => {
            filter.outgoingTransitions = Array.from(transitionMetrics.values())
                .filter(t => t.originId === filter.currentState.value)
            filter.incomingTransitions = Array.from(transitionMetrics.values())
                .filter(t => t.destinationId === filter.currentState.value)
        })

    return stateMetrics
}

/**
 * Generate TransitionMetrics filters from avs
 * Calls {@link getRegisteredFilters}, filter then using 'StateFilterGroup' groupName
 * Pushes it to {@link transitionStateMetrics} list if metricData > 0 and isStatePageFilter flag
 * @param avs
 */
export function generateTransitionMetrics(avs: AggregatedView[]) {
    let transitionStateMetrics: StateMetricFilter[] = []
    let registeredFilters: RegisteredFilters = getRegisteredFilters();

    Object.keys(registeredFilters.metricAwareFilterGroups)
        .filter(groupName => groupName === "StateFilterGroup")
        .map(groupName => registeredFilters.metricAwareFilterGroups[groupName])
        .forEach(group => {
            Object.keys(group.filters)
                .map(filterName => group.filters[filterName] as StateMetricFilter)
                .filter(filter => filter.lineSeriesAndMetricData!.metricData.some(data => data.actualValue > 0))
                .forEach(filter => {
                    if (!filter.isStatePageFilter) {
                        transitionStateMetrics.push(filter)
                    }
                })
        })


    const transitionMetrics = new Map<string, TransitionMetrics>();
    const customersPerTransition = new Map<string, Set<string>>();

    avs
        .forEach(av => {
            let transitions = av.transitions.slice();
            for (let ct = 0; ct < transitions.length; ct++) {
                let transition = transitions[ct]
                let key = getTransitionKey(transition, false, false)
                if (!transitionMetrics.has(key)) transitionMetrics.set(key, initializeTransitionMetric(transition, false, false))
                if (!customersPerTransition.has(key)) customersPerTransition.set(key, new Set);
                transitionMetrics.get(key)!.count += 1;
                customersPerTransition.get(key)!.add(av.customerIdentifier);
            }
        })

    customersPerTransition.forEach((set, key) => transitionMetrics.get(key)!.customers = set.size);

    transitionStateMetrics
        .forEach(filter => {
            filter.outgoingTransitions = Array.from(transitionMetrics.values())
                .filter(t => t.originId === filter.currentState.value)
            filter.incomingTransitions = Array.from(transitionMetrics.values())
                .filter(t => t.destinationId === filter.currentState.value)
        })

    return transitionMetrics
}

/**
 * Set {@link stateMetrics}
 */
export async function setStateMetricsData(newStateMetrics: StateMetricFilter[]){
    await new Promise(f => setTimeout(f, 1));

    stateMetrics = newStateMetrics

    // notify everyone
    stateMetricsListeners.forEach(listener => listener(stateMetrics));
}

/**
 * Sets {@link transitionStateMetrics}
 * @param newTransitionStateMetric
 */
export async function setTransitionStateMetricsData(newTransitionStateMetric: StateMetricFilter[]){
    await new Promise(f => setTimeout(f, 1));

    transitionStateMetrics = newTransitionStateMetric

    // notify everyone
    transitionStateMetricsListeners.forEach(listener => listener(transitionStateMetrics));
}

/**
 * Set {@link summaryMetric}
 */
export function setSummaryMetric(avs: AggregatedView[], comparisonAvs: AggregatedView[]){
    summaryMetric = getSummaryMetric(avs, comparisonAvs);

    // notify everyone
    summaryMetricListeners.forEach(listener => listener(summaryMetric!));
}

/**
 * Set {@link frictionMetric}
 */
export function setFrictionMetric(avs: AggregatedView[], comparisonAvs: AggregatedView[]){
    frictionMetric = getFrictionMetric(avs, comparisonAvs);

    // notify everyone
    frictionMetricListeners.forEach(listener => listener(frictionMetric));
}

/**
 * Set {@link global_showPageTags}
 */
export function setGlobalShowPageTags(newShowPageTags: boolean) {
    console.log('setShowPageTags: current showPageTags: ', global_showPageTags);

    if (global_showPageTags === newShowPageTags) {
        console.log('setShowPageTags: No change identified for showPageTags - skipping executing listeners')
        return;
    }

    global_showPageTags = newShowPageTags;

    console.log('setShowPageTags: updated showPageTags: ', global_showPageTags)

    // notify everyone
    showPageTagsListeners.forEach(listener => listener(global_showPageTags));

    setStateMetricsData(generateStateMetrics(getAnalyticsData().filteredViews))

}

/**
 * Set {@link global_showTransitionTags}
 */
export function setGlobalShowTransitionTags(newShowTransitionTags: boolean) {
    console.log('setShowTransitionTags: current showTransitionTags: ', global_showTransitionTags);

    if (global_showTransitionTags === newShowTransitionTags) {
        console.log('setShowTransitionTags: No change identified for showTransitionTags - skipping executing listeners')
        return;
    }

    global_showTransitionTags = newShowTransitionTags;

    console.log('setShowTransitionTags: updated showTransitionTags: ', global_showTransitionTags)

    // notify everyone
    showTransitionTagsListeners.forEach(listener => listener(global_showTransitionTags));

    setStateMetricsData(generateStateMetrics(getAnalyticsData().filteredViews))

}

/**
 * Set {@link global_transitionAnalysisMode}
 */
export function setGlobalTransitionAnalysisMode(newTransitionAnalysisMode: TransitionAnalysisMode) {
    console.log('setTransitionAnalysisMode: current transitionAnalysisMode: ', global_transitionAnalysisMode);

    if (global_transitionAnalysisMode === newTransitionAnalysisMode) {
        console.log('setTransitionAnalysisMode: No change identified for transitionAnalysisMode - skipping executing listeners')
        return;
    }

    global_transitionAnalysisMode = newTransitionAnalysisMode;

    console.log('setTransitionAnalysisMode: updated transitionAnalysisMode: ', global_transitionAnalysisMode)

    // notify everyone
    transitionAnalysisModeListeners.forEach(listener => listener(global_transitionAnalysisMode));

    if (analyticsTab === "PageTab") {
        setStateMetricsData(generateStateMetrics(getAnalyticsData().filteredViews))
    } else {
        setTransitionStateMetricsData(generateStateMetrics(getAnalyticsData().filteredViews))
    }
}

/**
 * Set {@link analyticsTab}
 */
export function setAnalyticsTab(newAnalyticsTab: AnalyticsTabType) {
    console.log('setAnalyticsTab: current analyticsTab: ', analyticsTab);

    if (analyticsTab === newAnalyticsTab) {
        console.log('setAnalyticsTab: No change identified for analyticsTab - skipping executing listeners')
        return;
    }

    analyticsTab = newAnalyticsTab;

    console.log('setAnalyticsTab: updated analyticsTab: ', analyticsTab)

    // notify everyone
    analyticsTabListeners.forEach(listener => listener(analyticsTab));
}

/**
 * Subscribe to get notified whenever 'stateMetrics' state changes
 * @param listener
 */
export function subscribeStateMetricsChanges(listener: (stateMetrics: StateMetricFilter[]) => void) {
    stateMetricsListeners.add(listener);
    listener(stateMetrics);
}

/**
 * Subscribe to get notified whenever 'transitionStateMetrics' state changes
 * @param listener
 */
export function subscribeTransitionStateMetricsChanges(listener: (transitionStateMetrics: StateMetricFilter[]) => void) {
    transitionStateMetricsListeners.add(listener);
    listener(transitionStateMetrics);
}

/**
 * Subscribe to get notified whenever 'summaryMetric' state changes
 * @param listener
 */
export function subscribeSummaryMetricChanges(listener: (summaryMetric: MetricAwareFilter) => void) {
    summaryMetricListeners.add(listener);
    listener(summaryMetric!);
}

/**
 * Subscribe to get notified whenever 'frictionMetric' state changes
 * @param listener
 */
export function subscribeFrictionMetricChanges(listener: (frictionMetric: FrictionMetrics[]) => void) {
    frictionMetricListeners.add(listener);
    listener(frictionMetric);
}

/**
 * Subscribe to get notified whenever 'showPageTags' state changes
 * @param listener
 */
export function subscribeGlobalShowPageTagsChanges(listener: (showPageTags: boolean) => void) {
    showPageTagsListeners.add(listener);
    listener(global_showPageTags);
}

/**
 * Subscribe to get notified whenever 'showTransitionTags' state changes
 * @param listener
 */
export function subscribeGlobalShowTransitionTagsChanges(listener: (showTransitionTags: boolean) => void) {
    showTransitionTagsListeners.add(listener);
    listener(global_showTransitionTags);
}

/**
 * Subscribe to get notified whenever 'transitionAnalysisMode' state changes
 * @param listener
 */
export function subscribeGlobalTransitionAnalysisModeChanges(listener: (transitionAnalysisMode: TransitionAnalysisMode) => void) {
    transitionAnalysisModeListeners.add(listener);
    listener(global_transitionAnalysisMode);
}

/**
 * Subscribe to get notified whenever 'analyticsTab' state changes
 * @param listener
 */
export function subscribeAnalyticsTabChanges(listener: (analyticsTab: AnalyticsTabType) => void) {
    analyticsTabListeners.add(listener);
    listener(analyticsTab);
}