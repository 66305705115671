import {MetricEvaluator} from "../../interfaces/metrics/metric";
import {additionalSummaryMetricOptions, frictionMetricOptions, getMetricOptions} from "../configs";
import {metricEvaluatorOptions} from "../../common/specsMap";

/**
 * Reads metrics from page-metric-spec.json
 * @param stateKey
 * @param showTags
 * @param showTransitionTags
 * @param transitionAnalysisMode
 */
export function getPageMetricEvaluators(stateKey: string, showTags: boolean): any {

    let metricEvaluators: MetricEvaluator[] = []

    getMetricOptions()
        .forEach((metricOption: any) => {
            metricEvaluators.push(metricEvaluatorOptions[metricOption.evaluationFunction.type]
                .constructor({...metricOption.evaluationFunction.props, stateKey, showTags}));
        })

    return metricEvaluators

}

/**
 * Read additional metric spec and friction metric spec to be displayed at the top of dashboard.
 */
export function getSummaryMetricEvaluators(): any {

    let metricEvaluators: MetricEvaluator[] = []

    if (additionalSummaryMetricOptions !== undefined) {
        additionalSummaryMetricOptions
            .forEach((metricOption: any) => {
                metricEvaluators.push(metricEvaluatorOptions[metricOption.evaluationFunction.type]
                    .constructor({...metricOption.evaluationFunction.props}));
            })
    }

    return metricEvaluators
}

export function getFrictionSummarySpecs() {
    return frictionMetricOptions
}