import {AggregatedView, FrictionMetrics, LineSeriesAndMetricData, MetricData} from "../interfaces";
import {
  getRegisteredFilters, resetFilters,
  resetShouldEvaluateFlag, setRegisteredFilters, updateRegisteredFiltersWithStateFilters,
} from "./filterStateManager";
import {
  getCachedAVForPrefix, getErrorMessage,
  getLambdaClientsPerRegion,
  getPrefixList,
  getS3ClientsPerRegion, getTrend, MILLIS_IN_DAY,
} from "../common/utils";
import {Lambda} from "@aws-sdk/client-lambda";
import {S3} from "@aws-sdk/client-s3";
import {DateRange, getComparisonDateRange, getPrimaryDateRange, getSelectedRegions} from "./dataSelectState";
import {
  addNotificationItem,
  removeAllNotifications,
  removeNotificationItem,
  setAnalyticsTabsLoadInProgress,
  setApplyFilterButtonDisabledFlag, setComparisonLoadButtonDisabledFlag,
  setLoadButtonDisabledFlag,
  setLoadInProgress, setLoadPageMetricsButtonDisabledFlag,
  setNotificationItems, setStopLoadButtonDisabledFlag
} from "./globalState";
import {
  Filter,
  FilterGroup,
  MetricAwareFilter,
  RegisteredFilters,
  StateMetricFilter
} from "../interfaces/filters/filter";
import {
  generateStateMetrics,
  setFrictionMetric, setStateMetricsData,
  setSummaryMetric, setTransitionStateMetricsData,
} from "./metricStateManager";
import {EvaluationProps, Metric} from "../interfaces/metrics/metric";
import {getFrictionMetric} from "../utils/frictionMetricUtils";
import {getFilterOptions, loadSkillSpecifications} from "../config/configs";
import {createFilters} from "../config/filters/filterConfig";


/**
 * Progress report on what's happening with the data currently.
 */
export type AnalyticsDataStatusType = 'INITIALIZING' | 'FINISHED' | 'LOADING' | 'LOADED' | 'ERROR' | 'FILTERING';

/**
 * Final data object that can be accessed by everyone who chooses to subscribe to updates.
 */
export interface AnalyticsData {
  readonly aggregatedViews: AggregatedView[];
  readonly aggregatedViewsToCompare: AggregatedView[];
  readonly filteredViews: AggregatedView[];
  readonly filteredViewsToCompare: AggregatedView[];
}

/**
 * Progress of data being loaded.
 */
export interface DataLoadProgress {
  readonly total: number;
  readonly loaded: number;
  readonly failed: number;
}

/**
 * Wrapper for overall data load progress, across both primary and comparison data sources.
 */
export interface OverallAnalyticsDataStatus {
  readonly statusType: AnalyticsDataStatusType;
  readonly errorMessage?: string;
  readonly primaryDataLoadProgress?: DataLoadProgress;
  readonly comparisonDataLoadProgress?: DataLoadProgress;
}

let data: AnalyticsData = {
  aggregatedViews: [],
  aggregatedViewsToCompare: [],
  filteredViewsToCompare: [],
  filteredViews: [],
};
let overallAnalyticsDataStatus: OverallAnalyticsDataStatus = {statusType: "INITIALIZING"};

let controller = new AbortController();
let signal = controller.signal;

const progressListeners = new Set<(x: OverallAnalyticsDataStatus) => void >();
const readyForRenderListeners = new Set<(x: AnalyticsData) => void>();

/**
 * Return current AnalyticsData object
 */
export function getAnalyticsData() {
  return data;
}

export function stopLoad() {
  controller.abort();
}
/**
 * Fetched data from s3 for specified dateRange and selectedRegions
 */
async function fetchData(dateRange: DateRange, selectedRegions: string[]): Promise<AggregatedView[]> {
  // prepare and load
  const prefixes: string[] = getPrefixList(dateRange.startDate!, dateRange.endDate!)
  console.log('loading prefixes', prefixes)

  const lambdaClients: { [region: string]: Lambda } = getLambdaClientsPerRegion(selectedRegions);
  const s3Clients: { [region: string]: S3 } = getS3ClientsPerRegion(selectedRegions);

  controller = new AbortController();
  signal = controller.signal;

  const promises = []
  const avs: AggregatedView[] = [];
  for (const prefix of prefixes) {
    for (const region of selectedRegions) {
      promises.push(getCachedAVForPrefix(prefix, region, lambdaClients[region], s3Clients[region], avs, signal))
    }
  }

  await Promise.all(promises)
  return avs;
}

export async function loadComparisonData() {
  removeAllNotifications()

  console.log('loadComparisonData: current progress state', overallAnalyticsDataStatus)

  let comparisonDateRange: DateRange = getComparisonDateRange();
  let selectedRegions = getSelectedRegions();

  setStopLoadButtonDisabledFlag(false)

  try {
    addNotificationItem({
      content: `Loading comparison data from ${comparisonDateRange.startDate} to ${comparisonDateRange.endDate}, from regions ${JSON.stringify(selectedRegions)}`,
      type: "info", loading: true, dismissible: false,
    })
    addNotificationItem({
      content: "Dashboard load times are higher due to high volume of data across multiple regions.",
      type: "warning", dismissible: true, id: "dashboardLoad", onDismiss: () => removeNotificationItem("dashboardLoad")
    })
    setLoadInProgress(true);
    setLoadButtonDisabledFlag(true);
    setComparisonLoadButtonDisabledFlag(true);
    setAnalyticsTabsLoadInProgress(true);
    setApplyFilterButtonDisabledFlag(true);

    let aggregatedViewsToCompare = await fetchData(comparisonDateRange, selectedRegions);

    data = {...data, aggregatedViewsToCompare: aggregatedViewsToCompare};

    // set state to loaded
    updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "LOADED"});
  } catch (e) {
    setNotificationItems([{
      content: `Failed to load comparison data from ${comparisonDateRange.startDate} to ${comparisonDateRange.endDate}, from regions ${JSON.stringify(selectedRegions)}. Cause: ${getErrorMessage(e)}`,
      type: "error", loading: false, dismissible: true, id: "loadFailed", onDismiss: () => removeNotificationItem("loadFailed")
    }]);

    setComparisonLoadButtonDisabledFlag(false);
    setLoadInProgress(false);
    // set state to error
    updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "ERROR", errorMessage: getErrorMessage(e)});
    throw e;
  } finally {
    console.log("Data loaded from s3")
    setStopLoadButtonDisabledFlag(true)
  }

  setNotificationItems([{
    content: `Applying filters and evaluating metrics....`,
    type: "info", loading: false, dismissible: false
  }]);

  // apply filters
  await __applyFilters(false, true);

  setLoadInProgress(false);

  setNotificationItems([{
    content: `Comparison data ready for ${comparisonDateRange.startDate} to ${comparisonDateRange.endDate}, from regions ${JSON.stringify(selectedRegions)}`,
    type: "success", loading: false, dismissible: true, id: "dataReady", onDismiss: () => removeNotificationItem("dataReady")
  }])
  // mark as done
  updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "FINISHED"});
  readyForRenderListeners.forEach(listener => listener(data));
}

/**
 * Loads new data.
 */
export async function loadData() {

  console.log('loadData: current progress state', overallAnalyticsDataStatus);

  //DO NOT REMOVE THIS LINE
  await loadSkillSpecifications()

  let primaryDateRange: DateRange = getPrimaryDateRange();
  let selectedRegions = getSelectedRegions();

  data = {aggregatedViews: [], aggregatedViewsToCompare: [], filteredViews: [], filteredViewsToCompare: []}

  setStopLoadButtonDisabledFlag(false)

  //Reset state filters
  setRegisteredFilters({metricAwareFilterGroups: {}, dropDownFilterGroups: {}})

  // first set everything to loading...
  updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "LOADING"});

  // load data
  try {
    // housekeeping
    removeAllNotifications();
    addNotificationItem({
      content: `Loading data from ${primaryDateRange.startDate} to ${primaryDateRange.endDate}, from regions ${JSON.stringify(selectedRegions)}`,
      type: "info", loading: true, dismissible: false,
    })
    addNotificationItem({
      content: "Dashboard load times are higher due to high volume of data across multiple regions.",
      type: "warning", dismissible: true, id: "dashboardLoad", onDismiss: () => removeNotificationItem("dashboardLoad")
    })
    setLoadInProgress(true);
    setLoadButtonDisabledFlag(true);

    let aggregatedViews = await fetchData(primaryDateRange, selectedRegions);

    data = {...data, aggregatedViews: aggregatedViews};

    setRegisteredFilters(createFilters(aggregatedViews, getFilterOptions()))

    // set state to loaded
    updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "LOADED"});
  } catch (e) {
    setNotificationItems([{
      content: `Failed to load data from ${primaryDateRange.startDate} to ${primaryDateRange.endDate}, from regions ${JSON.stringify(selectedRegions)}. Cause: ${getErrorMessage(e)}`,
      type: "error", loading: false, dismissible: true, id: "loadFailed", onDismiss: () => removeNotificationItem("loadFailed")
    }]);

    setLoadButtonDisabledFlag(false);

    setLoadInProgress(false);
    // set state to error
    updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "ERROR", errorMessage: getErrorMessage(e)});
    throw e;
  } finally {
    console.log("Data loaded from s3")
    setStopLoadButtonDisabledFlag(true)
  }

  // reset shouldEvaluateFilter & shouldEvaluateGroup to true
  resetShouldEvaluateFlag(true)

  // set state to filtering
  updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "FILTERING"});

  setNotificationItems([{
    content: `Applying filters and evaluating metrics....`,
    type: "info", loading: false, dismissible: false
  }]);

  // apply filters
  await __applyFilters( true, false);

  setLoadPageMetricsButtonDisabledFlag(false);
  setComparisonLoadButtonDisabledFlag(false);

  // housekeeping
  setNotificationItems([{
    content: `Ready for ${primaryDateRange.startDate} to ${primaryDateRange.endDate}, from regions ${JSON.stringify(selectedRegions)}`,
    type: "success", loading: false, dismissible: true, id: "dataReady", onDismiss: () => removeNotificationItem("dataReady")
  }])

  // mark as done
  updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "FINISHED"});
  readyForRenderListeners.forEach(listener => listener(data));
}

/**
 * Clear shouldEvaluateFlag and set FilterMode to IGNORE
 */
export async function clearFilters() {
  resetFilters()
  setApplyFilterButtonDisabledFlag(false);
}

/**
 * Apply filters.
 */
export async function applyFilters() {
  // set state to filtering
  updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "FILTERING"});

  setNotificationItems([{
    content: `Applying filters and evaluating metrics....`,
    type: "info", loading: false, dismissible: false
  }]);

  // apply filters
  await __applyFilters(true, true);

  setLoadPageMetricsButtonDisabledFlag(false);

  removeAllNotifications();

  // mark as done
  updateAnalyticsDataStatus({...overallAnalyticsDataStatus, statusType: "FINISHED"});
  readyForRenderListeners.forEach(listener => listener(data));
}

export async function loadPageMetrics() {
  setLoadPageMetricsButtonDisabledFlag(true);
  setAnalyticsTabsLoadInProgress(true);

  await new Promise(f => setTimeout(f, 1));

  updateRegisteredFiltersWithStateFilters(data.aggregatedViews, data.aggregatedViewsToCompare);

  setNotificationItems([{
    content: `Loading page metrics table and transition diagram....`,
    type: "info", loading: false, dismissible: false
  }])

  await __evaluatePageMetrics()
  await setStateMetricsData(generateStateMetrics(data.filteredViews))
  await setTransitionStateMetricsData(generateStateMetrics(data.filteredViews))

  setAnalyticsTabsLoadInProgress(false);

  removeAllNotifications();

  readyForRenderListeners.forEach(listener => listener(data));
}

async function __applyFilters(filterMainAvRequired: boolean, filterComparisonAvRequired: boolean) {
  setLoadInProgress(true);
  setApplyFilterButtonDisabledFlag(true);

  await new Promise(f => setTimeout(f, 1));

  console.log("total avs", data.aggregatedViews!.length);

  let filteredViews: AggregatedView[] = (filterMainAvRequired)? evaluateFilters(data.aggregatedViews, true) : data.filteredViews;

  console.log("filtered avs", filteredViews.length);

  console.log("total comparison avs", data.aggregatedViewsToCompare!.length);

  let filteredViewsToCompare: AggregatedView[] = (filterComparisonAvRequired)? evaluateFilters(data.aggregatedViewsToCompare, false): data.filteredViewsToCompare;

  console.log("filtered comparison avs", filteredViewsToCompare.length);

  data = {...data, filteredViews, filteredViewsToCompare};

  // reset shouldEvaluate flag to false as it is already evaluated
  resetShouldEvaluateFlag(false)

  // evaluate metrics for each filter except PageMetrics
  __evaluateMetrics()

  setAnalyticsTabsLoadInProgress(true)
  await setStateMetricsData([])
  await setTransitionStateMetricsData([])
  setAnalyticsTabsLoadInProgress(false)

  setNotificationItems([{
    content: `Loading friction metrics table ....`,
    type: "info", loading: false, dismissible: false
  }])

  setSummaryMetric(filteredViews, filteredViewsToCompare);
  setFrictionMetric(filteredViews, filteredViewsToCompare);

  removeAllNotifications();

  setLoadInProgress(false)
}


function updateAnalyticsDataStatus(newVal: OverallAnalyticsDataStatus) {
  overallAnalyticsDataStatus = newVal;
  console.log('loadData: current progress state', overallAnalyticsDataStatus)
  progressListeners.forEach(listener => listener(overallAnalyticsDataStatus));
}

/**
 * Subscribe for full progress report while the data is being updated.
 */
export function subscribeProgressReports(listener: (x: OverallAnalyticsDataStatus) => void) {
  progressListeners.add(listener);
}

/**
 * Subscribe from full progress report while the data is being updated.
 */
export function unsubscribeProgressReports(listener: (x: OverallAnalyticsDataStatus) => void) {
  progressListeners.delete(listener);
}

/**
 * Subscribe for a notify after the data is done being updated and is ready for prime time.
 */
export function subscribeForAnalyticsReadiness(listener: (x: AnalyticsData) => void) {
  readyForRenderListeners.add(listener);
  listener(data)
}

/**
 * Unsubscribe from data-update-done updates.
 */
export function unsubscribeForAnalyticsReadiness(listener: (x: AnalyticsData) => void) {
  readyForRenderListeners.delete(listener);
}

/**
 * Evaluates all the metrics in metricEvaluators list of a filter
 * @param filter
 * @param mainEvaluationProps
 * @param comparisonEvaluationProps
 * @param filteredViews
 * @param filteredViewsToCompare
 */
export function evaluateMetricsForFilter(filter: MetricAwareFilter,
                                         mainEvaluationProps: EvaluationProps,
                                         comparisonEvaluationProps: EvaluationProps,
                                         filteredViews: AggregatedView[],
                                         filteredViewsToCompare: AggregatedView[]): LineSeriesAndMetricData {

  const lineSeries: { title: string, type: string, data: { x: Date, y: number }[]}[] = [];
  const metricData: MetricData[] = [];

  for (let metricEvaluator of filter.metricEvaluators) {

    let filteredViewsMetric: Metric = metricEvaluator.evaluate(filteredViews, mainEvaluationProps);
    let comparisonViewsMetric: Metric = metricEvaluator.evaluate(filteredViewsToCompare, comparisonEvaluationProps);

    metricData.push({
      metricLabel: filteredViewsMetric.metricLabel,
      actualValue: filteredViewsMetric.metricValue,
      comparisonValue: comparisonViewsMetric.metricValue,
      dataTrend: getTrend(filteredViewsMetric.isUpwardTrendGood, filteredViewsMetric.metricValue, comparisonViewsMetric.metricValue),
    });

    lineSeries.push({
      title: `${filteredViewsMetric.metricLabel} - (${filteredViewsMetric.metricValue.toLocaleString(window.navigator.language)})`,
      type: 'line',
      data: (filteredViewsMetric.trend === undefined) ? [] : filteredViewsMetric.trend
    });

  }

  return {
    lineDataSeries: lineSeries,
    metricData: metricData,
  };
}

function __evaluateMetrics() {

  console.log("Started metric evaluation");

  let registeredFilters: RegisteredFilters = {...getRegisteredFilters()};

  for (let groupName of Object.keys(registeredFilters.metricAwareFilterGroups)) {

    let group: FilterGroup = registeredFilters.metricAwareFilterGroups[groupName];

    if (groupName === "StateFilterGroup") {
      continue;
    }

    for (let filterName of Object.keys(group.filters)) {

      let filter = group.filters[filterName] as MetricAwareFilter;

      let filteredViews = [];
      let filteredViewsToCompare = [];

      for (let av of data.filteredViews) {
        if (av.filterOutcomes[groupName] === undefined) {
          av.filterOutcomes[groupName] = {}
        }

        if (av.filterOutcomes[groupName][filterName] === undefined) {
          av.filterOutcomes[groupName][filterName] = false;
        }

        if (av.filterOutcomes[groupName][filterName]) {
          filteredViews.push(av);
        }
      }

      for (let av of data.filteredViewsToCompare) {
        if (av.filterOutcomes[groupName] === undefined) {
          av.filterOutcomes[groupName] = {}
        }

        if (av.filterOutcomes[groupName][filterName] === undefined) {
          av.filterOutcomes[groupName][filterName] = false;
        }

        if (av.filterOutcomes[groupName][filterName]) {
          filteredViewsToCompare.push(av);
        }
      }

      filter.lineSeriesAndMetricData = evaluateMetricsForFilter(
          filter,
          { period: MILLIS_IN_DAY },
          { period: MILLIS_IN_DAY, isTrendRequired: false },
          filteredViews, filteredViewsToCompare);

      if (group.groupName === "StateFilterGroup") {
        let frictionMetricData: {[x:string]: MetricAwareFilter[]}= {}
        let frictionMetrics: FrictionMetrics[] = getFrictionMetric(filteredViews, filteredViewsToCompare)
        frictionMetrics.forEach(frictionMetric => {
          frictionMetricData[frictionMetric.label] = frictionMetric.filters
        })
        filter.lineSeriesAndMetricData.frictionMetricData = frictionMetricData
      }
    }
  }

  console.log("Finished metric evaluation");
}

function __evaluatePageMetrics() {

  console.log("Started page metric evaluation");

  let registeredFilters: RegisteredFilters = {...getRegisteredFilters()};

  let groupName = "StateFilterGroup";

  let group: FilterGroup = registeredFilters.metricAwareFilterGroups[groupName];

  for (let filterName of Object.keys(group.filters)) {

    let filter = group.filters[filterName] as StateMetricFilter;

    let filteredViews = [];
    let filteredViewsToCompare = [];

    for (let av of data.filteredViews) {
      if (av.filterOutcomes[groupName][filterName]) {
        filteredViews.push(av);
      }
    }

    for (let av of data.filteredViewsToCompare) {
      if (av.filterOutcomes[groupName][filterName]) {
        filteredViewsToCompare.push(av);
      }
    }

    filter.lineSeriesAndMetricData = evaluateMetricsForFilter(
        filter,
        { period: MILLIS_IN_DAY },
        { period: MILLIS_IN_DAY, isTrendRequired: false },
        filteredViews, filteredViewsToCompare);

    let frictionMetricData: {[x:string]: MetricAwareFilter[]}= {}
    let frictionMetrics: FrictionMetrics[] = getFrictionMetric(filteredViews, filteredViewsToCompare)
    frictionMetrics.forEach(frictionMetric => {
      frictionMetricData[frictionMetric.label] = frictionMetric.filters
    })
    filter.lineSeriesAndMetricData.frictionMetricData = frictionMetricData
  }

  console.log("Finished page metric evaluation");
}

function evaluateSingleFilterGroup(av: AggregatedView, filterGroup: FilterGroup, isMainAv: boolean): boolean {
  let temp;
  if (filterGroup.shouldEvaluateFilterGroup || av.filterGroupOutcomes[filterGroup.groupName] === undefined) {
    temp = filterGroup.evaluate(filterGroup.filters, av);
    if (filterGroup.groupName !== "StateFilterGroup") {
      av.filterGroupOutcomes[filterGroup.groupName] = temp;
    }
  } else {
    temp = av.filterGroupOutcomes[filterGroup.groupName];
  }
  return temp
}

/**
 * Evaluates filter group to true/false
 * @param av
 * @param filterGroups
 * @param isMainAv
 */
function evaluateFilterGroups(av: AggregatedView, filterGroups: { [group: string] : FilterGroup }, isMainAv: boolean): boolean {

  let matches = true;
  for (let groupName of Object.keys(filterGroups)) {
    let group: FilterGroup = filterGroups[groupName];

    if (group.excludeFromDataFiltering) {
      continue;
    }
    let temp = evaluateSingleFilterGroup(av, group, isMainAv)

    matches = matches && temp
  }
  return matches;
}

/**
 * Evaluates a filter to true/false
 * @param avs
 * @param isMainAv
 */
function evaluateFilters(avs: AggregatedView[], isMainAv: boolean) {
  let filteredViews: AggregatedView[] = [];

  const filtersToEvaluate: RegisteredFilters = getRegisteredFilters();

  for (let av of avs) {

    // evaluate metric aware filters first
    let metricAwareFilterResults = evaluateFilterGroups(av, filtersToEvaluate.metricAwareFilterGroups, isMainAv);

    // now evaluate dropDown filters
    let dropDownFilterResults = evaluateFilterGroups(av, filtersToEvaluate.dropDownFilterGroups, isMainAv);

    if (metricAwareFilterResults && dropDownFilterResults) filteredViews.push(av)
  }

  return filteredViews
}

/**
 * Filters the avs list by executing evaluate() method of filter on each av.
 * @param filter
 * @param avs
 */
export function evaluateFilterForAggregatedViews(filter: Filter, avs: AggregatedView[]): AggregatedView[] {
  let filteredViews: AggregatedView[] = [];

  avs.forEach(av => {
    if (filter.executorFunction.evaluate(av, filter.currentState)) {
      filteredViews.push(av);
    }
  });

  return filteredViews;
}
