import {
  PageMetrics,
  Transition,
  TransitionMetrics
} from "../interfaces";
import {
  getStateKey,
  getStateNameForDisplay,
  getTransitionKey,
  getTransitionNameForDisplay, getTrend,
} from "../common/utils";
import {MetricAwareFilter, StateMetricFilter} from "../interfaces/filters/filter";

export function convertMetricAwareFilterToPageMetrics(metricAwareFilters: MetricAwareFilter[]): PageMetrics[] {
  let pageMetrics: PageMetrics[] = [];

  metricAwareFilters
      .forEach(metricAwareFilter => {
        let result: PageMetrics = {
          "": metricAwareFilter.filterName
        }
        metricAwareFilter.lineSeriesAndMetricData?.metricData
            .forEach(data => {
              result[data.metricLabel] = data;
            })

        pageMetrics.push(result)
      })

  return pageMetrics
}

export function convertStateMetricsToPageMetrics(stateMetrics: StateMetricFilter[], showPageTags: boolean, shouldAddFrictionMetrics: boolean): PageMetrics[] {

  let pageMetrics: PageMetrics[] = [];

  stateMetrics
      .forEach(filter => {
        let stateMetricFilter: StateMetricFilter = filter;
        let result: PageMetrics = {
          "id": stateMetricFilter.currentState.value,
          "Page": stateMetricFilter.filterName,
          "Page Tag": showPageTags? stateMetricFilter.currentState.label: "",
          "Outgoing Transitions": stateMetricFilter.outgoingTransitions,
        }

          stateMetricFilter.lineSeriesAndMetricData?.metricData
            .forEach(data => {
              result[data.metricLabel] = data;
            })

        if (shouldAddFrictionMetrics) {
          result["Friction Metrics"] = stateMetricFilter.lineSeriesAndMetricData?.frictionMetricData
        }

        pageMetrics.push(result)
      })

  return pageMetrics;

}

export function initializeTransitionMetric(transition: Transition, showTags: boolean, showTransitionTags: boolean): TransitionMetrics {
  return {
    id: getTransitionKey(transition, showTags, showTransitionTags),
    originId: getStateKey(transition.origin, showTags),
    destinationId: getStateKey(transition.destination, showTags),
    originDisplay: getStateNameForDisplay(transition.origin, showTags),
    destinationDisplay: getStateNameForDisplay(transition.destination, showTags),
    transitionDisplay: getTransitionNameForDisplay(transition, showTransitionTags),
    transitionTag: transition.transitionTag,
    transitionReason: transition.reason,
    count: 0,
    customers: 0
  }
}
