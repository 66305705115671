import * as React from 'react';
import {useEffect, useState} from 'react';
import {Multiselect, MultiselectProps} from '@awsui/components-react';
import {setApplyFilterButtonDisabledFlag, subscribeLoadInProgressChanges} from "../../../../data/globalState";
import {FilterGroup} from "../../../../interfaces/filters/filter";
import {
    setDropDownFilterOption,
    setFilterMode,
    subscribeDropDownFilterChanges,
} from "../../../../data/filterStateManager";

/**
 * A basic multi-select filter.
 */
export function BasicMultiSelectFilter(props: {
    filterGroup: FilterGroup
}) {

    const [selectedOptions, setSelectedOptions] = useState<{label: string, value: string}[]>([]);
    const [loadInProgress, setLoadInProgress] = useState<boolean>();

    useEffect(() => {
        subscribeLoadInProgressChanges(setLoadInProgress);
    }, [setLoadInProgress]);

    useEffect(() => {
        subscribeDropDownFilterChanges(setSelectedOptions, props.filterGroup);
    }, [setSelectedOptions, props.filterGroup])

    useEffect(() => {
        console.log('rendered the multi select filter group ' + props.filterGroup.groupName);
    });

    return (
        <Multiselect
            disabled={loadInProgress}
            selectedOptions={selectedOptions as MultiselectProps.Options}
            onChange={({detail}) => {
                setDropDownFilterOption(detail.selectedOptions as {label: string, value: string}[], props.filterGroup);

                let selectedValues = (detail.selectedOptions as { label: string, value: string }[])
                    .map(option => option.value)

                for (let option of props.filterGroup.options) {
                    if (selectedValues.indexOf(option.value) >= 0) {
                        setFilterMode("DropdownFilter", option.label, props.filterGroup.groupName, "INCLUDE")
                    } else {
                        setFilterMode("DropdownFilter", option.label, props.filterGroup.groupName, "IGNORE")
                    }
                }
                setApplyFilterButtonDisabledFlag(false);
            }}
            deselectAriaLabel={e => `Remove ${e.label}`}
            options={props.filterGroup.options as MultiselectProps.Options}
            filteringType="auto"
            placeholder={props.filterGroup.placeholder}
            selectedAriaLabel="Selected"
        />
    );
}
