import {AggregatedView} from "../../index";
import {Metric, MetricEvaluator, MetricEvaluatorProps} from "../metric";

export interface StateTagCountMetricProps extends MetricEvaluatorProps {
    stateTag: string
}

/**
 * This evaluates total count of given {@link stateTag} in the av list
 */
export class StateTagCountMetric extends MetricEvaluator {

    stateTag: string;

    constructor(props: StateTagCountMetricProps) {
        super(props);
        this.stateTag = props.stateTag;
    }

    evaluate(aggregatedViews: AggregatedView[]): Metric {

        let seriesStatValue: number = 0;
        aggregatedViews.forEach(av => {
            for (let state of av.states) {
                if (state.tag !== undefined) {
                    if (state.tag === this.stateTag) {
                        seriesStatValue += 1
                    }
                }
            }
        })

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue,
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
