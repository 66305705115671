import * as React from "react";
import {useEffect, useState} from "react";
import {
  DateRange,
  setComparisonRange,
  subscribeComparisonDateRangeChanges,
  subscribeComparisonSuggestions,
} from "../../../data/dataSelectState";
import {Header, SpaceBetween} from "@awsui/components-react";
import {subscribeLoadInProgressChanges} from "../../../data/globalState";
import DateRangePicker, {DateRangePickerProps} from "@awsui/components-react/date-range-picker";
import AbsoluteValue = DateRangePickerProps.AbsoluteValue;
import {isValidRangeFunction} from "../../../common/utils";
import Link from "@awsui/components-react/link";


export function DateComparisonSelector() {
  const [value, setValue] = React.useState<DateRange>();
  const [suggestions, setSuggestions] = React.useState<DateRange[]>();
  const [loadInProgress, setLoadInProgress] = useState<boolean>(true);

  const rangeToString = (range: DateRange) => {
    return [range.startDate, range.endDate].join(' - ')
  }

  const getOptions = () => {
    const options: any = []
    suggestions?.forEach(s => options.push({label: rangeToString(s), value: s}));
    return options;
  }

  useEffect(() => {
    console.log("rendering date comparison selector")
    subscribeComparisonSuggestions(setSuggestions)
  }, [setSuggestions]);

  useEffect(() => {
    console.log("rendering date comparison selector")
    subscribeComparisonDateRangeChanges(setValue)
    subscribeLoadInProgressChanges(setLoadInProgress);
  }, [setValue]);

  return (
      <SpaceBetween size={'xxs'} direction={'vertical'}>
        <Header variant={"h3"}>Comparison period </Header>
        <DateRangePicker
            onChange={({detail}) => {
              setComparisonRange((detail.value as AbsoluteValue).startDate, (detail.value as AbsoluteValue).endDate)
            }}
            value={typeof value === 'undefined' ? null : {
              startDate: value.startDate!,
              endDate: value.endDate!,
              type: 'absolute'
            }}
            isValidRange={isValidRangeFunction as DateRangePickerProps.ValidationFunction}
            i18nStrings={{
              todayAriaLabel: "Today",
              nextMonthAriaLabel: "Next month",
              previousMonthAriaLabel: "Previous month",
              customRelativeRangeDurationLabel: "Duration",
              customRelativeRangeDurationPlaceholder:
                  "Enter duration",
              customRelativeRangeOptionLabel: "Custom range",
              customRelativeRangeOptionDescription:
                  "Set a custom range in the past",
              customRelativeRangeUnitLabel: "Unit of time",
              formatRelativeRange: e => {
                const t =
                    1 === e.amount ? e.unit : `${e.unit}s`;
                return `Last ${e.amount} ${t}`;
              },
              formatUnit: (e, t) => (1 === t ? e : `${e}s`),
              dateTimeConstraintText:
                  "Range is 1 to 31 days. For date, use YYYY/MM/DD.",
              relativeModeTitle: "Relative range",
              absoluteModeTitle: "Absolute range",
              relativeRangeSelectionHeading: "Choose a range",
              startDateLabel: "Start date",
              endDateLabel: "End date",
              startTimeLabel: "Start time",
              endTimeLabel: "End time",
              clearButtonLabel: "Clear and dismiss",
              cancelButtonLabel: "Cancel",
              applyButtonLabel: "Apply"
            }}
            locale={window.navigator.language}
            disabled={loadInProgress}
            dateOnly
            showClearButton={true}
            placeholder="Select range for comparison"
            rangeSelectorMode="absolute-only"
            relativeOptions={[]}/>
        <SpaceBetween size={'s'} direction={'horizontal'}>
          {getOptions().map((option: any, index: number) => {
            return (
                <Link variant={'info'} key={option.label + index} onFollow={() => {
                  if (loadInProgress) return;
                  setComparisonRange(option.value.startDate, option.value.endDate);
                }}>{option.label}</Link>
            )
          })}
        </SpaceBetween>
      </SpaceBetween>
  );
}
