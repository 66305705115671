import {
    Filter,
    FilterGroup,
    FilterMode,
    FilterType,
    MetricAwareFilter,
    RegisteredFilters
} from "../interfaces/filters/filter";
import {AggregatedView} from "../interfaces";
import {getStateMetricFilters} from "../config/filters/filterConfig";


let registeredFilters: RegisteredFilters = {
    dropDownFilterGroups: {},
    metricAwareFilterGroups: {},
}

let dropDownOption: {label: string, value: string}[] = []

// listeners subscribed for updates to registered filters
const registeredFiltersListeners = new Set<(registeredFilters: RegisteredFilters) => void>();

// listeners subscribed for updates to filter modes
const filterModeListeners = new Map<Filter, (mode: FilterMode) => void>();

// listeners subscribed for updates to drop down filters
const dropDownFilterChangesListeners = new Map<FilterGroup, (mode: {label: string, value: string}[]) => void>();

/**
 * Updates RegisteredFilters with new StateMetricFilters
 * @param avs
 * @param avsToCompare
 */
export function updateRegisteredFiltersWithStateFilters(avs: AggregatedView[], avsToCompare: AggregatedView[]) {
    if (registeredFilters.metricAwareFilterGroups["StateFilterGroup"] === undefined) {
        registeredFilters.metricAwareFilterGroups["StateFilterGroup"] = getStateMetricFilters(avs, avsToCompare);
        setRegisteredFilters(registeredFilters)
    }
}

/**
 * Get registeredFilters
 */
export function getRegisteredFilters(): RegisteredFilters {
    return registeredFilters
}

/**
 * Returns MetricAwareFilter belonging to filter group
 * @param filterName
 * @param filterGroup
 */
export function getMetricAwareFilter(filterName: string, filterGroup: string): MetricAwareFilter {
    return (registeredFilters
        .metricAwareFilterGroups[filterGroup]!
        .filters[filterName]! as MetricAwareFilter)
}

/**
 * Returns MetricAwareFilterGroup
 * @param filterGroup
 */
export function getMetricAwareFilterGroup(filterGroup: string): FilterGroup {
    return registeredFilters
        .metricAwareFilterGroups[filterGroup]!
}

/**
 * Returns DropdownFilter belonging to filter group
 * @param filterName
 * @param filterGroup
 */
export function getDropdownFilter(filterName: string, filterGroup: string): Filter {
    return registeredFilters
        .dropDownFilterGroups[filterGroup]!
        .filters[filterName]!
}

/**
 * Returns DropdownFilterGroup
 * @param filterGroup
 */
export function getDropdownFilterGroup(filterGroup: string): FilterGroup {
    return registeredFilters
        .dropDownFilterGroups[filterGroup]!
}

/**
 * Returns a filter of specified filterType
 * @param filterType
 * @param filterName
 * @param filterGroup
 */
export function getFilter(filterType: FilterType, filterName: string, filterGroup: string): Filter {
    if (filterType === "MetricAwareFilter") {
        return getMetricAwareFilter(filterName, filterGroup);
    }
    return getDropdownFilter(filterName, filterGroup);
}

/**
 * Returns a filterGroup of specified filterType
 * @param filterType
 * @param filterGroup
 */
export function getFilterGroup(filterType: FilterType, filterGroup: string): FilterGroup {
    if (filterType === "MetricAwareFilter") {
        return getMetricAwareFilterGroup(filterGroup);
    }
    return getDropdownFilterGroup(filterGroup);
}

/**
 * Sets filter mode
 * @param filterType
 * @param filterName
 * @param filterGroup
 * @param mode
 */
export function setFilterMode(filterType: FilterType, filterName: string, filterGroup: string, mode: FilterMode) {
    let filter: Filter = getFilter(filterType, filterName, filterGroup);
    let group: FilterGroup = getFilterGroup(filterType, filterGroup);
    filter.mode = mode;
    group.shouldEvaluateFilterGroup = true
    // notify filter
    if (filterModeListeners.get(filter) !== undefined) {
        filterModeListeners.get(filter)!(mode);
    }
}

/**
 * Set drop down filter option
 * @param option
 * @param filterGroup
 */
export function setDropDownFilterOption(option: {label: string, value: string}[], filterGroup: FilterGroup) {
    dropDownOption = option;

    // notify filter
    if (dropDownFilterChangesListeners.get(filterGroup) !== undefined) {
        dropDownFilterChangesListeners.get(filterGroup)!(dropDownOption);
    }
}

/**
 * Resets shouldEvaluateFilterGroup & shouldEvaluateFilter to value
 * @param value
 */
export function resetShouldEvaluateFlag(value: boolean) {
    Object.keys(registeredFilters.metricAwareFilterGroups)
        .forEach(groupName => {
            Object.keys(registeredFilters.metricAwareFilterGroups[groupName].filters)
                .forEach(filterName => {
                    registeredFilters.metricAwareFilterGroups[groupName].filters[filterName].shouldEvaluateFilter = value;
                });
            registeredFilters.metricAwareFilterGroups[groupName].shouldEvaluateFilterGroup = value
        })

    Object.keys(registeredFilters.dropDownFilterGroups)
        .forEach(groupName => {
            Object.keys(registeredFilters.dropDownFilterGroups[groupName].filters)
                .forEach(filterName => {
                    registeredFilters.dropDownFilterGroups[groupName].filters[filterName].shouldEvaluateFilter = value;
                });
            registeredFilters.dropDownFilterGroups[groupName].shouldEvaluateFilterGroup = value
        })
}

/**
 * Sets shouldEvaluateFlag to false and filterMode to IGNORE
 */
export function resetFilters() {
    resetShouldEvaluateFlag(false);
    Object.keys(registeredFilters.metricAwareFilterGroups)
        .forEach(groupName => {
            Object.keys(registeredFilters.metricAwareFilterGroups[groupName].filters)
                .forEach(filterName => {
                    setFilterMode("MetricAwareFilter", filterName, groupName, "IGNORE")
                });
        })

    Object.keys(registeredFilters.dropDownFilterGroups)
        .forEach(groupName => {
            Object.keys(registeredFilters.dropDownFilterGroups[groupName].filters)
                .forEach(filterName => {
                    setFilterMode("DropdownFilter", filterName, groupName, "IGNORE")
                    setDropDownFilterOption([], registeredFilters.dropDownFilterGroups[groupName])
                });
        })
}

/**
 * Set registered filter
 */
export function setRegisteredFilters(newRegisteredFilters: RegisteredFilters) {
    console.log('setRegisteredFilters: current registeredFilters: ', registeredFilters);

    if (registeredFilters === newRegisteredFilters) {
        console.log('setRegisteredFilters: No change identified for registeredFilters - skipping executing listeners')
        return;
    }

    registeredFilters = newRegisteredFilters;

    console.log('setRegisteredFilters: updated registeredFilters: ', registeredFilters)

    // notify everyone
    registeredFiltersListeners.forEach(listener => listener(registeredFilters));
}

/**
 * Subscribe to get notified whenever 'registeredFilters' state changes
 * @param listener
 */
export function subscribeRegisteredFiltersChanges(listener: (registeredFilters: RegisteredFilters) => void) {
    registeredFiltersListeners.add(listener);
    listener(registeredFilters);
}

/**
 * Subscribe to get notified whenever 'registeredFilters' state changes
 * @param listener
 * @param filter
 */
export function subscribeFilterModeChanges(listener: (mode: FilterMode) => void, filter: Filter) {
    filterModeListeners.set(filter, listener);
    listener(filter.mode);
}

/**
 * Dubscribe to changes in the drop down filter
 * @param listener
 * @param filterGroup
 */
export function subscribeDropDownFilterChanges(listener: (mode: { label: string, value: string}[]) => void, filterGroup: FilterGroup) {
    dropDownFilterChangesListeners.set(filterGroup, listener);
    listener([]);
}