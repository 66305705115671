import {useEffect, useState} from "react";
import {
    convertDateToReadable,
    MILLIS_IN_MINUTES,
    MILLIS_IN_FIVE_MINUTES,
    MILLIS_IN_FIFTEEN_MINUTES,
    MILLIS_IN_HOUR,
    MILLIS_IN_THREE_HOURS,
    MILLIS_IN_DAY, getScaledValues
} from "../../../../common/utils";
import {
    setGlobalGraphModalProps,
    subscribeGraphModalPropsChanges,
    subscribeLoadInProgressChanges
} from "../../../../data/globalState";
import * as React from "react";
import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    LineChart,
    MixedLineBarChartProps,
    Modal,
    SpaceBetween
} from "@awsui/components-react";
import {MetricAwareFilter} from "../../../../interfaces/filters/filter";
import {AggregatedView, LineSeriesAndMetricData} from "../../../../interfaces";
import {evaluateMetricsForFilter} from "../../../../data/dataManager";
import LineDataSeries = MixedLineBarChartProps.LineDataSeries;

export interface GraphModalProps {
    additionalInfo: string | undefined,
    aggregatedViews: AggregatedView[],
    lineSeriesData: { title: string, type: string, data: { x: Date, y: number }[]}[],
    title: string,
    visible: boolean,
    metricsAwareFilter: MetricAwareFilter | undefined,
    yFormatter: ((number: number) => string) | undefined;
}


export function GraphModal() {
    const [graphModalProps, setGraphModalProps] = useState<GraphModalProps>({
        additionalInfo: undefined,
        aggregatedViews: [],
        lineSeriesData: [],
        title: "",
        visible: false,
        yFormatter: undefined,
        metricsAwareFilter: undefined,
    });
    const [loadInProgress, setLoadInProgress] = useState<boolean>();
    const [windowSize, setWindowSize] = useState<number>(MILLIS_IN_DAY);

    useEffect(() => {
        subscribeLoadInProgressChanges(setLoadInProgress);
    }, [setLoadInProgress]);

    useEffect(() => {
        subscribeGraphModalPropsChanges(setGraphModalProps);
    }, [setGraphModalProps]);

    function onAggregationWindowChange(period: number) {
        setWindowSize(period);
        updateLineSeriesData(period);
    }

    function updateLineSeriesData(period: any) {
        let lineSeriesAndMetricData: LineSeriesAndMetricData = evaluateMetricsForFilter(graphModalProps.metricsAwareFilter!,
            { period: period? period: windowSize, stat: "sum" },
            { period: period? period: windowSize, isTrendRequired: false },
            graphModalProps.aggregatedViews,
            []);
        setGraphModalProps({...graphModalProps, lineSeriesData: lineSeriesAndMetricData.lineDataSeries});
    }

    function getPeriodLabel(): string {
        switch (windowSize) {
            case MILLIS_IN_MINUTES: return "1m";
            case MILLIS_IN_FIVE_MINUTES: return "5m";
            case MILLIS_IN_FIFTEEN_MINUTES: return "15m";
            case MILLIS_IN_HOUR: return "1h";
            case MILLIS_IN_THREE_HOURS: return "3h";
            default: return "1d";
        }
    }

    return (
        <Modal
            onDismiss={() => {
                setGlobalGraphModalProps({...graphModalProps, visible: false })
                setWindowSize(MILLIS_IN_DAY)
            }}
            visible={graphModalProps.visible}
            closeAriaLabel="Close modal"
            header={graphModalProps.title}
            size={"large"}
        >
            <>
                <Header actions={
                    <SpaceBetween size={"xxs"} direction={"horizontal"}>
                        <div key={"first-dropdown"}>
                            Aggregation Window:
                            <ButtonDropdown
                                items={[
                                    {text: "1m", id: `${MILLIS_IN_MINUTES}`},
                                    {text: "5m", id: `${MILLIS_IN_FIVE_MINUTES}`},
                                    {text: "15m", id: `${MILLIS_IN_FIFTEEN_MINUTES}`},
                                    {text: "1h", id: `${MILLIS_IN_HOUR}`},
                                    {text: "3h", id: `${MILLIS_IN_THREE_HOURS}`},
                                    {text: "1d", id: `${MILLIS_IN_DAY}`},

                                ]}
                                onItemClick={(event) => {
                                    onAggregationWindowChange(+event.detail.id);
                                }}
                            >
                                {getPeriodLabel()}
                            </ButtonDropdown>
                        </div>
                    </SpaceBetween>
                }/>
                <LineChart
                    series={getScaledValues(graphModalProps.lineSeriesData) as LineDataSeries<any>[]}
                    i18nStrings={{
                        filterLabel: 'Filter displayed data',
                        filterPlaceholder: 'Filter data',
                        filterSelectedAriaLabel: 'selected',
                        legendAriaLabel: 'Legend',
                        chartAriaRoleDescription: 'line chart',
                        xTickFormatter: e => convertDateToReadable(e),
                        yTickFormatter: function o(e) {
                            return "";
                        }
                    }}
                    ariaLabel="Line chart"
                    errorText="Error loading data."
                    height={500}
                    statusType={loadInProgress ? 'loading' : 'finished'}
                    loadingText="Loading chart"
                    recoveryText="Retry"
                    xScaleType="time"
                    xTitle="Time (UTC)"
                    hideFilter={true}
                    yScaleType="linear"
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No data available</b>
                            <Box variant="p" color="inherit">
                                There is no data available
                            </Box>
                        </Box>
                    }
                    noMatch={
                        <Box textAlign="center" color="inherit">
                            <b>No matching data</b>
                            <Box variant="p" color="inherit">
                                There is no matching data to display
                            </Box>
                            <Button>Clear filter</Button>
                        </Box>
                    }
                />
            </>
        </Modal>
    )
}
