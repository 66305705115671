import {AggregatedView} from "../../index";
import {FilterEvaluator, FilterEvaluatorProps} from "../filter";
import {getMetadataValues} from "../../../common/stringMetadata";

/**
 * This filter evaluates if list of values is empty.
 *
 * Calls getDistinctValues() which returns all the distinct values of a metadata
 * and returns true if the list is non-empty.
 */
export class DoesValueExistFilter extends FilterEvaluator {

    constructor(props: FilterEvaluatorProps) {
        super(props);
    }

    evaluate(av: AggregatedView, currentState: { label: string, value: string | string[] }): boolean {
        if (currentState === undefined || currentState.value === undefined) return true;
        if (typeof currentState.value === "string") {
            currentState.value = [currentState.value]
        }
        const values = getMetadataValues(this.mDesc, av, this.fallbackMetadata);
        if (currentState.value.indexOf("true") >= 0 && typeof values !== 'undefined' && values.length > 0) return true;
        return currentState.value.indexOf("false") >=0 && (typeof values === 'undefined' || values.length == 0);
    }

}
