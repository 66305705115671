import {MetricEvaluator, StateMetricEvaluatorProps} from "../metric";
import {AggregatedView} from "../../index";
import {getStateKey} from "../../../common/utils";

/**
 * Props for StateTransitionCountMetric
 * transitions: this is used to retrieve the executionTime of specified state in an aggregatedView object
 */
export interface StateTransitionCountMetricProps extends StateMetricEvaluatorProps {
    transitions: string[]
}

/**
 * Calculates the number of states transitioned through all the states present in the {@link transitions} list
 * in the exact sequence as in {@link transitions} list
 */
export class StateTransitionCountMetric extends MetricEvaluator {

    stateKey: string;
    transitions: string[]
    showTags: boolean

    constructor(props: StateTransitionCountMetricProps) {
        super(props);
        this.stateKey = props.stateKey;
        this.transitions = props.transitions;
        this.showTags = props.showTags;
    }

    evaluate(aggregatedViews: AggregatedView[]) {

        let uniqueAggregatedViews: AggregatedView[] = Array.from(new Set<AggregatedView>(aggregatedViews))

        let seriesStatValue = 0;

        for (let av of uniqueAggregatedViews) {
            let states = av.states.slice();
            let count = states.length;

            for (let ct = 0; ct < count; ct++) {
                if (getStateKey(states[ct], this.showTags)=== this.stateKey) {
                    if ((ct + this.transitions.length) < count) {
                        let temp = true;
                        for (let j = 0; j < this.transitions.length; j++) {
                            if (states[ct + j + 1].name !== this.transitions[j]) {
                                temp = false;
                                break;
                            }
                        }
                        if (temp) {
                            seriesStatValue += 1;
                        }
                    }
                    else {
                        break;
                    }
                }
            }
        }

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue,
            isUpwardTrendGood: this.isUpwardTrendGood
        }
    }
}
