import {useCollection} from "@awsui/collection-hooks";
import {
    Button,
    Checkbox,
    Header,
    Pagination,
    Popover,
    Table,
    TextFilter
} from "@awsui/components-react";
import {getComparisonDisplayValue, numberWithFraction} from "../../../../common/utils";
import {
    PageMetrics,
    TransitionAnalysisMode,
    TransitionMetrics
} from "../../../../interfaces";
import React, {useEffect, useState} from "react";
import {
    subscribeLoadInProgressChanges
} from "../../../../data/globalState";
import {
    setGlobalShowPageTags,
    setGlobalShowTransitionTags,
    subscribeGlobalShowPageTagsChanges, subscribeGlobalShowTransitionTagsChanges,
    subscribeGlobalTransitionAnalysisModeChanges,
    subscribeStateMetricsChanges
} from "../../../../data/metricStateManager";
import {convertStateMetricsToPageMetrics} from "../../../../utils/pageMetricsUtils";
import {StateMetricFilter} from "../../../../interfaces/filters/filter";

export function PageMetricsTable() {

    const [stateMetrics, setStateMetrics] = useState<StateMetricFilter[]>([]);
    const [showPageTags, setShowPageTags] = useState<boolean>(false);
    const [showTransitionTags, setShowTransitionTags] = useState<boolean>(true);
    const [transitionAnalysisMode, setTransitionAnalysisMode] = useState<TransitionAnalysisMode>("customers");
    const [loadInProgress, setLoadInProgress] = useState<boolean>();


    useEffect(() => {
        subscribeGlobalShowPageTagsChanges(setShowPageTags)
    }, [setShowPageTags])

    useEffect(() => {
        subscribeGlobalShowTransitionTagsChanges(setShowTransitionTags)
    }, [setShowTransitionTags])

    useEffect(() => {
        subscribeLoadInProgressChanges(setLoadInProgress);
    }, [setLoadInProgress]);

    useEffect(() => {
        subscribeStateMetricsChanges(setStateMetrics)
    }, [setStateMetrics])

    useEffect(() => {
        subscribeGlobalTransitionAnalysisModeChanges(setTransitionAnalysisMode)
    }, [setTransitionAnalysisMode])

    let pageMetrics: PageMetrics[] = convertStateMetricsToPageMetrics(stateMetrics, showPageTags, false);

    pageMetrics.sort((a: any, b: any) => b["Visits"].actualValue - a["Visits"].actualValue)
    pageMetrics.forEach((p: any) => p["Outgoing Transitions"].sort((a: TransitionMetrics, b: TransitionMetrics) => {
                if (transitionAnalysisMode === 'customers') return b.customers - a.customers;
                else return b.count - a.count;
            }
        )
    )

    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, propertyFilterProps} = useCollection(
        pageMetrics,
        {
            filtering: {},
            sorting: {},
            pagination: {pageSize: 30}
        }
    );

    function getCellValue(item: any, key: string) {
        if (item.dataTrend === undefined) {
            return item
        }
        return (
            <>{getComparisonDisplayValue(item, key)}</>
        )

    }

    function getAdditionalColumns() {
        let result = []

        result.push({
            header: 'Outgoing pages',
            cell: (item: any) => {
                if (item["Outgoing Transitions"].length === 0) return "-";
                return (
                    <Popover fixedWidth position={'top'} dismissButton={true} triggerType={'text'} size={'large'}
                             content={
                                 <Table items={item["Outgoing Transitions"]!} columnDefinitions={[{
                                     id: 'Page',
                                     header: 'Page',
                                     cell: (itemInside: TransitionMetrics) => getTransitionMetricDescription(item, itemInside, transitionAnalysisMode)
                                 }, {
                                     id: 'Transition',
                                     header: 'Transition',
                                     cell: (itemInside: TransitionMetrics) => itemInside.transitionDisplay
                                 }]}/>
                             }>{getInlineTransitionMetricDescription(item, item["Outgoing Transitions"][0], transitionAnalysisMode)}
                    </Popover>);
            }
        })

        return result
    }

    return (
        <>
            <Header
                variant="h2"
                actions={
                    <>
                        <Button variant={"normal"}>
                            <Checkbox checked={showPageTags} onChange={e => setGlobalShowPageTags(e.detail.checked)}>Page tags</Checkbox>
                        </Button>
                        <Button variant={"normal"}>
                            <Checkbox checked={showTransitionTags} onChange={e => setGlobalShowTransitionTags(e.detail.checked)}>Transition tags</Checkbox>
                        </Button>
                    </>
                }
            />
            <Table
                loading={loadInProgress}
                {...collectionProps}
                columnDefinitions={(pageMetrics.length > 0)? [...Object.keys(pageMetrics[0])
                    .filter(key => (key !== "id" && key !== "Outgoing Transitions" && key !== "Friction Metrics"))
                    .map(key => {
                        return {
                            id: key,
                            header: key,
                            cell: (item: any) => getCellValue(item[key], key),
                            sortingField: key,
                            width: 150
                        }
                    }), ...getAdditionalColumns()]: []}
                items={items}
                selectionType="single"
                variant="full-page"
                stickyHeader={true}
                resizableColumns={true}
                wrapLines={true}
                filter={
                    <TextFilter
                        {...filterProps}
                        filteringAriaLabel="Filter"
                        filteringPlaceholder="Filter"
                    />
                }
                pagination={<Pagination {...paginationProps}/>}
            />
        </>
    );
}

function getInlineTransitionMetricDescription(item: any, transition: TransitionMetrics, analysisMode: TransitionAnalysisMode) {

    let display = transition.destinationDisplay;
    if (transition.transitionDisplay !== "*") display = display + "(" + transition.transitionDisplay + ")";

    if (analysisMode === 'customers')
        return `${display} (${transition.customers.toLocaleString(window.navigator.language)} customers / ${numberWithFraction(100 * transition.customers / item["Unique Customers"].actualValue, 1).toLocaleString(window.navigator.language)}%)`
    else
        return `${display} (${transition.count.toLocaleString(window.navigator.language)} instances / ${numberWithFraction(100 * transition.count / item["Visits"].actualValue, 1).toLocaleString(window.navigator.language)}%)`
}

function getTransitionMetricDescription(item: any, transition: TransitionMetrics, analysisMode: TransitionAnalysisMode) {
    if (analysisMode === 'customers')
        return `${transition.destinationDisplay} (${transition.customers.toLocaleString(window.navigator.language)} customers / ${numberWithFraction(100 * transition.customers / item["Unique Customers"].actualValue, 1).toLocaleString(window.navigator.language)}%)`
    else
        return `${transition.destinationDisplay} (${transition.count.toLocaleString(window.navigator.language)} instances / ${numberWithFraction(100 * transition.count / item["Visits"].actualValue, 1).toLocaleString(window.navigator.language)}%)`
}
