import {AggregatedView, MetadataDescriptor} from "../../index";
import {EvaluationProps, Metric, MetricEvaluator, MetricEvaluatorProps} from "../metric";
import {getMetadataByProperty} from "../../../common/stringMetadata";

export interface SessionTimeMetricProps extends MetricEvaluatorProps{
    startTime: {
        mDesc: MetadataDescriptor,
        fallbackMetadata: MetadataDescriptor,
        metadataProperty: string,
    },
    endTime: {
        mDesc: MetadataDescriptor,
        fallbackMetadata: MetadataDescriptor,
        metadataProperty: string,
    }
    latencyPercent: number
}

/**
 * Returns the percentile of session time in an aggregated list
 * The percentile is defined using {@link latencyPercent}
 * Session turn is retrieved by {@link endTime} - {@link startTime}.
 */
export class SessionTimeMetric extends MetricEvaluator {

    startTime: {
        mDesc: MetadataDescriptor,
        fallbackMetadata: MetadataDescriptor,
        metadataProperty: string
    };
    endTime: {
        mDesc: MetadataDescriptor,
        fallbackMetadata: MetadataDescriptor,
        metadataProperty: string
    };
    latencyPercent: number

    constructor(props: SessionTimeMetricProps) {
        super(props);
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.latencyPercent = props.latencyPercent;
    }

    evaluate(aggregatedViews: AggregatedView[], props?: EvaluationProps): Metric {

        let sessionTimeList: number[] = []
        let seriesStatValue = 0;
        aggregatedViews.forEach(av => {
            const startTime: number = getMetadataByProperty(this.startTime.mDesc, av, this.startTime.metadataProperty, this.startTime.fallbackMetadata) as unknown as number;
            const endTime: number = getMetadataByProperty(this.endTime.mDesc, av, this.endTime.metadataProperty, this.endTime.fallbackMetadata) as unknown as number;

            if (typeof startTime !== 'undefined' && typeof endTime !== 'undefined') {
                sessionTimeList.push((+endTime) - (+startTime));
            }

        })

        if ((this.latencyPercent != undefined && this.latencyPercent > 0)) {
            sessionTimeList.sort(function(a, b){return a - b});
        }

        var sum = 0;
        var n = (this.latencyPercent == undefined || this.latencyPercent == 0) ? sessionTimeList.length : sessionTimeList.length * (this.latencyPercent / 100);
        for (var i = 0; i < n; i++) {
            sum += sessionTimeList[i];
        }

        if (n > 0) {
            seriesStatValue = (sum / n);
        }

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue.toFixed(1),
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
