import {AggregatedView, LineSeriesAndMetricData, MetadataDescriptor, TransitionMetrics} from "../index";
import {MetricEvaluator} from "../metrics/metric";

export type FilterMode = "INCLUDE" | "EXCLUDE" | "IGNORE";
export type FilterType = "MetricAwareFilter" | "DropdownFilter";

/**
 * RegisteredFilters contains metricAwareFilterGroups and dropDownFilterGroups.
 * metricAwareFilterGroups - These filters are filters displayed along with metrics on the dashboard
 * dropDownFilterGroups - These are the dropDownFilters displayed at the top.
 */
export interface RegisteredFilters {
    readonly metricAwareFilterGroups: { [group: string] : FilterGroup };
    readonly dropDownFilterGroups: { [group: string] : FilterGroup };
}

/**
 * Filters in a FilterGroup must be of the same type.
 * Eg:
 *      FilterGroup(Country Of Residence)   {
 *                                               Filter(Japan)
 *                                               Filter(Mexico)
 *                                               Filter(UK)
 *                                           }
 *
 * evaluate() will execute all the individual Filter.executorFunction in the group
 * and returns true if any of the filters return true.
 * If result of evaluate() is true the current aggregatedView will be included in the filteredList
 */
export interface FilterGroup {
    readonly groupName: string;
    readonly evaluate: (filters: { [filter: string] : Filter }, av: AggregatedView) => boolean;
    readonly options: { label: string, value: string }[];
    readonly placeholder?: string;
    readonly excludeFromDataFiltering: boolean;
    shouldEvaluateFilterGroup: boolean;
    filters: { [filter: string] : Filter };
}

/**
 * Individual Filters, here name must be equal to filterValue
 * Eg: Filter {
 *     filterName: Japan
 *     currentState: { label: Japan, value: JP}
 *     mode: INCLUDE
 *     executorFunction: Return true if av matches {CountryOfResidence = JP}
 * }
 */
export interface Filter {
    readonly filterName: string;
    readonly filterType: FilterType;
    readonly executorFunction: FilterEvaluator;
    readonly parentFilterGroup: FilterGroup | undefined;
    mode: FilterMode;
    shouldEvaluateFilter: boolean;
    currentState: { label: string, value: string };
}

export interface MetricAwareFilter extends Filter {
    readonly metricEvaluators: MetricEvaluator[];
    lineSeriesAndMetricData?: LineSeriesAndMetricData
}

export interface StateMetricFilter extends MetricAwareFilter {
    readonly isStatePageFilter: boolean;
    outgoingTransitions: TransitionMetrics[], // outgoing transitions,
    incomingTransitions: TransitionMetrics[] // incoming transitions
}

/**
 * Props for FilterEvaluator
 * mDesc: params required to fetch Metadata values. This has to be provided by the skill
 * fallbackMetadata: params required to fetch Metadata values in case mDesc is not valid anymore. This has to be provided by the skill
 */
export interface FilterEvaluatorProps {
    mDesc: MetadataDescriptor;
    valuesToExclude?: string[]
    fallbackMetadata?: MetadataDescriptor
}

/**
 * Base filter evaluator class.
 */
export abstract class FilterEvaluator {

    mDesc: MetadataDescriptor
    valuesToExclude?: string[]
    fallbackMetadata?: MetadataDescriptor

    protected constructor(props: FilterEvaluatorProps) {
        this.mDesc = props.mDesc
        this.valuesToExclude = props.valuesToExclude
        this.fallbackMetadata = props.fallbackMetadata
    }

    abstract evaluate(av: AggregatedView, currentState: { label: string, value: string | string[] }): boolean;
}