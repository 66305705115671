import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    LineChart, MixedLineBarChartProps,
    SpaceBetween,
    StatusIndicator
} from "@awsui/components-react";
import {subscribeLoadInProgressChanges} from "../../../../data/globalState";
import LineDataSeries = MixedLineBarChartProps.LineDataSeries;
import {MetricData} from "../../../../interfaces";
import {MetricsAwareFilterProps} from "./metricsAwareFilter";
import {getComparisonDisplayValue, getScaledValues} from "../../../../common/utils";


export function MetricValue(props: MetricsAwareFilterProps) {

    const [loadInProgress, setLoadInProgress] = useState<boolean>();

    useEffect(() => {
        console.log(`rendered MetricValues component ${props.metricsAwareFilter.filterName}`);
        subscribeLoadInProgressChanges(setLoadInProgress);
    }, [setLoadInProgress])

    function getMetricValueComponent() {

        let lineSeriesAndMetricData = props.metricsAwareFilter.lineSeriesAndMetricData

        let lineDataSeries = lineSeriesAndMetricData? (lineSeriesAndMetricData.lineDataSeries) : [];
        let metricData: MetricData[] = lineSeriesAndMetricData? lineSeriesAndMetricData.metricData: [];

        return (
            <SpaceBetween size={'xs'} direction={'horizontal'}>
                <div style={{width: 120}}>
                    <LineChart
                        series={getScaledValues(lineDataSeries) as LineDataSeries<any>[]}
                        height={80}
                        i18nStrings={{
                            filterLabel: "Filter displayed data",
                            filterPlaceholder: "Filter data",
                            filterSelectedAriaLabel: "selected",
                            detailPopoverDismissAriaLabel: "Dismiss",
                            legendAriaLabel: "Legend",
                            chartAriaRoleDescription: "line chart",
                            xTickFormatter: e => "",
                            yTickFormatter: function o(e) {
                                return "";
                            }
                        }}
                        ariaLabel="Single data series line chart"
                        errorText="Error loading data."
                        statusType={loadInProgress ? 'loading' : 'finished'}
                        hideFilter={true}
                        hideLegend={true}
                        loadingText="Loading chart"
                        recoveryText="Retry"
                        xScaleType="time"
                        yScaleType="linear"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    />
                </div>
                <SpaceBetween size={'xxs'} direction={'vertical'}>
                    {metricData.map(data => {

                        if (loadInProgress) {
                            return (
                                <StatusIndicator type={"loading"} key={data.metricLabel}/>
                            )
                        }

                        if (data.comparisonValue === 0 && data.actualValue === 0) {
                            return (
                                <StatusIndicator type={"stopped"} key={data.metricLabel}>
                                    <span>_____________________</span>
                                </StatusIndicator>
                            )
                        }

                        let comparisonString = `${data.metricLabel}: ${getComparisonDisplayValue(data)}`
                        return (
                            <StatusIndicator type={(data.dataTrend === 'POSITIVE')? 'success': 'warning'} key={data.metricLabel}>
                                {comparisonString}
                            </StatusIndicator>
                        )
                    })}
                </SpaceBetween>
            </SpaceBetween>
        )
    }

    return <>{getMetricValueComponent()}</>
}
