import format from "date-fns/format";
import addDays from "date-fns/addDays";
import {addMonths, differenceInDays} from "date-fns";
import {setComparisonLoadButtonDisabledFlag, setLoadButtonDisabledFlag} from "./globalState";

export interface DateRange {
  readonly startDate?: string;
  readonly endDate?: string;
}

let selectedRegions: string[] = [];
let primaryDateRange: DateRange = {};
let comparisonDateRange: DateRange = {};
let comparisonSuggestions: DateRange[] = [];

// listeners subscribed for updates to primary date range
const primaryDateRangeListeners = new Set<(dateRange: DateRange) => void>();

// listeners subscribed for updates to date range for comparisons
const comparisonDateRangeListeners = new Set<(dateRange: DateRange) => void>();

// listeners subscribed for updates to comparison suggestions
const compareSuggestionsListeners = new Set<(suggestions: DateRange[]) => void>();

// listeners subscribed to updates to
const regionListeners = new Set<(regions: string[]) => void>();

function formatIso(date: Date) {
  return format(date, "yyyy-MM-dd");
}

function __today() {
  // subtract 30 hours - this is to avoid having to load _hot_ data. Arbitrary period of 6 hours to avoid hot data.
  return Date.now() - 30 * 60 * 60 * 1000;
}

export function lastTwoWeeks() {
  return [formatIso(addDays(__today(), -14 + 1)), formatIso(new Date(__today()))];
}
export function lastWeek() {
  return [formatIso(addDays(__today(), -7 + 1)), formatIso(new Date(__today()))];
}

export function lastThirtyDays() {
  return [formatIso(addDays(__today(), -30 + 1)), formatIso(new Date(__today()))];
}

export function thisMonth() {
  const today = new Date(__today());
  const daysSinceMonthStart = new Date().getDate();
  return [formatIso(addDays(new Date(), -daysSinceMonthStart + 1)), formatIso(today)];
}

export function lastMonth() {
  const today = new Date();
  const daysSinceMonthStart = today.getDate();
  const previousMonthLast = addDays(today, -daysSinceMonthStart); // -daysSinceMonthStart + 1 to get to first day of current month
  const daysSincePreviousMonthStart = previousMonthLast.getDate();
  return [formatIso(addDays(previousMonthLast, -daysSincePreviousMonthStart + 1)), formatIso(previousMonthLast)];
}

/**
 * Set a new date range.
 */
export function setDateRange(startDate: string, endDate: string) {
  console.log('setDateRange: current p, c, r, suggest: ', primaryDateRange, comparisonDateRange, selectedRegions, comparisonSuggestions)

  if (primaryDateRange.startDate === startDate && primaryDateRange.endDate === endDate) {
    console.log('setDateRange: No change identified for startDate, endDate - skipping executing listeners')
    return;
  }

  primaryDateRange = {startDate: startDate, endDate: endDate};

  const __startDate = new Date(`${startDate}T00:00:00`); // the latter part is added to ensure local tz is used
  const __endDate = new Date(`${endDate}T00:00:00`)
  const difference = differenceInDays(__endDate, __startDate);

  comparisonSuggestions = [{
    startDate: formatIso(addMonths(__startDate, -1)), endDate: formatIso(addMonths(__endDate, -1))
  }, {
    startDate: formatIso(addDays(__startDate, -difference - 1)), endDate: formatIso(addDays(__endDate, -difference - 1))
  }]
  comparisonDateRange = comparisonSuggestions[0];

  console.log('setDateRange: updated p, c, r, suggest: ', primaryDateRange, comparisonDateRange, selectedRegions, comparisonSuggestions)

  // notify everyone except regions
  comparisonDateRangeListeners.forEach(listener => listener(comparisonDateRange));
  primaryDateRangeListeners.forEach(listener => listener(primaryDateRange));
  compareSuggestionsListeners.forEach(listener => listener(comparisonSuggestions));

  setLoadButtonDisabledFlag(false);
  setComparisonLoadButtonDisabledFlag(true);
}

/**
 * Get primaryDataRange
 */
export function getPrimaryDateRange(): DateRange {
  return primaryDateRange;
}

/**
 * Get comparisonDateRange
 */
export function getComparisonDateRange(): DateRange {
  console.log(`getComparisonDateRange ${comparisonDateRange.startDate}`)
  return comparisonDateRange;
}

/**
 * Get selectedRegions
 */
export function getSelectedRegions(): string[] {
  return selectedRegions;
}

/**
 * Set a new selection of data regions.
 */
export function setRegions(regions: string[]) {
  console.log('setRegions: current p, c, r, suggest: ', primaryDateRange, comparisonDateRange, selectedRegions, comparisonSuggestions)
  if (selectedRegions.filter(r => regions.indexOf(r) < 0).length == 0
      && regions.filter(r => selectedRegions.indexOf(r) < 0).length == 0
  ) {
    console.log('setRegions: No change identified for regions - skipping executing listeners')
    return;
  }

  selectedRegions = [...regions]
  console.log('setRegions: updated p, c, r, suggest: ', primaryDateRange, comparisonDateRange, selectedRegions, comparisonSuggestions)
  regionListeners.forEach(listener => listener(selectedRegions));
  setLoadButtonDisabledFlag(false);
  setComparisonLoadButtonDisabledFlag(true);
}

/**
 * Set comparisonDateRange
 * @param startDate
 * @param endDate
 */
export function setComparisonRange(startDate: string, endDate: string) {
  console.log('setComparisonRange: current p, c, r, suggest: ', primaryDateRange, comparisonDateRange, selectedRegions, comparisonSuggestions)

  if (startDate === comparisonDateRange.startDate && endDate === comparisonDateRange.endDate) {
    console.log('setComparisonRange: No change identified for comparison range - skipping executing listeners')
    return;
  }

  comparisonDateRange = {startDate: startDate, endDate: endDate}
  console.log('setComparisonRange: updated p, c, r, suggest: ', primaryDateRange, comparisonDateRange, selectedRegions, comparisonSuggestions)
  comparisonDateRangeListeners.forEach(listener => listener(comparisonDateRange));
  setComparisonLoadButtonDisabledFlag(false);
}

/**
 * Subscribe to get notified whenever 'primaryDateRange' state changes
 * @param listener
 */
export function subscribePrimaryDateRangeChanges(listener: (range: DateRange) => void) {
  primaryDateRangeListeners.add(listener);
  listener(primaryDateRange);
  return () => primaryDateRangeListeners.delete(listener);
}

/**
 * Subscribe to get notified whenever 'comparisonDateRange' state changes
 * @param listener
 */
export function subscribeComparisonDateRangeChanges(listener: (range: DateRange) => void) {
  comparisonDateRangeListeners.add(listener);
  listener(comparisonDateRange);
  return () => comparisonDateRangeListeners.delete(listener);
}

/**
 * Subscribe to get notified whenever 'comparisonSuggestions' state changes
 * @param listener
 */
export function subscribeComparisonSuggestions(listener: (ranges: DateRange[]) => void) {
  compareSuggestionsListeners.add(listener);
  listener(comparisonSuggestions);
  return () => compareSuggestionsListeners.delete(listener);
}

/**
 * Subscribe to get notified whenever 'selectedRegions' state changes
 * @param listener
 */
export function subscribeRegionsChanges(listener: (regions: string[]) => void) {
  regionListeners.add(listener);
  listener(selectedRegions);
  return () => regionListeners.delete(listener);
}
