import {AggregatedView, TransitionAnalysisMode} from "../index";
import {Stats} from "../../common/stats";

export type MetricType = "PageMetric" | "FrictionMetric";

export interface Metric {
    metricLabel: string
    metricValue: number | string;
    trend?: { x: Date, y: number }[];
    isUpwardTrendGood?: boolean;
}

/**
 * Base evaluator props passed to MetricEvaluator constructor
 */
export interface MetricEvaluatorProps {
    metricLabel: string;
    isTrendRequired: boolean;
    isUpwardTrendGood?: boolean
}

/**
 * Evaluator props for evaluating state metrics
 */
export interface StateMetricEvaluatorProps extends MetricEvaluatorProps {
    stateKey: string;
    showTags: boolean
}

/**
 * Evaluator props for evaluating state metrics
 */
export interface TransitionMetricEvaluatorProps extends MetricEvaluatorProps {
    transitionReason: string;
    showTags: boolean
}

/**
 * This props will be passed to evaluate() method.
 */
export interface EvaluationProps {
    period: number;
    stat?: Stats;
    isTrendRequired?: boolean
}

/**
 * Base metric evaluator class.
 */
export abstract class MetricEvaluator {

    metricLabel: string
    isTrendRequired: boolean
    isUpwardTrendGood?: boolean

    protected constructor(props: MetricEvaluatorProps) {
        this.metricLabel = props.metricLabel;
        this.isTrendRequired = props.isTrendRequired
        this.isUpwardTrendGood = props.isUpwardTrendGood
    }

    abstract evaluate(aggregatedViews: AggregatedView[], props?: EvaluationProps): Metric
}
