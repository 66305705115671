import {AggregatedView, MetadataDescriptor} from "../../index";
import {StatsFunctions} from "../../../common/stats";
import {EvaluationProps, Metric, MetricEvaluator, MetricEvaluatorProps} from "../metric";
import {getSum} from "../../../common/numericMetadata";

/**
 * Props for SummationMetricProps
 * mDesc: params required to fetch Metadata values. This has to be provided by the skill
 * fallbackMetadata: params required to fetch Metadata values in case mDesc is not valid anymore. This has to be provided by the skill
 */
export interface SummationMetricProps extends MetricEvaluatorProps {
    mDesc: MetadataDescriptor;
    fallbackMetadata?: MetadataDescriptor
}

/**
 * This calculates the sum of all values of a Metadata.
 * Retrieves the Metadata values using the mDesc provided in SummationMetricProps and sums up
 * all the values in the list.
 */
export class SummationMetric extends MetricEvaluator {

    mDesc: MetadataDescriptor;
    fallbackMetadata?: MetadataDescriptor;

    constructor(props: SummationMetricProps) {
        super(props);
        this.mDesc = props.mDesc
        this.fallbackMetadata = props.fallbackMetadata
    }

    evaluate(aggregatedViews: AggregatedView[], props?: EvaluationProps): Metric {

        const periodData: { [window: number]: number[] } = {};

        let isTrendRequired = undefined;

        if (typeof props !== 'undefined') {
            isTrendRequired = (props.isTrendRequired !== undefined ? props.isTrendRequired: this.isTrendRequired);
        }

        let seriesStatValue = 0;
        aggregatedViews.forEach(av => {
            const sum = getSum(this.mDesc, av, this.fallbackMetadata);

            if (typeof props !== 'undefined') {
                const window = Math.floor(av.timestamp / props.period) * props.period;
                if (!periodData.hasOwnProperty(window)) periodData[window] = [];
                if (typeof sum !== 'undefined') {
                    periodData[window].push(sum);
                }
            }
            if (typeof sum !== 'undefined') {
                seriesStatValue += sum;
            }
        })

        if (!isTrendRequired && isTrendRequired === false) {
            return {
                metricLabel: this.metricLabel,
                metricValue: seriesStatValue,
                isUpwardTrendGood: this.isUpwardTrendGood
            };
        }

        const overallSeriesData: { x: Date, y: number }[] = [];

        const sortedWindows = Object.keys(periodData).map(v => Number(v)).sort();

        sortedWindows.forEach(window => {
            overallSeriesData.push({
                x: new Date(window), y: StatsFunctions.get(props!.stat? props!.stat: "sum")!(periodData[window])
            });
        });

        return {
            metricLabel: this.metricLabel,
            metricValue: seriesStatValue,
            trend: overallSeriesData,
            isUpwardTrendGood: this.isUpwardTrendGood
        };
    }

}
